import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../shared/styledButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogContentText} from '@material-ui/core';

type ConfirmPromoteDialogProps = {
    isOpen: boolean;
    title: string;
    description: string;
    onClose: () => void;
    action: () => void;
};

const ConfirmPromoteDialog = (props: ConfirmPromoteDialogProps): JSX.Element => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {isOpen, onClose, action, title, description} = props;

    const promoteHandler = (): void => {
        action();
        onClose();
    };

    const closeDialogHandler = (): void => {
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={closeDialogHandler} classes={{paper: classes.paper}} fullWidth>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledButton color='secondary' onClick={closeDialogHandler}>
                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                </StyledButton>
                <StyledButton color='primary' onClick={promoteHandler}>
                    <Typography>{intl.formatMessage({id: 'userAdministration.button.promote'})}</Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);

export default ConfirmPromoteDialog;
