import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useToasts} from 'react-toast-notifications';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {InputAdornment, Grid} from '@material-ui/core';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import StyledButton from '../shared/styledButton';
import {projectReview} from '../../api/apiClient';
import {ReviewProjectRequest} from '../../api/apiContracts';
import StyledTextField from '../shared/styledTextField';
import StyledHeader from '../shared/styledHeader';
import {validationConstraints} from '../../consts/validationConstrs';

type ProjectReviewFormProps = {
    projectId: number;
};

const ProjectReviewForm = (props: ProjectReviewFormProps): JSX.Element => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {addToast} = useToasts();
    const {handleResponseError} = useErrorHandler();
    const {projectId} = props;
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            const reviewProjectRequest = new ReviewProjectRequest({
                projectIsAccepted: values.projectIsAccepted,
                reviewComments: values.reviewComments,
            });
            await projectReview(projectId, reviewProjectRequest);
            addToast(intl.formatMessage({id: 'project.review.success'}), {appearance: 'success'});
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
            setSubmitted(true);
        }
    };

    const validationSchema = Yup.object().shape({
        reviewComments: Yup.string().max(
            validationConstraints.reviewComments.maxLength,
            intl.formatMessage(
                {id: 'validation.maxLength'},
                {maxLength: validationConstraints.reviewComments.maxLength}
            )
        ),
    });

    return (
        <Formik
            initialValues={{reviewComments: '', projectIsAccepted: false}}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {props => {
                const {
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    submitForm,
                } = props;

                return (
                    <Form>
                        <StyledHeader
                            headerText={intl.formatMessage({
                                id: 'project.review.reviewComments.heading',
                            })}
                        />
                        <StyledTextField
                            multiline
                            inputProps={{style: {resize: 'vertical'}}}
                            rows={10}
                            placeholder={intl.formatMessage({
                                id: 'project.review.reviewComments.placeholder',
                            })}
                            name='reviewComments'
                            value={values.reviewComments}
                            helperText={(touched.reviewComments && errors.reviewComments) as string}
                            error={touched.reviewComments && Boolean(errors.reviewComments)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={submitted}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        className={
                                            classes.inputAdornment
                                        }>{`${values.reviewComments.length}/${validationConstraints.reviewComments.maxLength}`}</InputAdornment>
                                ),
                            }}
                        />

                        <Grid container justify='space-between' className={classes.actions}>
                            <Grid item>
                                <StyledButton
                                    color='secondary'
                                    type='button'
                                    disabled={submitted || Object.keys(errors).some(key => errors[key] && touched[key])}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}
                                    onClick={async () => {
                                        setFieldValue('projectIsAccepted', false);
                                        await submitForm();
                                    }}>
                                    {intl.formatMessage({id: 'project.review.reject'})}
                                </StyledButton>
                            </Grid>
                            <Grid item>
                                <StyledButton
                                    color='primary'
                                    type='button'
                                    disabled={
                                        submitted ||
                                        isSubmitting ||
                                        Object.keys(errors).some(key => errors[key] && touched[key])
                                    }
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={async () => {
                                        setFieldValue('projectIsAccepted', true);
                                        await submitForm();
                                    }}>
                                    {intl.formatMessage({id: 'project.review.accept'})}
                                </StyledButton>
                            </Grid>

                            <input type='submit' hidden />
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        actions: {
            marginTop: spacing(2),
        },
        inputAdornment: {
            position: 'absolute',
            right: '13px',
            top: '13px',

            '& p': {
                fontSize: '11px',
            },
        },
    })
);

export default ProjectReviewForm;
