import React from 'react';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {Drawer, List, ListItem, ListItemText, Divider} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import HomeSideMenu from '../sideMenus/homeSideMenu';
import {UserRole } from '../../api/apiContracts';
import {authService} from '../../services/authService';


const AdminSideMenu = (props: {children?: React.ReactNode}) => {
    const classes = useStyles();
    const intl = useIntl();
    const location = useSelector<ApplicationState, string>(s => s.router.location.pathname);
    const toolbar = <div className={classes.toolbar} />;
    const userIsManager = authService.getUserRoles().includes(UserRole.Manager);
    const userIsAdministrator = authService.getUserRoles().includes(UserRole.Administrator);

    return (
         userIsAdministrator && !userIsManager ?
            <Drawer
                variant='permanent'
                className={classes.drawer}
                classes={{paper: classes.drawerPaper, paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft}}>
                {props.children}
                <List>
                    <StyledListItem
                        button
                        selected={location === (Router.routes.userAdministration.props.path as string)}
                        onClick={() => Router.routes.index.go()}>
                        <ListItemText primary={intl.formatMessage({id: 'sideMenu.userAdministration'})}/>
                    </StyledListItem>
                    <Divider variant='middle' />
                </List>
            </Drawer> :
            <HomeSideMenu>{toolbar}</HomeSideMenu>
    );
};

const drawerWidth = 250;
const useStyles = makeStyles(({palette, mixins, spacing}: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: palette.secondary.main,
        },
        drawerPaperAnchorDockedLeft: {
            border: 'none',
        },
        nested: {
            paddingLeft: spacing(4),
        },
        toolbar: mixins.toolbar,
    }),
);

const StyledListItem = withStyles(({palette}: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'baseline',
        '&$selected': {
            backgroundColor: 'transparent',
            color: palette.primary.main,
            fontWeight: 700,
            '& span': {
                fontWeight: 700,
            },
            '&::before': {
                content: '"|"',
                fontSize: 20,
                lineHeight: '16px',
            },
        },
    },
    selected: {},
}))(ListItem);

export default AdminSideMenu;
