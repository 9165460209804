import {UserResponse} from '../../api/apiContracts';

export type UsersState = {
    users: UserResponse[];
};

export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export type SetUsersAction = {
    type: typeof SET_USERS;
    users: UserResponse[];
};

export type UpdateUserAction = {
    type: typeof UPDATE_USER;
    user: UserResponse;
};

export type DeleteUserAction = {
    type: typeof DELETE_USER;
    id: string;
};

export type UsersActionTypes = SetUsersAction | UpdateUserAction | DeleteUserAction;
