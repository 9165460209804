import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import HomeHeader from '../headers/homeHeader';
import HomeSideMenu from '../sideMenus/homeSideMenu';

const HomeLayout = (props: { children?: React.ReactNode }) => {
    const classes = useStyles();
    const toolbar = <div className={classes.toolbar} />;

    return (
        <div className={classes.root}>
            <HomeHeader />
            <HomeSideMenu>{toolbar}</HomeSideMenu>
            <main className={classes.content}>
                {toolbar}
                {props.children}
            </main>
        </div>
    );
};

const useStyles = makeStyles(({palette, mixins, spacing}: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        toolbar: mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: spacing(3),
        },
    })
);

export default HomeLayout;
