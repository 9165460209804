import {StepConnector, Theme, withStyles} from '@material-ui/core';

const StyledConnector = withStyles(({palette}: Theme) => ({
    active: {
        '& $line': {
            borderColor: palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: palette.primary.main,
        },
    },
    line: {
        borderColor: palette.text.secondary,
        borderTopWidth: 4,
        borderRadius: 1,
    },
}))(StepConnector);

export default StyledConnector;