import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import ProjectCard from '../components/projectsOverview/projectCard';
import {ProjectResponse, ProjectStatus} from '../api/apiContracts';
import NewProjectCard from '../components/projectsOverview/newProjectCard';
import {loadProjects} from '../thunks/projectsThunks';
import {ApplicationState} from '../store';
import {AppThunkDispatch} from '../store/types';

const ProjectsOverview = () => {
    const classes = useStyles();
    const intl = useIntl();
    const projects = useSelector<ApplicationState, ProjectResponse[]>(s => s.projects.projects);
    const dispatch: AppThunkDispatch = useDispatch();
    const {handleResponseError} = useErrorHandler();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                await dispatch(loadProjects());
            } catch (e) {
                handleResponseError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [intl, dispatch, handleResponseError]);

    const ProjectCardSkeleton = () => (
        <Grid item xs={12} md={6} xl={4}>
            <Skeleton variant='rect' width='100%'>
                <ProjectCard project={new ProjectResponse()} />
            </Skeleton>
        </Grid>
    );

    return (
        <Grid container direction='column' spacing={10} className={classes.root}>
            <Grid item>
                <Divider />
                <Typography variant='h4' component='h2' className={classes.heading}>
                    {intl.formatMessage({id: 'projects.draft.title'})}
                </Typography>
                <Typography variant='subtitle1' component='h3' className={classes.subtitle}>
                    {intl.formatMessage({id: 'projects.draft.subtitle'})}
                </Typography>
                <Grid container spacing={3} className={classes.cardsContainer}>
                    {loading ? (
                        <ProjectCardSkeleton />
                    ) : (
                        projects
                            .filter(p => p.status === ProjectStatus.Draft)
                            .map((project, index) => (
                                <Grid key={index} item xs={12} md={6} xl={4}>
                                    <ProjectCard project={project} />
                                </Grid>
                            ))
                    )}
                    <Grid item xs={12} md={6} xl={4}>
                        <NewProjectCard />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.opaque}>
                <Divider />
                <Typography variant='h4' component='h2' className={classes.heading}>
                    {intl.formatMessage({id: 'projects.pending.title'})}
                </Typography>
                <Typography variant='subtitle1' component='h3' className={classes.subtitle}>
                    {intl.formatMessage({id: 'projects.pending.subtitle'})}
                </Typography>
                <Grid container spacing={3} className={classes.cardsContainer}>
                    {loading ? (
                        <ProjectCardSkeleton />
                    ) : (
                        projects
                            .filter(p => p.status === ProjectStatus.Pending)
                            .map((project, index) => (
                                <Grid key={index} item xs={12} md={6} xl={4}>
                                    <ProjectCard project={project} />
                                </Grid>
                            ))
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
                <Typography variant='h4' component='h2' className={classes.heading}>
                    {intl.formatMessage({id: 'projects.accepted.title'})}
                </Typography>
                <Typography variant='subtitle1' component='h3' className={classes.subtitle}>
                    {intl.formatMessage({id: 'projects.accepted.subtitle'})}
                </Typography>
                <Grid container spacing={3} className={classes.cardsContainer}>
                    {loading ? (
                        <ProjectCardSkeleton />
                    ) : (
                        projects
                            .filter(p => p.status === ProjectStatus.Accepted)
                            .map((project, index) => (
                                <Grid key={index} item xs={12} md={6} xl={4}>
                                    <ProjectCard project={project} />
                                </Grid>
                            ))
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
                <Typography variant='h4' component='h2' className={classes.heading}>
                    {intl.formatMessage({id: 'projects.rejected.title'})}
                </Typography>
                <Typography variant='subtitle1' component='h3' className={classes.subtitle}>
                    {intl.formatMessage({id: 'projects.rejected.subtitle'})}
                </Typography>
                <Grid container spacing={3} className={classes.cardsContainer}>
                    {loading ? (
                        <ProjectCardSkeleton />
                    ) : (
                        projects
                            .filter(p => p.status === ProjectStatus.Rejected)
                            .map((project, index) => (
                                <Grid key={index} item xs={12} md={6} xl={4}>
                                    <ProjectCard project={project} />
                                </Grid>
                            ))
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        root: {},
        heading: {
            marginTop: spacing(2),
        },
        cardsContainer: {
            marginTop: spacing(1),
            marginBottom: spacing(1),
        },
        opaque: {
            opacity: 0.5,
        },
        subtitle: {
            fontWeight: 500,  
        },
    })
);

export default ProjectsOverview;
