import React, {useEffect} from 'react';
import {Grid, Typography} from '@material-ui/core';
import DashboardTile from '../components/shared/dashboardTile';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {DashboardStatsResponse} from '../api/apiContracts';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../store';
import {AppThunkDispatch} from '../store/types';
import {loadStats} from '../thunks/statsThunks';

const ManagementDashboard = () => {
    const classes = useStyles();
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();

    const dashboardStats = useSelector<ApplicationState, DashboardStatsResponse>(s => s.stats.stats);

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                await dispatch(loadStats());
            } catch (e) {
                handleResponseError(e);
            }
        })();
    }, [dispatch, handleResponseError]);

    return (
        <div className={classes.root}>
            <Grid container>
                <DashboardTile xs={6} name='Total accounts' data={dashboardStats.totalAccounts} />
                <DashboardTile xs={6} name='New accounts this month' data={dashboardStats.newAccountsThisMonth} />
                <DashboardTile xs={6} name='Total projects' data={dashboardStats.totalProjects} />
                <DashboardTile xs={6} name='New projects this month' data={dashboardStats.newProjectsThisMonth} />

                <Grid item xs={12} className={classes.projectsHeading}>
                    <Typography variant='h5' component='h5' align='center'>
                        Project status
                    </Typography>
                </Grid>
                <DashboardTile xs={3} name='Drafts' data={dashboardStats.draftProjects} />
                <DashboardTile xs={3} name='Pending' data={dashboardStats.pendingProjects} />
                <DashboardTile xs={3} name='Accepted' data={dashboardStats.acceptedProjects} />
                <DashboardTile xs={3} name='Rejected' data={dashboardStats.rejectedProjects} />
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            left: '55%',
            top: '40%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 25,
        },
        projectsHeading: {
            paddingTop: theme.spacing(10),
        },
    })
);

export default ManagementDashboard;
