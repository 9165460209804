import axios from 'axios';
import {
    registerIdentityRoute,
    loginRoute,
    refreshTokenRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    requestEmailChangeRoute,
    changePasswordRoute,
    requestConfirmEmailRoute
} from '../apiRoutes';
import {
    RegisterIdentityRequest,
    LoginRequest,
    RefreshTokenRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    AuthResponse,
    RequestEmailChangeRequest,
    RequestConfirmEmailRequest
} from '../apiContracts';

export const registerIdentity = async (request: RegisterIdentityRequest): Promise<void> => {
    await axios.post(registerIdentityRoute, request);
};

export const login = async (request: LoginRequest): Promise<AuthResponse> => {
    const result = AuthResponse.fromJS((await axios.post(loginRoute, request)).data);
    return result;
};

export const refreshToken = async (request: RefreshTokenRequest): Promise<AuthResponse> => {
    const result = AuthResponse.fromJS((await axios.post(refreshTokenRoute, request)).data);
    return result;
};

export const forgotPassword = async (request: ForgotPasswordRequest): Promise<void> => {
    await axios.post(forgotPasswordRoute, request);
};

export const resetPassword = async (request: ResetPasswordRequest): Promise<void> => {
    await axios.post(resetPasswordRoute, request);
};

export const requestEmailChange = async (request: RequestEmailChangeRequest): Promise<void> => {
    await axios.post(requestEmailChangeRoute, request);
};

export const requestConfirmEmail = async (request: RequestConfirmEmailRequest): Promise<void> => {
    await axios.post(requestConfirmEmailRoute, request);
};

export const changeLoggedInUserPassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    await axios.post(changePasswordRoute, {oldPassword: oldPassword, newPassword: newPassword});
};
