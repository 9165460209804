import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {Grid, Typography} from '@material-ui/core';
import {getProjectSubmitDetails} from '../api/apiClient';
import {getProjectById, checkProject} from '../api/apiClient';
import {ProjectSubmitDetailsResponse, ProjectResponse, UserResponse} from '../api/apiContracts';
import ProjectSubmitDetailsForm from '../components/forms/projectSubmitDetailsForm';
import {ApplicationState} from '../store';
import {authService} from '../services/authService';

type RouteParams = {
    id: string;
};

const EditProjectSubmitDetails = (props: RouteComponentProps<RouteParams>) => {
    const intl = useIntl();
    const loggedInUser = useSelector<ApplicationState, UserResponse[]>(s => s.users.users).filter(
        u => u.id === authService.getLoggedInUserId()
    )[0];
    const {handleResponseError} = useErrorHandler();

    let projectId = 0;
    try {
        projectId = parseInt(props.match.params.id);
    } catch (e) {}

    const [loading, setLoading] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [projectSubmitDetails, setProjectSubmitDetails] = useState<ProjectSubmitDetailsResponse>(
        new ProjectSubmitDetailsResponse({
            productionUrl: '',
            companyName: '',
            address: '',
            zipCode: '',
            city: '',
            contactPhone: '',
            reviewNotes: '',
            contactName: loggedInUser.displayName,
            contactEmail: loggedInUser.email,
        })
    );
    const [project, setProject] = useState<ProjectResponse>(new ProjectResponse());

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const project: ProjectResponse = await getProjectById(projectId);

                if (checkProject(project))
                {
                    const projectSubmitDetails: ProjectSubmitDetailsResponse = await getProjectSubmitDetails(projectId);
                    setProjectSubmitDetails(projectSubmitDetails);
                    setProject(project);
                    setIsEdit(true);
                    setLoading(false);
                }

            } catch (e) {
                if (e && e.status && e.status === 404) {
                    setLoading(false);
                } else {
                    handleResponseError(e);
                }
            }
        })();
    }, [handleResponseError, intl, projectId]);

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography variant='body2'>
                    {intl.formatMessage({id: 'project.addEdit.submitDetails.description'})}
                </Typography>
            </Grid>
            <Grid item>
                <ProjectSubmitDetailsForm
                    loading={loading}
                    isEdit={isEdit}
                    projectId={projectId}
                    projectStatus={project.status}
                    projectSubmitDetails={projectSubmitDetails}
                />
            </Grid>
        </Grid>
    );
};

export default EditProjectSubmitDetails;
