import React, {Fragment, useEffect, useState} from 'react';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {ProjectResponse, ProjectPreviewResponse} from '../api/apiContracts';
import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InputTitle from '../components/shared/inputTitle';
import Preview from '../components/addEditProjectGeneralDetails/preview';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {getProjectPreview} from '../api/apiClient';
import Router from '../routing/router';
import {UseQueryParams} from '../utils/useQueryParams';
import StyledTextField from '../components/shared/styledTextField';
import {isInvalidAccessKey, invalidTokenTypes} from '../consts/errorMessages';

const PeekProject = (): JSX.Element => {
    const classes = useStyles();
    const queryParams = UseQueryParams();
    const {handleResponseError} = useErrorHandler();
    const [loading, setLoading] = useState<boolean>(true);
    const [project, setProject] = useState<ProjectPreviewResponse>(new ProjectResponse());
    const projectPreviewKey = queryParams.get('ProjectPreviewKey');

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const project: ProjectPreviewResponse = await getProjectPreview(projectPreviewKey as string);
                setProject(project);
            } catch (e) {
                if (isInvalidAccessKey(e.message)) {
                    Router.routes.invalidToken.go({type: invalidTokenTypes.Collaborator});
                } else {
                    handleResponseError(e);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [handleResponseError, projectPreviewKey]);

    return (
        <Grid container justify='space-between' className={classes.root}>
            <Grid item xs={12} lg={4}>
                {['name', 'payoff', 'country', 'description'].map(key => (
                    <Fragment key={key}>
                        <InputTitle name={key} />
                        {loading ? (
                            <Skeleton variant='rect' width='100%'>
                                <StyledTextField />
                            </Skeleton>
                        ) : (
                            <StyledTextField name={key} value={project[key]} disabled={true} multiline />
                        )}
                    </Fragment>
                ))}
            </Grid>

            <Grid item xs={12} lg={8} xl={6}>
                <Preview project={project as ProjectResponse} loading={loading} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        root: {
            padding: spacing(4),
        },
    }),
);

export default PeekProject;
