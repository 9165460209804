import React, {ErrorInfo} from 'react';
import {withIntl, WithIntlProps} from '../components/hoc/withIntl';
import {withErrorHandler, WithErrorHandlerProps} from './withErrorHandler';

type GlobalErrorBoundaryProps = WithErrorHandlerProps & WithIntlProps;

class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps> {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.props.handleError(this.props.intl.formatMessage({id: 'generic.exception'}), {...error, ...info});
    }

    render() {
        if (this.state.hasError)
        {
            return null;
        }
        
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default withIntl(withErrorHandler(GlobalErrorBoundary));
