import React from 'react';
import StyledTextField from '../shared/styledTextField';
import StyledHeader from './styledHeader';

type StyledTextFieldWithHeaderProps = {
    autoFocus?: boolean;
    name?: string;
    type?: string;
    autoComplete?: string;
    placeholder?: string;
    value?: string;
    helperText?: string;
    error?: boolean;
    onChange?: any;
    onBlur?: any;
    disabled?: boolean;
    headerText: string;
    inputProps?: any;
    className?: string;
    classes?: {header?: string};
};

export default function StyledTextFieldWithHeader({
    autoFocus,
    name,
    type,
    autoComplete,
    placeholder,
    value,
    helperText,
    error,
    onChange,
    onBlur,
    disabled,
    headerText,
    inputProps,
    className,
    classes,
}: StyledTextFieldWithHeaderProps): JSX.Element {
    return (
        <div>
            <StyledHeader headerText={headerText} className={classes?.header} />
            <StyledTextField
                autoFocus={autoFocus}
                name={name}
                type={type}
                autoComplete={autoComplete}
                placeholder={placeholder}
                value={value}
                helperText={helperText}
                error={error}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                InputProps={inputProps}
                className={className}
            />
        </div>
    );
}
