import axios from 'axios';
import {
    getProjectSubmitDetailsRoute,
    createProjectSubmitDetailsRoute,
    deleteProjectSubmitDetailsRoute,
    patchProjectSubmitDetailsRoute,
} from '../apiRoutes';
import {
    ProjectSubmitDetailsResponse,
    ProjectSubmitDetailsRequest,
    IProjectSubmitDetailsResponse,
} from '../apiContracts';
import {compare} from 'fast-json-patch';

export const getProjectSubmitDetails = async (projectId: number): Promise<ProjectSubmitDetailsResponse> => {
    const result: ProjectSubmitDetailsResponse = ProjectSubmitDetailsResponse.fromJS(
        (await axios.get(getProjectSubmitDetailsRoute(projectId))).data
    );
    return result;
};

export const createProjectSubmitDetails = async (
    projectId: number,
    projectSubmitDetails: ProjectSubmitDetailsRequest
): Promise<void> => {
    await axios.post(createProjectSubmitDetailsRoute(projectId), projectSubmitDetails);
};

export const deleteProjectSubmitDetails = async (projectId: number): Promise<void> => {
    await axios.delete(deleteProjectSubmitDetailsRoute(projectId));
};

export const patchProjectSubmitDetails = async (
    projectId: number,
    oldProjectSubmitDetails: IProjectSubmitDetailsResponse,
    newProjectSubmitDetails: IProjectSubmitDetailsResponse
): Promise<ProjectSubmitDetailsResponse> => {
    const oldProjectSubmitDetailsRequest = ProjectSubmitDetailsRequest.fromJS(oldProjectSubmitDetails);
    const newProjectSubmitDetailsRequest = ProjectSubmitDetailsRequest.fromJS(newProjectSubmitDetails);

    const patch = compare(oldProjectSubmitDetailsRequest, newProjectSubmitDetailsRequest);
    if (patch.length) {
        await axios.patch(patchProjectSubmitDetailsRoute(projectId), patch);
    }

    return ProjectSubmitDetailsResponse.fromJS(newProjectSubmitDetails);
};
