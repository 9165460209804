import {IconButton, InputAdornment} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';

type PasswordFieldProps = {
    autoFocus?: boolean;
    placeholder: string;
    name: string;
    value: string;
    helperText: string;
    error?: boolean;
    onChange: any;
    onBlur: any;
    headerText: string;
    classes?: {header?: string};
    disabled: boolean;
};

export default function PasswordField({
    autoFocus,
    placeholder,
    name,
    value,
    helperText,
    error,
    onChange,
    onBlur,
    headerText,
    classes,
    disabled
}: PasswordFieldProps): JSX.Element {
    const [passwordIsVisible, setPasswordIsVisible] = React.useState(false);

    const showPassword = () => {
        setPasswordIsVisible(true);
    };

    const hidePassword = () => {
        setPasswordIsVisible(false);
    };

    return (
        <StyledTextFieldWithHeader
            autoFocus={autoFocus}
            name={name}
            type={passwordIsVisible ? 'text' : 'password'}
            autoComplete='current-password'
            placeholder={placeholder}
            value={value}
            helperText={helperText}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            headerText={headerText}
            classes={classes}
            disabled={disabled}
            inputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={showPassword}
                            onMouseUp={hidePassword}>
                            {passwordIsVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
