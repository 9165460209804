import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import {files} from '../../assets/resources';

type ServiceNotWorkingPopupProps = {
    isOpen: boolean;
    name: string;
    onCancel: () => void;
};

const ServiceNotWorkingPopup = (props: ServiceNotWorkingPopupProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog classes={{paper: classes.paper}} open={props.isOpen}  onClose={props.onCancel} fullWidth>
            <DialogTitle className={classes.title}>
                <Typography variant='h4' component='span'>
                    {intl.formatMessage({id: `project.addEdit.generalDetails.ServiceNotWorking.title`})}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>
                    {intl.formatMessage({id: `project.addEdit.generalDetails.ServiceNotWorking.description`})}
                </Typography>
            </DialogContent>
            <DialogContent className={classes.link}>
                <Typography variant='subtitle2'>
                    <Link target='_blank'
                        rel='noopener'
                        underline='always'
                        href={files.troubleshootingGuide}
                        onMouseDown={e => e.preventDefault()}>
                        {intl.formatMessage({id: `project.addEdit.generalDetails.ServiceNotWorking.link`})}
                    </Link>
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StyledButton onClick={props.onCancel} color='primary'>
                    <Typography noWrap>
                        {intl.formatMessage({id: `project.addEdit.generalDetails.ServiceNotWorking.cancel`})}
                    </Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        content: {
            textAlign: 'center',
            padding: `${spacing(1)}px 20%`,
        },
        actions: {
            justifyContent: 'center',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
        link: {
            textAlign: 'center',
        },
    })
);

export default ServiceNotWorkingPopup;
