import React from 'react';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import {useIntl} from 'react-intl';

type EmailFieldProps = {
    autoFocus?: boolean;
    value: string;
    helperText: string;
    error?: boolean;
    onChange: any;
    onBlur: any;
    disabled: boolean;
    headerText: string;
    classes?: {header?: string};
};

export default function EmailField({
    autoFocus,
    value,
    helperText,
    error,
    onChange,
    onBlur,
    disabled,
    headerText,
    classes,
}: EmailFieldProps): JSX.Element {
    const intl = useIntl();

    return (
        <StyledTextFieldWithHeader
            autoFocus={autoFocus}
            name='email'
            type='email'
            placeholder={intl.formatMessage({id: 'generic.email.placeholder'})}
            value={value}
            helperText={helperText}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            headerText={headerText}
            classes={classes}
        />
    );
}
