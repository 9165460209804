import {ProjectShortInfoActionTypes, ProjectShortInfoState} from './types';

const initialState: ProjectShortInfoState = {
    projectShortInfos: [],
};

export const projectShortInfoReducer = (state = initialState, action: ProjectShortInfoActionTypes): ProjectShortInfoState => {
    switch (action.type) {
        case 'SET_PROJECT_SHORT_INFOS':
            return {projectShortInfos: action.projectShortInfos};
        default:
            return state;
    }
};
