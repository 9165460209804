import {TextField, withStyles, Theme} from '@material-ui/core';

const eboksBorderColor = '#d5e0e3';

const StyledTextField = withStyles(({palette}: Theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: eboksBorderColor,
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: palette.text.primary,
            },
            '&.Mui-focused fieldset': {
                borderColor: palette.text.primary,
            },
        },
        '& label.Mui-focused': {
            color: eboksBorderColor,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: eboksBorderColor,
        },
    },
}))(TextField);

export default StyledTextField;
