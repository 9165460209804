import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress, {CircularProgressProps} from '@material-ui/core/CircularProgress';

const StyledCircularProgress = (props: CircularProgressProps): JSX.Element => {
    const classes = useStylesFacebook();

    return (
        <div className={clsx(props.className, classes.root)}>
            <CircularProgress
                variant='determinate'
                className={classes.bottom}
                size={40}
                thickness={4}
                {...(({className, ...rest}) => rest)(props)}
                value={100}
            />
            <CircularProgress
                variant='indeterminate'
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={40}
                thickness={4}
                {...(({className, ...rest}) => rest)(props)}
            />
        </div>
    );
};

const useStylesFacebook = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.secondary.dark,
        },
        top: {
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    })
);

export default StyledCircularProgress;
