import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@material-ui/core';
import StyledButton from './styledButton';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import StyledTextField from './styledTextField';

type ConfirmationDialogProps = {
    isOpen: boolean;
    title: string;
    description: string;
    confirmationText: string;
    action: () => void;
    handleClose: () => void;
};

const ConfirmationDialog = ({
                                isOpen,
                                title,
                                description,
                                confirmationText,
                                action,
                                handleClose,
                            }: ConfirmationDialogProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const [confirmationFieldValue, setConfirmationFieldValue] = React.useState('');

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                handleClose();
                setConfirmationFieldValue('');
            }}
            classes={{paper: classes.paper}}
            fullWidth>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <StyledTextField
                autoFocus
                className={classes.textField}
                name='confirmationField'
                value={confirmationFieldValue}
                placeholder={intl.formatMessage(
                    {id: 'confirmationDialog.confirmationField.placeholder'},
                    {confirmationText},
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmationFieldValue(event.target.value);
                }}
            />
            <DialogActions>
                <StyledButton color='secondary' onClick={() => {
                    handleClose();
                    setConfirmationFieldValue('');
                }}>
                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                </StyledButton>
                <StyledButton
                    color='primary'
                    onClick={() => {
                        action();
                        handleClose();
                        setConfirmationFieldValue('');
                    }}
                    disabled={confirmationFieldValue !== confirmationText}>
                    <Typography>{intl.formatMessage({id: 'generic.button.delete'})}</Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
        },
        textField: {
            paddingTop: spacing(1),
            paddingRight: spacing(2.5),
            paddingBottom: spacing(1),
            paddingLeft: spacing(2.5),
        },
    }),
);

export default ConfirmationDialog;
