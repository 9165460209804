import React from 'react';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {images} from '../../assets/resources';

const NewProjectCard = () => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Card className={classes.root} onClick={() => Router.routes.addProject.go()}>
            <CardActionArea className={classes.actionArea}>
                <CardContent className={classes.content}>
                    <Typography variant='h4' className={classes.title}>
                        {intl.formatMessage({id: 'actions.createNewProject'})}
                    </Typography>
                    <Typography variant='h6'>{intl.formatMessage({id: 'projects.newProjectCard.subtitle'})}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        root: {
            height: 260,
            border: 1,
            boxShadow: 'none',
            backgroundImage: `url(${images.dashedBorders})`,
            borderRadius: spacing(2),
            backgroundColor: '#e0e3e6',
        },
        actionArea: {
            height: '100%',
        },
        content: {
            paddingLeft: spacing(4),
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 700,
        },
    })
);

export default NewProjectCard;
