import {
    SET_PROJECTS,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    SetProjectsAction,
    DeleteProjectAction,
    CreateProjectAction,
    UpdateProjectAction,
} from './types';
import {ProjectResponse} from '../../api/apiContracts';

export const setProjects = (projects: ProjectResponse[]): SetProjectsAction => {
    return {
        type: SET_PROJECTS,
        projects: projects,
    };
};

export const createProject = (project: ProjectResponse): CreateProjectAction => {
    return {
        type: CREATE_PROJECT,
        project: project,
    };
};

export const updateProject = (project: ProjectResponse): UpdateProjectAction => {
    return {
        type: UPDATE_PROJECT,
        project: project,
    };
};

export const deleteProject = (id: number): DeleteProjectAction => {
    return {
        type: DELETE_PROJECT,
        id: id,
    };
};
