export const images = {
  logo: require("./logo.svg"),
  logo_sandbox: require("./e-boks_sandbox.svg"),
  previewAppTile: require("./previewAppTile.png"),
  dashedBorders: require("./dashedBorders.svg"),
  eboksDK: require("./eBoksDK.png"),
  eboksNO: require("./eBoksNO.png"),
  eboksSE: require("./eBoksSE.png"),
  build: require("./build.png"),
  test: require("./test.png"),
  ship: require("./ship.png"),
};

export const files = {
  tc: require("./e-Boks Plus Sandbox TCs (v.2).pdf"),
  guideSSO: require("./e-Boks Sandbox Service Guide 1.01.pdf"),
  troubleshootingGuide: require("./Sandbox Troubleshooting Guide.pdf"),
};
