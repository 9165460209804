import React from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import {forgotPassword} from '../../api/apiClient';
import {emailFormatValidationRegex} from '../../consts/regexConsts';
import EmailField from '../shared/emailField';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';

const ForgotPasswordForm = (): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(intl.formatMessage({id: 'validation.email.empty'}))
            .matches(emailFormatValidationRegex, intl.formatMessage({id: 'validation.email.format'})),
    });

    const handleBack = () => {
        Router.routes.login.go();
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            await forgotPassword(values);
            Router.routes.forgotPasswordSuccess.go();
        } catch (e) {
            handleResponseError(e);
            setSubmitting(false);
        }
    };

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, isValid, handleChange, handleBlur} = props;

                    return (
                        <Form>
                            <EmailField
                                autoFocus
                                value={values.email}
                                helperText={(touched.email && errors.email) as string}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={false}
                                headerText={intl.formatMessage({id: 'forgotPassword.email.heading'})}
                            />

                            <Grid container className={classes.buttonsOuterGrid} direction='row'>
                                <Grid item xs={5}>
                                    <StyledButton color='secondary' onClick={handleBack}
                                        onMouseDown={e => e.preventDefault()}  type='reset'>
                                        {intl.formatMessage({id: 'generic.button.back'})}
                                    </StyledButton>
                                </Grid>
                                <Grid item xs={7}>
                                    <StyledButton
                                        color='primary'
                                        type='submit'
                                        disabled={!isValid}
                                        onMouseDown={e => e.preventDefault()}
                                        loading={isSubmitting}>
                                        {intl.formatMessage({id: 'forgotPassword.button.send'})}
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsOuterGrid: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1.5),
        },
    })
);

export default ForgotPasswordForm;
