import React, {useState, useRef, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {SketchPicker, ColorResult, Color} from 'react-color';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OutsideClickHandler from 'react-outside-click-handler';

export type {ColorResult};

export type ColorPickerProps = {
    color: Color;
    onColorChange: (color: ColorResult) => void;
};

const ColorPicker = (props: ColorPickerProps): JSX.Element => {
    const classes = useStyles();

    const [isColorPickerVisible, setIsColorPickerVisible] = useState<boolean>(false);
    const colorPickerWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isColorPickerVisible && colorPickerWrapperRef && colorPickerWrapperRef.current) {
            colorPickerWrapperRef.current.focus();
        }
    }, [isColorPickerVisible]);

    return (
        <Grid container justify='center'>
            <OutsideClickHandler onOutsideClick={() => setIsColorPickerVisible(false)}>
                <Grid item>
                    <Grid container alignItems='center' onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}>
                        <Grid item>
                            <ExpandMoreIcon />
                        </Grid>
                        <Grid item>
                            <div className={classes.colorPreview} style={{backgroundColor: props.color.toString()}} />
                        </Grid>
                    </Grid>
                </Grid>
                {isColorPickerVisible ? (
                    <Grid item>
                        <div className={classes.colorPickerWrapper} tabIndex={0} ref={colorPickerWrapperRef}>
                            <SketchPicker
                                disableAlpha={true}
                                color={props.color}
                                onChangeComplete={props.onColorChange}
                            />
                        </div>
                    </Grid>
                ) : null}
            </OutsideClickHandler>
        </Grid>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        colorPickerWrapper: {
            display: 'inline-block',
            position: 'absolute',
            zIndex: 2,
            '&:focus': {
                outline: 'none',
            },
        },
        colorPreview: {
            width: spacing(4),
            height: spacing(4),
            borderRadius: spacing(0.5),
            border: `solid ${spacing(0.25)}px rgba(0, 0, 0, 0.1)`,
        },
        actions: {
            marginTop: spacing(4),
        },
    })
);

export default ColorPicker;
