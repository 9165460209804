import {applyMiddleware, createStore, AnyAction} from 'redux';
import thunk, {ThunkMiddleware} from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';
import {composeWithDevTools} from 'redux-devtools-extension';
import {History} from 'history';
import {history} from '../routing/history';
import createRootReducer from './rootReducer';

const configureStore = (history: History, initialState?: ApplicationState) => {
    const middlewares = [thunk as ThunkMiddleware<ApplicationState, AnyAction>, routerMiddleware(history)];
    const composeEnhancers = composeWithDevTools({});
    const rootReducer = createRootReducer(history);

    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

const store = configureStore(history);

export type ApplicationState = ReturnType<ReturnType<typeof createRootReducer>>;

export default store;
