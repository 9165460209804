import {useIntl} from 'react-intl';
import React, {useState} from 'react';
import {Grid, IconButton, InputAdornment} from '@material-ui/core';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import {Edit} from '@material-ui/icons';
import ChangeEmailDialog from './changeEmailDialog';

type ChangeEmailFormProps = {
    currentEmail: string;
};

const ChangeEmailForm = ({currentEmail}: ChangeEmailFormProps): JSX.Element => {
    const intl = useIntl();
    const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] = useState(false);

    return (
        <Grid item xs={12}>
            <StyledTextFieldWithHeader
                inputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={() => setIsChangeEmailDialogOpen(true)}>
                                <Edit />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={currentEmail}
                disabled={true}
                headerText={intl.formatMessage({id: 'profile.email.heading'})}
            />
            <ChangeEmailDialog
                currentEmail={currentEmail}
                isOpen={isChangeEmailDialogOpen}
                onClose={() => setIsChangeEmailDialogOpen(false)}
            />
        </Grid>
    );
};

export default ChangeEmailForm;
