import {SET_PROJECT_SHORT_INFOS, SetProjectShortInfosAction} from './types';
import {ProjectShortInfoResponse} from '../../api/apiContracts';

export const setProjectShortInfosAction = (
    projectShortInfos: ProjectShortInfoResponse[]
): SetProjectShortInfosAction => {
    return {
        type: SET_PROJECT_SHORT_INFOS,
        projectShortInfos: projectShortInfos,
    };
};
