import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@material-ui/core';
import StyledButton from './styledButton';

type DiscardChangesConfirmationPopupProps = {
    isOpen: boolean;
    name: string;
    onOk: () => void;
    onSave?: () => void;
    onCancel: () => void;
};

const DiscardChangesConfirmationPopup = (props: DiscardChangesConfirmationPopupProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog classes={{paper: classes.paper}} open={props.isOpen}  onClose={props.onCancel} fullWidth>
            <DialogTitle className={classes.title}>
                <Typography variant='h4' component='span'>
                    {intl.formatMessage({id: `project.addEdit.${props.name}.discardChangesConfirmation.title`})}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>
                    {intl.formatMessage({id: `project.addEdit.${props.name}.discardChangesConfirmation.description`})}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StyledButton onClick={props.onOk} color='primary'>
                    <Typography noWrap>
                        {intl.formatMessage({id: `project.addEdit.${props.name}.discardChangesConfirmation.ok`})}
                    </Typography>
                </StyledButton>
                {props.onSave ? (
                <StyledButton onClick={props.onSave} color='primary'>
                    <Typography noWrap>
                        {intl.formatMessage({id: `project.addEdit.${props.name}.discardChangesConfirmation.save`})}
                    </Typography>
                </StyledButton>
                ) : null}
                <StyledButton onClick={props.onCancel} color='default'>
                    <Typography noWrap>
                        {intl.formatMessage({id: `project.addEdit.${props.name}.discardChangesConfirmation.cancel`})}
                    </Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        content: {
            textAlign: 'center',
            padding: `${spacing(1)}px 20%`,
        },
        actions: {
            justifyContent: 'center',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);

export default DiscardChangesConfirmationPopup;
