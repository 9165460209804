import {combineReducers} from 'redux';
import {History} from 'history';
import {projectsReducer} from './projects/reducers';
import {testUsersReducer} from './testUsers/reducers';
import {usersReducer} from './users/reducers';
import {statsReducer} from './stats/reducers';
import {projectShortInfoReducer} from './projectShortInfo/reducers';
import {connectRouter} from 'connected-react-router';

const rootReducer = (history: History) =>
    combineReducers({
        users: usersReducer,
        testUsers: testUsersReducer,
        projects: projectsReducer,
        projectShortInfo: projectShortInfoReducer,
        stats: statsReducer,
        router: connectRouter(history),
    });

export default rootReducer;
