import React, { MouseEvent } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Checkbox, FormControlLabel, Link, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {files} from '../../assets/resources';

type TermsCheckboxProps = {
    checked: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    onBlur?: (event: React.FocusEvent<any>) => void;
    name: string;
};

export default function TermsCheckbox(props: TermsCheckboxProps): JSX.Element {
    const classes = useStyles(props);
    const intl = useIntl();

    return (
        <FormControlLabel
            className={classes.termsContainer}
            label={
                <Typography className={classes.terms}>
                    {intl.formatMessage({id: `${props.name}.main`},
                        {
                            terms: 
                                <Link
                                    target='_blank'
                                    rel='noopener'
                                    underline='always'
                                    href={files.tc}
                                    onMouseDown={(e:MouseEvent) => e.preventDefault()}>
                                    {intl.formatMessage({id: `${props.name}.link`})}
                                </Link>
                            ,
                            policy: 
                                <Link
                                    target='_blank'
                                    rel='noopener'
                                    underline='always'
                                    href={intl.formatMessage({id: `${props.name}.privacypolicy.url`})}
                                    onMouseDown={(e:MouseEvent) => e.preventDefault()}>
                                    {intl.formatMessage({id: `${props.name}.privacypolicy`})}
                                </Link>
                        }                                        
                    )}
                </Typography>
            }
            control={
                <Checkbox
                    name='termsAccepted'
                    color='primary'
                    checked={props.checked}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    onMouseDown={e => e.preventDefault()}
                    onClick={props.onBlur}
                />
            }
        />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        termsContainer: {
            paddingTop: theme.spacing(1),
        },
        terms: {
            paddingTop: theme.spacing(0.5),
            color: theme.palette.info.main,
            fontSize: 14,
            fontWeight: 600,
        },
    })
);
