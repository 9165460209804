import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {ProjectShortInfoResponse, ProjectStatus} from '../../api/apiContracts';
import MaterialTable, {Column, MTableToolbar} from './materialTable';
import StyledButton from '../shared/styledButton';
import Router from '../../routing/router';
import {ApplicationState} from '../../store';
import {useDispatch, useSelector} from 'react-redux';
import {AppThunkDispatch} from '../../store/types';
import {loadProjectShortInfos} from '../../thunks/projectShortInfoThunks';

type Row = ProjectShortInfoResponse;

const ProjectShortInfosTable = () => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch: AppThunkDispatch = useDispatch();
    const {handleResponseError} = useErrorHandler();

    const [loading, setLoading] = useState<boolean>(true);
    const projectShortInfos = useSelector<ApplicationState, ProjectShortInfoResponse[]>(
        s => s.projectShortInfo.projectShortInfos
    );
    
    useEffect(() => {
        (async (): Promise<void> => {
            try {
                await dispatch(loadProjectShortInfos());
            } catch (e) {
                handleResponseError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, handleResponseError, intl]);

    const columns: Column<Row>[] = [
        {
            title: intl.formatMessage({id: 'projectsManagement.table.headers.id'}), 
            type: 'string', 
            field: 'id'
        },
        {
            title: intl.formatMessage({id: 'projectsManagement.table.headers.name'}), 
            type: 'string', field: 'name', 
            customSort: (a, b) => a.name.localeCompare(b.name, navigator.language),
        },
        {
            title: intl.formatMessage({id: 'projectsManagement.table.headers.email'}), 
            type: 'string', 
            field: 'userEmail',
            customSort: (a, b) => a.userEmail.localeCompare(b.userEmail, navigator.language),
        },
        {
            title: intl.formatMessage({id: 'projectsManagement.table.headers.status'}),
            field: 'status',
            type: 'string'
        },
        {
            title: intl.formatMessage({id: 'projectsManagement.table.headers.review'}),
            field: 'status',
            headerStyle: {textAlign: 'center'},
            render: rowData =>
                rowData.status === ProjectStatus.Pending ? (
                    <StyledButton
                        color='primary'
                        onClick={event => {
                            event.stopPropagation();
                            Router.routes.projectReview.go({id: rowData.id});
                        }}>
                        {intl.formatMessage({id: 'projectsManagement.button.review'})}
                    </StyledButton>
                ) : null,
            customSort: s => s.status === ProjectStatus.Pending ? -1 : 1,
        },
    ];

    const onRowClick = async (event, row: Row | undefined, togglePanel: any): Promise<any> => {
        if (row)
        {
            if (row.status === ProjectStatus.Pending)
                Router.routes.projectReview.go({id: row.id});
            else    
                Router.routes.editProject.go({id: row.id});
        }
    };

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'projectsManagement.title'})}
            columns={columns}
            data={projectShortInfos}
            onRowClick={onRowClick}
            options={{
                search: true,
                padding: 'dense',
                headerStyle: {
                    fontWeight: 'bold',
                },
                emptyRowsWhenPaging: false
            }}
            isLoading={loading}
            components={{
                Toolbar: props => (
                    <div className={classes.toolbar}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
        />
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        toolbar: {
            '& .MuiFormControl-fullWidth': {
                width: 'auto',
            },
        },
    }),
);

export default ProjectShortInfosTable;
