import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {Collapse, Drawer, List, ListItem, ListItemText, Divider, Box} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {authService} from '../../services/authService';
import {UserRole} from '../../api/apiContracts';

const HomeSideMenu = (props: {children?: React.ReactNode}) => {
    const classes = useStyles();
    const intl = useIntl();
    const location = useSelector<ApplicationState, string>(s => s.router.location.pathname);
    const [managementOpen, setManagementOpen] = useState<boolean>(true);
    const userIsManager = authService.getUserRoles().includes(UserRole.Manager);
    const userIsAdministrator = authService.getUserRoles().includes(UserRole.Administrator);

    return (
        <Drawer
            variant='permanent'
            className={classes.drawer}
            classes={{paper: classes.drawerPaper, paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft}}>
            {props.children}
            <List>
                <StyledListItem
                    button
                    selected={location === (Router.routes.index.props.path as string)}
                    onClick={() => Router.routes.index.go()}>
                    <ListItemText primary={intl.formatMessage({id: 'sideMenu.home'})}/>
                </StyledListItem>
                <Divider variant='middle'/>
                <StyledListItem
                    button
                    selected={location === (Router.routes.help.props.path as string)}
                    onClick={() => {window.open(intl.formatMessage({id: 'header.actions.helpCenter.url'}), '_blank', 'noopener')}}>
                    <ListItemText primary={intl.formatMessage({id: 'sideMenu.helpCenter'})}/>
                </StyledListItem>
                <Divider variant='middle'/>
                {userIsManager ? (
                    <>
                        <StyledListItem button onClick={() => setManagementOpen(!managementOpen)}>
                            <ListItemText primary={intl.formatMessage({id: 'sideMenu.management'})}/>
                            {managementOpen ? <ExpandLess/> : <ExpandMore/>}
                        </StyledListItem>
                        <Collapse in={managementOpen} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                <StyledListItem
                                    button
                                    selected={location === (Router.routes.managementDashboard.props.path as string)}
                                    onClick={() => Router.routes.managementDashboard.go()}
                                    className={classes.nested}>
                                    <ListItemText primary='Dashboard'/>
                                </StyledListItem>
                                <StyledListItem
                                    button
                                    selected={location === (Router.routes.projectsManagement.props.path as string)}
                                    onClick={() => Router.routes.projectsManagement.go()}
                                    className={classes.nested}>
                                    <ListItemText primary='Projects'/>
                                </StyledListItem>
                            </List>
                        </Collapse>
                    </>
                ) : null}
                {userIsAdministrator ?
                    <StyledListItem
                        button
                        selected={location === (Router.routes.userAdministration.props.path as string)}
                        onClick={() => Router.routes.userAdministration.go()}>
                        <ListItemText primary={intl.formatMessage({id: 'sideMenu.userAdministration'})}/>
                    </StyledListItem> : null}
            </List>
            {!Router.isProjectsPath(location) ?
                (<ListItem disableGutters>
                        <StyledButton color='primary' onClick={() => Router.routes.addProject.go()}>
                            <Box component='span' fontWeight={500}>
                                {intl.formatMessage({id: 'actions.createNewProjectButton'})}
                            </Box>
                        </StyledButton>
                    </ListItem>
                ) : null}
        </Drawer>
    );
};

const drawerWidth = 250;
const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: palette.secondary.main,
        },
        drawerPaperAnchorDockedLeft: {
            border: 'none',
        },
        nested: {
            paddingLeft: spacing(4),
        },
    }),
);

const StyledListItem = withStyles(({palette, spacing}: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'baseline',
        '&$selected': {
            backgroundColor: 'transparent',
            color: palette.primary.main,
            fontWeight: 700,
            '& span': {
                fontWeight: 700,
            },
            '&::before': {
                content: '"|"',
                fontSize: 20,
                lineHeight: '16px',
                paddingRight: spacing(0.5),
            },
        },
    },
    selected: {},
}))(ListItem);

export default HomeSideMenu;
