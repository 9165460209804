import React from 'react';
import {useToasts} from 'react-toast-notifications';
import {useIntl} from 'react-intl';
import {logError} from './errorLogging';

export const useErrorHandler = () => {
    const {addToast} = useToasts();
    const intl = useIntl();

    const handleError = React.useCallback(
        (message: string, exception?: any) => {
            addToast(message, {appearance: 'error'});
            logError({message: message, exception: exception});
        },
        [addToast]
    );

    const handleResponseError = React.useCallback(
        (e: any) => {
            e = e || {};
            e.message = e.message || intl.formatMessage({id: 'generic.exception'});
            handleError(e.message, e.exception);
        },
        [handleError, intl]
    );

    return {
        handleError,
        handleResponseError,
    };
};
