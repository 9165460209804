import {TestUserResponse} from '../../api/apiContracts';

export type TestUsersState = {
    testUsers: TestUserResponse[];
};

export const SET_TEST_USERS = 'SET_TEST_USERS';
export const CREATE_TEST_USER = 'CREATE_TEST_USER';
export const UPDATE_TEST_USER = 'UPDATE_TEST_USER';
export const DELETE_TEST_USER = 'DELETE_TEST_USER';

export type SetTestUsersAction = {
    type: typeof SET_TEST_USERS;
    testUsers: TestUserResponse[];
};

export type CreateTestUserAction = {
    type: typeof CREATE_TEST_USER;
    testUser: TestUserResponse;
};

export type UpdateTestUserAction = {
    type: typeof UPDATE_TEST_USER;
    testUser: TestUserResponse;
};

export type DeleteTestUserAction = {
    type: typeof DELETE_TEST_USER;
    id: number;
};

export type TestUsersActionTypes =
    | SetTestUsersAction
    | CreateTestUserAction
    | UpdateTestUserAction
    | DeleteTestUserAction;
