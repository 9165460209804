import React from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Grid, FormHelperText} from '@material-ui/core';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import {emailFormatValidationRegex} from '../../consts/regexConsts';
import {validationConstraints} from '../../consts/validationConstrs';
import PasswordField from '../shared/passwordField';
import EmailField from '../shared/emailField';
import StyledButton from '../shared/styledButton';
import {registerIdentity} from '../../api/apiClient';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import TermsCheckbox from '../shared/termsCheckbox';
import {userNameRegex, userNameNotEmptyRegex} from '../../consts/regexConsts';
import ReCAPTCHA from "react-google-recaptcha";

const RegistrationForm = (): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();

    const validationSchema = Yup.object().shape({
        displayName: Yup.string()
            .max(
                validationConstraints.name.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.name.maxLength}),
            )
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'registration.name.heading'})}))
            .matches(userNameNotEmptyRegex, intl.formatMessage({id: 'validation.name.invalidcharacters'}))
            .matches(userNameRegex, intl.formatMessage({id: 'validation.name.invalidcharacters'})),
        email: Yup.string()
            .max(
                validationConstraints.name.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.email.maxLength}),
            )
            .required(intl.formatMessage({id: 'validation.email.empty'}))
            .matches(emailFormatValidationRegex, intl.formatMessage({id: 'validation.email.format'})),
        password: Yup.string()
            .required(intl.formatMessage({id: 'validation.password.empty'}))
            .min(validationConstraints.password.minLength,
                intl.formatMessage({id: 'validation.password.length'}, {minLength: validationConstraints.password.minLength}))
           .test(
                'password',
                intl.formatMessage({id: 'validation.password.case'}),
                value => value != null && value.toLowerCase() !== value,
            ),
        termsAccepted: Yup.boolean().oneOf([true], intl.formatMessage({id: 'validation.accepted'})),
        notABot: Yup.boolean().oneOf([true]),
    });

    const handleBack = () => {
        Router.routes.login.go();
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            await registerIdentity(values);
            Router.routes.registrationSuccess.go();
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={{displayName: '', email: '', password: '', termsAccepted: false, notABot: false}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, isValid, handleChange, handleBlur, setFieldValue} = props;

                    return (
                        <>
                        <Form>
                            <StyledTextFieldWithHeader
                                autoFocus
                                placeholder={intl.formatMessage({id: 'registration.name.placeholder'})}
                                name='displayName'
                                value={values.displayName}
                                helperText={errors.displayName as string}
                                error={Boolean(errors.displayName)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                headerText={intl.formatMessage({id: 'registration.name.heading'})}
                            />

                            <EmailField
                                value={values.email}
                                helperText={(touched.email && errors.email) as string}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={false}
                                headerText={intl.formatMessage({id: 'registration.email.heading'})}
                            />

                            <PasswordField
                                placeholder={intl.formatMessage({id: 'registration.password.placeholder'})}
                                name='password'
                                value={values.password}
                                helperText={(touched.password && errors.password) as string}
                                error={touched.password && Boolean(errors.password)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                headerText={intl.formatMessage({id: 'registration.password.heading'})}
                                disabled={isSubmitting}
                            />

                            <ReCAPTCHA sitekey="6LfELhMaAAAAAOL8-pz2CuRe3WrWfGAhIkh_3oAV" className={classes.captchaContainer}
                                onChange={() => setFieldValue('notABot', true)} 
                                onExpired={() => setFieldValue('notABot', false)}
                            />

                            <TermsCheckbox checked={values.termsAccepted} 
                                name='registration.terms'
                                onChange={handleChange} onBlur={handleBlur} />
                            <FormHelperText 
                                error={touched.termsAccepted && Boolean(errors.termsAccepted)} 
                                variant="filled">{touched.termsAccepted && errors.termsAccepted}</FormHelperText>

                            <Grid container spacing={0} className={classes.buttonsOuterGrid}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledButton color='secondary'  type='reset'
                                                  onMouseDown={e => e.preventDefault()}
                                                  onClick={handleBack}>
                                        {intl.formatMessage({id: 'generic.button.back'})}
                                    </StyledButton>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Grid container justify='flex-end'>
                                        <StyledButton
                                            color='primary'
                                            type='submit'
                                            disabled={!isValid}
                                            onMouseDown={e => e.preventDefault()}
                                            loading={isSubmitting}>
                                            {intl.formatMessage({id: 'registration.button.create'})}
                                        </StyledButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                        </>           
                    );
                }}
            </Formik>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputHeading: {
            color: theme.palette.primary.main,
        },
        buttonsOuterGrid: {
            paddingTop: theme.spacing(2.5),
        },
        captchaContainer: {
            paddingTop: theme.spacing(2.5),
        }
    }),
);

export default RegistrationForm;
