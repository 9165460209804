import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import RegistrationForm from '../components/forms/registrationForm';
import WelcomeBox from '../components/shared/welcomeBox';

const Registration = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <WelcomeBox name='registration'>
                <RegistrationForm />
            </WelcomeBox>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            height: '100vh',
            minHeight: '100vh',
        },  
    })
);

export default Registration;
