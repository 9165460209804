import {images} from '../../assets/resources';
import {Grid, Typography} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';

type WelcomeBoxProps = {
    children: ReactNode;
    name?: string;
    subName?: string;
};

export default function WelcomeBox({children, name, subName}: WelcomeBoxProps): JSX.Element {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <img src={images.logo_sandbox} alt={intl.formatMessage({id: 'e-boks.logo'})} className={classes.logo} />
            </div>
            <div className={classes.box}>
                <Grid container spacing={0} className={classes.gridsContainer}>
                    <Grid item xs={12} className={classes.headingContainer}>
                        <h1 className={classes.heading}>{intl.formatMessage({id: `${(name??'welcome')}.title`}, {br: <br/>})}</h1>
                        {subName ? (
                            <Typography>
                                {intl.formatMessage({id: `${(subName??'welcome')}.title`}, {br: <br/>})}
                            </Typography>
                        ) : null}
                    </Grid>
                    {children}
                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            position: 'absolute',
            paddingTop: 10,
            [theme.breakpoints.down('sm')]: {
                left: '2%',
                marginRight: '2%',
            },
            [theme.breakpoints.only('md')]: {
                left: '12%',
                marginRight: '12%',
            },
            [theme.breakpoints.only('lg')]: {
                left: '18%',
                top: '2%',
                transform: 'translate(18%)',
                marginRight: '36%',
            },
            [theme.breakpoints.up('xl')]: {
                left: '50%',
                top: '9%',
                transform: 'translate(-50%)',
                marginRight: '15%',
            },
        },
        logoContainer: {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        box: {
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 25,
        },
        logo: {
            [theme.breakpoints.down('sm')]: {
                height: '40px',
            },
            [theme.breakpoints.only('md')]: {
                height: '46px',
            },
            [theme.breakpoints.only('lg')]: {
                height: '56px',
            },
            [theme.breakpoints.up('xl')]: {
                height: '64px',
            }
        },
        gridsContainer: {
            paddingTop: theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: theme.spacing(5),
                paddingLeft: theme.spacing(5),
                paddingBottom: theme.spacing(4),
            },
            [theme.breakpoints.up('xl')]: {
                paddingRight: theme.spacing(9),
                paddingLeft: theme.spacing(9),
                paddingBottom: theme.spacing(5),
            },
        },
        headingContainer: {
            textAlign: 'center',
            lineHeight: '200%',
            paddingTop: theme.spacing(3),
        },
        heading: {
            color: theme.palette.info.main,
            [theme.breakpoints.down('md')]: {
                fontSize: 25,
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: 29,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: 32,
            },
            fontWeight: 400,
        },
    })
);
