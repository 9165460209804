import {UsersActionTypes, UsersState} from './types';

const initialState: UsersState = {
    users: [],
};

export const usersReducer = (state = initialState, action: UsersActionTypes): UsersState => {
    switch (action.type) {
        case 'SET_USERS':
            return {users: action.users};
        case 'UPDATE_USER':
            return {users: state.users.map(u => (u.id === action.user.id ? action.user : u))};
        case 'DELETE_USER':
            return {users: state.users.filter(u => u.id !== action.id)};
        default:
            return state;
    }
};
