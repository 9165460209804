import React from 'react';
import {Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const NotFound = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='h1'>Page not found</Typography>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            display: 'flex',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
);

export default NotFound;
