import {ProjectResponse} from '../../api/apiContracts';

export type ProjectsState = {
    projects: ProjectResponse[];
};

export const SET_PROJECTS = 'SET_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export type SetProjectsAction = {
    type: typeof SET_PROJECTS;
    projects: ProjectResponse[];
};

export type CreateProjectAction = {
    type: typeof CREATE_PROJECT;
    project: ProjectResponse;
};

export type UpdateProjectAction = {
    type: typeof UPDATE_PROJECT;
    project: ProjectResponse;
};

export type DeleteProjectAction = {
    type: typeof DELETE_PROJECT;
    id: number;
};

export type ProjectsActionTypes = SetProjectsAction | CreateProjectAction | UpdateProjectAction | DeleteProjectAction;
