import {UserResponse} from '../../api/apiContracts';
import {DELETE_USER, DeleteUserAction, SET_USERS, SetUsersAction, UPDATE_USER, UpdateUserAction} from './types';

export const setUsersAction = (users: UserResponse[]): SetUsersAction => {
    return {
        type: SET_USERS,
        users: users,
    };
};

export const updateUserAction = (user: UserResponse): UpdateUserAction => {
    return {
        type: UPDATE_USER,
        user: user,
    };
};

export const deleteUserAction = (id: string): DeleteUserAction => {
    return {
        type: DELETE_USER,
        id: id,
    };
};
