import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const IphoneFrame = ({ children }: { children?: React.ReactNode }): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.notch}>
                <div className={classes.camera} />
                <div className={classes.speaker} />
            </div>
            <div className={classes.topBar} />
            <div className={classes.sleep} />
            <div className={classes.bottomBar} />
            <div className={classes.volume} />
            <div className={classes.$overflow}>
                <div className={clsx(classes.$shadow, classes.shadowTr)} />
                <div className={clsx(classes.$shadow, classes.shadowTl)} />
                <div className={clsx(classes.$shadow, classes.shadowBr)} />
                <div className={clsx(classes.$shadow, classes.shadowBl)} />
            </div>
            <div className={classes.innerShadow} />
            <div className={classes.screen}>{children}</div>
        </div>
    );
};

const internalBorder = 23;
const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        root: {
            display: 'inline-block',
            position: 'relative',
            transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            width: '100%',
            height: '100%',
            padding: internalBorder,
            background: '#fdfdfd',
            boxShadow: 'inset 0 0 11px 0 black',
            borderRadius: '66px',
            zIndex: 10,

            '&:before': {
                width: 'calc(100% - 10px)',
                height: 'calc(100% - 10px)',
                position: 'absolute',
                top: '5px',
                content: '""',
                left: '5px',
                borderRadius: '61px',
                background: 'black',
                zIndex: 1,
            },
        },
        screen: {
            width: '100%',
            position: 'relative',
            height: '100%',
            zIndex: 3,
            background: 'white',
            overflow: 'hidden',
            display: 'block',
            borderRadius: '40px',
            boxShadow: 'none',
        },
        topBar: {
            display: 'block',
            width: '100%',
            position: 'absolute',
            height: '8px',
            background: 'rgba(black, 0.1)',
            left: 0,
            top: '80px',
        },
        bottomBar: {
            display: 'block',
            width: '100%',
            position: 'absolute',
            height: '8px',
            background: 'rgba(black, 0.1)',
            left: 0,
            bottom: '80px',
        },
        innerShadow: {
            width: 'calc(100% - 20px)',
            height: 'calc(100% - 20px)',
            position: 'absolute',
            top: '10px',
            overflow: 'hidden',
            left: '10px',
            borderRadius: '56px',
            boxShadow: 'inset 0 0 15px 0 rgba(white, 0.66)',
            zIndex: 1,

            '&:before': {
                boxShadow: 'inset 0 0 20px 0 #FFFFFF',
                width: '100%',
                height: '116%',
                position: 'absolute',
                top: '-8%',
                content: '""',
                left: 0,
                borderRadius: '200px / 112px',
                zIndex: 2,
            },
        },
        volume: {
            left: '-3px',
            top: '116px',
            height: '32px',
            width: '3px',
            background: '#b5b5b5',
            position: 'absolute',

            '&:before': {
                height: '62px',
                top: '62px',
                content: '""',
                left: 0,
                width: '3px',
                background: '#b5b5b5',
                position: 'absolute',
            },

            '&:after': {
                height: '62px',
                top: '140px',
                content: '""',
                left: 0,
                width: '3px',
                background: '#b5b5b5',
                position: 'absolute',
            },
        },
        sleep: {
            height: '96px',
            top: '200px',
            right: '-3px',
            width: '3px',
            background: '#b5b5b5',
            position: 'absolute',
        },
        camera: {
            width: '6px',
            height: '6px',
            top: '9px',
            borderRadius: '100%',
            position: 'absolute',
            left: '70%',
            background: '#0d4d71',
        },

        speaker: {
            height: '6px',
            width: '30%',
            left: '50%',
            transform: 'translateX(-50%)',
            position: 'absolute',
            top: '9px',
            background: '#171818',
            borderRadius: '6px',
        },

        notch: {
            position: 'absolute',
            width: '55%',
            height: '30px',
            top: internalBorder,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 4,
            background: 'black',
            borderBottomLeftRadius: '24px',
            borderBottomRightRadius: '24px',

            '&:before, &:after': {
                content: '""',
                height: '8px',
                position: 'absolute',
                top: 0,
                width: '8px',
            },

            '&:after': {
                background:
                    'radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%)',
                left: '-8px',
            },

            '&:before': {
                background:
                    'radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%)',
                right: '-8px',
            },
        },
        $overflow: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '66px',
            overflow: 'hidden',
        },
        $shadow: {
            borderRadius: '100%',
            width: '90px',
            height: '90px',
            position: 'absolute',
            background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 60%)',
        },
        shadowTr: {
            top: '-20px',
            right: '-20px',
        },
        shadowTl: {
            top: '-20px',
            left: '-20px',
        },
        shadowBr: {
            bottom: '-20px',
            right: '-20px',
        },
        shadowBl: {
            bottom: '-20px',
            left: '-20px',
        },
    })
);

export default IphoneFrame;
