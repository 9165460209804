import {ConnectedRouter} from 'connected-react-router';
import {history} from './routing/history';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {IntlProvider} from 'react-intl';
import english from './translations/en.json';
import Routes from './routing/routes';
import React from 'react';
import GlobalErrorBoundary from './errorHandling/globalErrorBoundary';
import {ToastProvider} from 'react-toast-notifications';
import CustomToast from './components/customToast';
import {eBoksRedTheme} from './components/shared/eBoksRedTheme';
import InitAxios from './startup/initAxios';
import LoadInitialData from './startup/loadInitialData';

// Localization
const translations = {
    en: english,
};
const browserLanguage = navigator.language.split(/[-_]/)[0];
const language = browserLanguage in translations ? browserLanguage : 'en';

export const App = () => {
    return (
        <ConnectedRouter history={history}>
            <ThemeProvider theme={eBoksRedTheme}>
                <CssBaseline>
                    <IntlProvider locale={language} messages={translations[language]}>
                        <ToastProvider components={{Toast: CustomToast}}>
                            <GlobalErrorBoundary>
                                <InitAxios>
                                    <LoadInitialData>
                                        <Routes />
                                    </LoadInitialData>
                                </InitAxios>
                            </GlobalErrorBoundary>
                        </ToastProvider>
                    </IntlProvider>
                </CssBaseline>
            </ThemeProvider>
        </ConnectedRouter>
    );
};
