//all these constants are 'keys' DONT change them
const invalidToken = 'Invalid token.';
const invalidLink = 'The link is invalid or has expired';
const accessKeyExpired = 'The service access key has expired';
const accessKeyInvalid = 'The service access key type is invalid';
const accessKeyNotFound = 'Unable to identify the service access key';
const emailNotConfirmed = 'The account has not been verified, please check your email and verify your account';

export const isInvalidToken = (message: string) => {
  return message === invalidToken || message === invalidLink;
}

export const isInvalidAccessKey = (message: string) => {
  return message === accessKeyExpired || message === accessKeyInvalid || message === accessKeyNotFound;
}

export const isNotConfirmed = (message: string) => {
  return message === emailNotConfirmed;
}

export const invalidTokenTypes = {
    PasswordReset: 'password reset',
    Collaborator: 'collaborator',
    EmailChange: 'email change',
    EmailConfirmation: 'email confirmation',
  };
  