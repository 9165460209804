import React from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import PasswordField from '../shared/passwordField';
import {resetPassword} from '../../api/apiClient';
import {UseQueryParams} from '../../utils/useQueryParams';
import EmailField from '../shared/emailField';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {validationConstraints} from '../../consts/validationConstrs';
import {isInvalidToken, invalidTokenTypes} from '../../consts/errorMessages';

const ResetPasswordForm = (): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const queryParams = UseQueryParams();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(),
        passwordResetToken: Yup.string().required(),
        newPassword: Yup.string()
            .required(intl.formatMessage({id: 'validation.password.empty'}))
            .min(validationConstraints.password.minLength,
                intl.formatMessage({id: 'validation.password.length'}, {minLength: validationConstraints.password.minLength}))
           .test(
                'newPassword',
                intl.formatMessage({id: 'validation.password.case'}),
                value => value != null && value.toLowerCase() !== value
            ),
    });

    const handleBack = () => {
        Router.routes.login.go();
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            await resetPassword(values);
            Router.routes.login.go();
        } catch (e) {
            if (isInvalidToken(e.message)) {
                Router.routes.invalidToken.go({type: invalidTokenTypes.PasswordReset});
            } else {
                handleResponseError(e);
                setSubmitting(false);
            }
        }
    };

    return (
        <Grid item xs={12}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: queryParams.get('Email'),
                    passwordResetToken: queryParams.get('PasswordResetToken'),
                    newPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, handleChange, handleBlur} = props;

                    return (
                        <Form>
                            <EmailField
                                autoFocus
                                value={values.email as string}
                                helperText={(touched.email && errors.email) as string}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                headerText={intl.formatMessage({id: 'resetPassword.email.heading'})}
                            />

                            <PasswordField
                                placeholder={intl.formatMessage({id: 'resetPassword.newPassword.placeholder'})}
                                name='newPassword'
                                value={values.newPassword}
                                helperText={(touched.newPassword && errors.newPassword) as string}
                                error={touched.newPassword && Boolean(errors.newPassword)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                headerText={intl.formatMessage({id: 'resetPassword.newPassword.heading'})}
                                disabled={isSubmitting}
                            />

                            <Grid container spacing={0} className={classes.buttonsOuterGrid}>
                                <Grid item xs={6}>
                                    <StyledButton color='secondary' type='reset' onMouseDown={e => e.preventDefault()}  onClick={handleBack}>
                                        {intl.formatMessage({id: 'generic.button.back'})}
                                    </StyledButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justify='flex-end'>
                                        <StyledButton
                                            color='primary'
                                            type='submit'
                                            disabled={Object.keys(errors).some(key => errors[key] && touched[key])}
                                            onMouseDown={e => e.preventDefault()}
                                            loading={isSubmitting}>
                                            {intl.formatMessage({id: 'resetPassword.button.reset'})}
                                        </StyledButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputHeading: {
            color: theme.palette.primary.main,
        },
        buttonsOuterGrid: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1.5),
        },
    })
);

export default ResetPasswordForm;
