import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ProjectShortInfosTable from '../components/tables/projectShortInfosTable';

const ProjectsManagement = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ProjectShortInfosTable />
        </div>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
    })
);

export default ProjectsManagement;
