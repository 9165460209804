import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import {images} from '../../assets/resources';

type FirstTimeLoginPopUpProps = {
    isOpen: boolean;
    onOk: () => void;
};

const FirstTimeLoginPopUp = (props: FirstTimeLoginPopUpProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog classes={{paper: classes.paper}} open={props.isOpen} onClose={props.onOk} fullWidth maxWidth='lg' scroll='body'>
            <DialogTitle className={classes.title}>
                <Typography variant='h4' component='span'>
                    {intl.formatMessage({id: 'firstTimeLoginPopUp.title'})}
                </Typography>
            </DialogTitle>
            <DialogContent >
                <Typography variant='h6' className={classes.subTitle}>
                    {intl.formatMessage({id: 'firstTimeLoginPopUp.subTitle'})}
                </Typography>
                <Grid container className={classes.imageContainer}  justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                    <img src={images.build} alt= {intl.formatMessage({id: 'firstTimeLoginPopUp.BuildIt'})} className={classes.image} />
                    </Grid>
                    <Grid item>
                    <img src={images.test} alt= {intl.formatMessage({id: 'firstTimeLoginPopUp.TestIt'})} className={classes.image} />
                    </Grid>
                    <Grid item>
                    <img src={images.ship} alt= {intl.formatMessage({id: 'firstTimeLoginPopUp.ShipIt'})} className={classes.image} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Grid item>
                    <StyledButton onClick={props.onOk} color='primary' >
                        <Typography>
                            {intl.formatMessage({id: 'firstTimeLoginPopUp.button.getStarted'})}
                        </Typography>
                    </StyledButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(3),
        },
        title: {
            textAlign: 'center',
            paddingTop: spacing(4),
        },
        subTitle: {
            textAlign: 'center',
            paddingTop: spacing(1),
            paddingBottom: spacing(5),
            marginLeft:  '22%',
            marginRight:  '22%',            
        },
        actions: {
            justifyContent: 'center',
            paddingBottom: '20px'

        },
        image: {
            height: '265px',
            marginRight: '8px',
            marginLeft: '8px',
        },
        imageContainer: {
            paddingBottom: '26px'
        },
    })
);

export default FirstTimeLoginPopUp;
