import React, {useState} from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {createStyles, Grid, makeStyles, Theme, Link} from '@material-ui/core';
import {emailFormatValidationRegex} from '../../consts/regexConsts';
import StyledButton from '../shared/styledButton';
import {useIntl} from 'react-intl';
import Router from '../../routing/router';
import EmailField from '../shared/emailField';
import PasswordField from '../shared/passwordField';
import {login, requestConfirmEmail} from '../../api/apiClient';
import {authService} from '../../services/authService';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {AppThunkDispatch} from '../../store/types';
import {useDispatch} from 'react-redux';
import {AuthResponse} from '../../api/apiContracts';
import {loadLoggedInUser} from '../../thunks/usersThunks';
import {isNotConfirmed} from '../../consts/errorMessages';
import RequestEmailConfirmationPopup from '../shared/requestEmailConfirmationPopup';

const LoginForm = (): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch: AppThunkDispatch = useDispatch();
    const {handleResponseError} = useErrorHandler();
    const [isRequestEmailConfirmationPopupOpened, setIsRequestEmailConfirmationPopupOpened] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(intl.formatMessage({id: 'validation.email.empty'}))
            .matches(emailFormatValidationRegex, intl.formatMessage({id: 'validation.email.format'})),
        password: Yup.string().required(intl.formatMessage({id: 'validation.password.empty'})),
    });

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            const authResponseData: AuthResponse = await login(values);
            authService.login(authResponseData.jwtToken, authResponseData.refreshToken);
            await dispatch(loadLoggedInUser());
            Router.routes.index.go();
        } catch (e) {
            setSubmitting(false);
            if (isNotConfirmed(e.message)) {
                handleResponseError({message: intl.formatMessage({id: 'login.verfication.resend'},  {
                    message: e.message,
                    link: (<Link  underline='always' style={{cursor:'pointer', fontWeight:500}}
                            onClick={async ()=> { await requestConfirmEmail(values); 
                                setIsRequestEmailConfirmationPopupOpened(true)}}>
                            {intl.formatMessage({id: 'login.verfication.link'})}
                           </Link>)
                })});
            } else {
                handleResponseError(e);
            }
        }
    };

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={{email: '', password: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, isValid, handleChange, handleBlur} = props;

                    const handleRequestEmailConfirmationOk = () => {
                        setIsRequestEmailConfirmationPopupOpened(false);
                    }
                
                    return (
                        <>
                        <Form>
                            <EmailField
                                autoFocus
                                value={values.email}
                                helperText={((touched.email && errors.email) as string) || ' '}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={false}
                                headerText={intl.formatMessage({id: 'login.email.heading'})}
                                classes={{header: classes.inputHeader}}
                            />

                            <PasswordField
                                placeholder={intl.formatMessage({id: 'login.password.placeholder'})}
                                name='password'
                                value={values.password}
                                helperText={((touched.password && errors.password) as string) || ' '}
                                error={touched.password && Boolean(errors.password)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                headerText={intl.formatMessage({id: 'login.password.heading'})}
                                classes={{header: classes.inputHeader}}
                                disabled={isSubmitting}
                            />

                            <Grid
                                container
                                spacing={0}
                                direction='column'
                                alignItems='stretch'
                                className={classes.buttonsGridContainer}>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={!isValid}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}
                                    classes={{wrapper: classes.buttonWrapper}}>
                                    {intl.formatMessage({id: 'login.button.login'})}
                                </StyledButton>
                            </Grid>
                        </Form>
                        <RequestEmailConfirmationPopup name='registration.confirmation.success' isOpen={isRequestEmailConfirmationPopupOpened} 
                            onOk={handleRequestEmailConfirmationOk}/>
                        </>
                    );
                }}
            </Formik>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsGridContainer: {
            paddingBottom: theme.spacing(1.5),
        },
        inputHeader: {
            paddingTop: 0,
            height: 26,
        },
        buttonWrapper: {
            paddingTop: 0,
        },
    })
);

export default LoginForm;
