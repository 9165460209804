import axios from 'axios';
import {getTestUsersRoute, createTestUserRoute, deleteTestUserRoute, patchTestUserRoute} from '../apiRoutes';
import {TestUserResponse, TestUserRequest, ITestUserResponse} from '../apiContracts';
import {compare} from 'fast-json-patch';
import {getResourceId} from '../../utils/getResourceId';

export const getTestUsers = async (): Promise<TestUserResponse[]> => {
    const result: TestUserResponse[] = (await axios.get(getTestUsersRoute)).data.map(t => TestUserResponse.fromJS(t));
    return result;
};

export const createTestUser = async (testUser: TestUserRequest): Promise<number> => {
    const response = await axios.post(createTestUserRoute, testUser);
    const id: number = getResourceId(response);
    return id;
};

export const deleteTestUser = async (testUserId: number): Promise<void> => {
    await axios.delete(deleteTestUserRoute(testUserId));
};

export const patchTestUser = async (
    oldTestUser: ITestUserResponse,
    newTestUser: ITestUserResponse
): Promise<TestUserResponse> => {
    const oldTestUserRequest = TestUserRequest.fromJS(oldTestUser);
    const newTestUserRequest = TestUserRequest.fromJS(newTestUser);

    const patch = compare(oldTestUserRequest, newTestUserRequest);
    if (patch.length) {
        await axios.patch(patchTestUserRoute(newTestUser.id), patch);
    }

    return TestUserResponse.fromJS(newTestUser);
};
