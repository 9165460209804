import {AppThunk} from '../store/types';
import {TestUserResponse, TestUserRequest, ITestUserResponse} from '../api/apiContracts';
import {
    createTestUser as createTestUserApi,
    deleteTestUser as deleteTestUserApi,
    patchTestUser,
} from '../api/apiClient';
import {getTestUsers} from '../api/apiClient';
import {
    createTestUserAction,
    deleteTestUserAction,
    setTestUsersAction,
    updateTestUserAction,
} from '../store/testUsers/actions';

export const loadTestUsers = (): AppThunk<Promise<void>> => async dispatch => {
    const testUsers: TestUserResponse[] = await getTestUsers();
    dispatch(setTestUsersAction(testUsers));
};

export const createTestUser = (testUser: TestUserRequest): AppThunk<Promise<number>> => async dispatch => {
    const id = await createTestUserApi(testUser);
    const testUserResponse: TestUserResponse = new TestUserResponse({...testUser, id: id});
    dispatch(createTestUserAction(testUserResponse));

    return id;
};

export const updateTestUser = (
    oldTestUser: ITestUserResponse,
    newTestUser: ITestUserResponse
): AppThunk<Promise<void>> => async dispatch => {
    const patchedTestUser = await patchTestUser(oldTestUser, newTestUser);
    dispatch(updateTestUserAction(patchedTestUser));
};

export const deleteTestUser = (id: number): AppThunk<Promise<void>> => async dispatch => {
    await deleteTestUserApi(id);
    dispatch(deleteTestUserAction(id));
};
