import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {getProjectById, checkProject} from '../api/apiClient';
import {ProjectResponse } from '../api/apiContracts';
import ProjectTestDetailsForm from '../components/forms/projectTestDetailsForm';
import {Typography} from '@material-ui/core';

type RouteParams = {
    id: string;
};

const EditProjectTestDetails = (props: RouteComponentProps<RouteParams>) => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();

    let projectId = 0;
    try {
        projectId = parseInt(props.match.params.id);
    } catch (e) {}

    const [loading, setLoading] = useState<boolean>(true);
    const [project, setProject] = useState<ProjectResponse>(new ProjectResponse());

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const project: ProjectResponse = await getProjectById(projectId);

                if (checkProject(project))
                {
                    setProject(project);
                }

            } catch (e) {
                handleResponseError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [handleResponseError, intl, projectId]);

    return (
        <div className={classes.root}>
            <Typography variant='body2'>
                {intl.formatMessage({id: 'project.addEdit.testDetails.description'})}
            </Typography>
            <ProjectTestDetailsForm loading={loading} project={project} setProject={setProject} />
        </div>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
    })
);

export default EditProjectTestDetails;
