import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {FormGroup, FormControlLabel, Checkbox, FormControl, Grid, Switch} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {ProjectTestOptions} from '../../api/apiContracts';
import InputTitle from '../shared/inputTitle';

type TestOptionsSelectionProps = {
    testOptions: number;
    setTestOptions: (testOptions: number) => void;
    loading: boolean;
};

const TestOptionsSelection = (props: TestOptionsSelectionProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const testOptionsLabels = {
        [ProjectTestOptions[ProjectTestOptions.Name]]: intl.formatMessage({
            id: 'project.addEdit.testOptions.name.label',
        }),
        [ProjectTestOptions[ProjectTestOptions.Email]]: intl.formatMessage({
            id: 'project.addEdit.testOptions.email.label',
        }),
        [ProjectTestOptions[ProjectTestOptions.PhoneNumber]]: intl.formatMessage({
            id: 'project.addEdit.testOptions.phoneNumber.label',
        }),
        [ProjectTestOptions[ProjectTestOptions.Identity]]: intl.formatMessage({
            id: 'project.addEdit.testOptions.identity.label',
        }),
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = props.testOptions;
        if (event.target.checked) {
            value = value | ProjectTestOptions[event.target.name];
        } else {
            value = value & ~ProjectTestOptions[event.target.name];
        }
        props.setTestOptions(value);
    };

    const isSSOOn = (): boolean => { return (props.testOptions & ProjectTestOptions.SignWithCertificate) === ProjectTestOptions.SignWithCertificate; }

    return (
        <Grid container direction='column' className={classes.root}>
            <Grid item>
                <InputTitle
                    name='testOptions.signWithCertificate'
                    values={{
                        signCertif: null/*(
                            <a href='javascript:void(0)' onClick={downloadSignCertificate}>
                                {intl.formatMessage({
                                    id: `project.property.testOptions.signWithCertificate.button.heading`,
                                })}
                            </a>
                        )*/,
                    }}
                />
                {props.loading ? (
                    <Skeleton
                        key={ProjectTestOptions[ProjectTestOptions.SignWithCertificate]}
                        variant='text'
                        width='100%'>
                        <Switch />
                    </Skeleton>
                ) : (
                    <Switch
                        key={ProjectTestOptions[ProjectTestOptions.SignWithCertificate]}
                        name={ProjectTestOptions[ProjectTestOptions.SignWithCertificate]}
                        checked={
                            (props.testOptions & ProjectTestOptions.SignWithCertificate) ===
                            ProjectTestOptions.SignWithCertificate
                        }
                        color='primary'
                        onChange={handleChange}
                        className={classes.switch}
                    />
                )}
            </Grid>
            <Grid item>
                <FormControl component='fieldset'>
                    <InputTitle name="testOptions"/>
                    <FormGroup>
                        {Object.keys(testOptionsLabels).map(key =>
                            props.loading ? (
                                <Skeleton key={key} variant='text' width='100%'>
                                    <Checkbox />
                                </Skeleton>
                            ) : (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                        checked={
                                            isSSOOn() &&
                                            (props.testOptions & ProjectTestOptions[key]) === ProjectTestOptions[key]
                                        }
                                        onChange={handleChange}
                                            name={key}
                                            color='primary'
                                        />
                                    }
                                    label={testOptionsLabels[key]}
                                    classes={{label: classes.label}}
                                    disabled={!isSSOOn()}
                                />
                            )
                        )}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
        title: {
            fontWeight: 'bold',
        },
        label: {
            fontWeight: 'bold',
        },
        switch: {
            marginLeft: '-12px',
        },
    })
);

export default TestOptionsSelection;
