import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Typography, FormHelperText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledButton from '../shared/styledButton';
import { ProjectSubmitDetailsResponse, ProjectStatus } from '../../api/apiContracts';
import { useErrorHandler } from '../../errorHandling/useErrorHandler';
import InputTitle from '../shared/inputTitle';
import { emailFormatValidationRegex } from '../../consts/regexConsts';
import { submitProject, createProjectSubmitDetails, patchProjectSubmitDetails } from '../../api/apiClient';
import ConfirmationPopup from '../editProjectSubmitDetails/confirmationPopup';
import StyledTextField from '../shared/styledTextField';
import Router from '../../routing/router';
import DiscardChangesConfirmationPopup from '../shared/DiscardChangesConfirmationPopup';
import TermsCheckbox from '../shared/termsCheckbox';
import { validationConstraints } from '../../consts/validationConstrs';

type ProjectSubmitDetailsFormProps = {
    loading: boolean;
    isEdit: boolean;
    projectId: number;
    projectStatus: ProjectStatus;
    projectSubmitDetails: ProjectSubmitDetailsResponse;
};

const ProjectSubmitDetailsForm = (props: ProjectSubmitDetailsFormProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const { handleResponseError } = useErrorHandler();
    const { loading, isEdit, projectId, projectSubmitDetails } = props;
    const [isCompletedConfirmationPopupOpened, setIsCompletedConfirmationPopupOpened] = useState<boolean>(false);
    const [isConfirmationPopupOpened, setIsConfirmationPopupOpened] = useState<boolean>(false);
    const [doGoBack, setDoGoBack] = useState<boolean>(false);
    const [leavingTo, setLeavingTo] = useState<string>('');

    const goBack = () => {
        //as it should be:  
        //setIsConfirmationPopupOpened(false);
        //Router.goBack();

        //as it is now, beause on in ProjectTestDetailsForm we can't calculate value of 'goBackSteps':
        Router.routes.editProjectTestDetails.go({ id: projectId });
    }

    const handleSubmit = async (values, { setSubmitting }): Promise<void> => {
        setSubmitting(true);
        try {
            const newProjectSubmitDetails = (({ termsAccepted, warningAccepted, ...rest }) => rest)(values);

            if (isEdit) {
                await patchProjectSubmitDetails(projectId, projectSubmitDetails, newProjectSubmitDetails);
            } else {
                await createProjectSubmitDetails(projectId, newProjectSubmitDetails);
            }

            if (leavingTo) {
                Router.goTo(leavingTo);
            } else if (doGoBack) {
                goBack();
            } else {
                await completeProjectSubmission();
            }

        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    const completeProjectSubmission = async (): Promise<void> => {
        try {
            await submitProject(projectId);
            setIsCompletedConfirmationPopupOpened(true);
        } catch (e) {
            handleResponseError(e);
        }
    };

    const validationSchema = Yup.object().shape({
        productionUrl: Yup.string()
            .url(intl.formatMessage({ id: 'validation.url' }, { property: intl.formatMessage({ id: 'project.property.productionUrl.heading' }) }))
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.productionUrl.heading' }) })),
        companyName: Yup.string()
            .max(
                validationConstraints.companyName.maxLength,
                intl.formatMessage(
                    { id: 'validation.maxLength' },
                    { maxLength: validationConstraints.companyName.maxLength }
                )
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.companyName.heading' }) })),
        address: Yup.string()
            .max(
                validationConstraints.address.maxLength,
                intl.formatMessage({ id: 'validation.maxLength' }, { maxLength: validationConstraints.address.maxLength })
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.address.heading' }) })),
        zipCode: Yup.string()
            .max(
                validationConstraints.zipCode.maxLength,
                intl.formatMessage({ id: 'validation.maxLength' }, { maxLength: validationConstraints.zipCode.maxLength })
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.zipCode.heading' }) })),
        city: Yup.string()
            .max(
                validationConstraints.city.maxLength,
                intl.formatMessage({ id: 'validation.maxLength' }, { maxLength: validationConstraints.city.maxLength })
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.city.heading' }) })),
        contactName: Yup.string()
            .max(
                validationConstraints.contactName.maxLength,
                intl.formatMessage(
                    { id: 'validation.maxLength' },
                    { maxLength: validationConstraints.contactName.maxLength }
                )
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.contactName.heading' }) })),
        contactPhone: Yup.string()
            .max(
                validationConstraints.contactPhone.maxLength,
                intl.formatMessage(
                    { id: 'validation.maxLength' },
                    { maxLength: validationConstraints.contactPhone.maxLength }
                )
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.contactPhone.heading' }) })),
        contactEmail: Yup.string()
            .matches(emailFormatValidationRegex, intl.formatMessage({ id: 'validation.email.format' }))
            .max(
                validationConstraints.contactEmail.maxLength,
                intl.formatMessage(
                    { id: 'validation.maxLength' },
                    { maxLength: validationConstraints.contactEmail.maxLength }
                )
            )
            .required(intl.formatMessage({ id: 'validation.required' }, { property: intl.formatMessage({ id: 'project.property.contactEmail.heading' }) })),
        reviewNotes: Yup.string().min(validationConstraints.reviewNotes.minLength,
            intl.formatMessage({ id: 'validation.minLength' }, { minLength: validationConstraints.reviewNotes.minLength }))
            .max(
                validationConstraints.reviewNotes.maxLength,
                intl.formatMessage({ id: 'validation.maxLength' }, { maxLength: validationConstraints.reviewNotes.maxLength })
            ),
        termsAccepted: Yup.boolean().oneOf([true], intl.formatMessage({ id: 'validation.accepted' })),
        warningAccepted: Yup.boolean().oneOf([true], intl.formatMessage({ id: 'validation.accepted' })),
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...projectSubmitDetails, termsAccepted: false, warningAccepted: false }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {props => {
                const { values, errors, touched, isSubmitting, dirty, isValid, handleChange, handleBlur, submitForm } = props;

                const handleGoBackButtonClick = () => {
                    if (dirty) {
                        setIsConfirmationPopupOpened(true);
                    } else {
                        goBack();
                    }
                };

                const handleDiscardChangesOk = () => {
                    if (leavingTo) {
                        Router.goTo(leavingTo);
                    } else {
                        goBack();
                    }
                };

                const handleDiscardChangesSave = async () => {
                    setIsConfirmationPopupOpened(false);

                    if (isValid) {
                        setDoGoBack(!leavingTo);
                        await submitForm();
                    }
                };

                const handleDiscardChangesCancel = () => {
                    setIsConfirmationPopupOpened(false);
                    setLeavingTo('');
                    setDoGoBack(false);
                };

                return (
                    <Form>
                        <Prompt when={dirty && !isSubmitting && !isConfirmationPopupOpened && !isCompletedConfirmationPopupOpened}
                            message={(location) => {
                                setLeavingTo(location.pathname);
                                setIsConfirmationPopupOpened(true);
                                return false;
                            }}
                        />

                        <Grid container justify='space-between'>
                            <Grid item xs={12} lg={7}>
                                <Grid container direction='column'>
                                    <Grid item>
                                        <InputTitle name='productionUrl' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    autoFocus
                                                    name='productionUrl'
                                                    value={values.productionUrl}
                                                    helperText={
                                                        (touched.productionUrl && errors.productionUrl) as string
                                                    }
                                                    error={touched.productionUrl && Boolean(errors.productionUrl)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='companyName' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    name='companyName'
                                                    value={values.companyName}
                                                    helperText={(touched.companyName && errors.companyName) as string}
                                                    error={touched.companyName && Boolean(errors.companyName)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.companyName.length}/${validationConstraints.companyName.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='address' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    name='address'
                                                    value={values.address}
                                                    helperText={(touched.address && errors.address) as string}
                                                    error={touched.address && Boolean(errors.address)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.address.length}/${validationConstraints.address.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <Grid container justify='space-between'>
                                            <Grid item xs={4}>
                                                <InputTitle name='zipCode' />
                                                {loading ? (
                                                    <Skeleton variant='rect' width='100%'>
                                                        <StyledTextField />
                                                    </Skeleton>
                                                ) : (
                                                        <StyledTextField
                                                            name='zipCode'
                                                            value={values.zipCode}
                                                            helperText={(touched.zipCode && errors.zipCode) as string}
                                                            error={touched.zipCode && Boolean(errors.zipCode)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position='end'
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }>{`${values.zipCode.length}/${validationConstraints.zipCode.maxLength}`}</InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                            </Grid>
                                            <Grid item xs={7}>
                                                <InputTitle name='city' />
                                                {loading ? (
                                                    <Skeleton variant='rect' width='100%'>
                                                        <StyledTextField />
                                                    </Skeleton>
                                                ) : (
                                                        <StyledTextField
                                                            name='city'
                                                            value={values.city}
                                                            helperText={(touched.city && errors.city) as string}
                                                            error={touched.city && Boolean(errors.city)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position='end'
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }>{`${values.city.length}/${validationConstraints.city.maxLength}`}</InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='contactName' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    name='contactName'
                                                    value={values.contactName}
                                                    helperText={(touched.contactName && errors.contactName) as string}
                                                    error={touched.contactName && Boolean(errors.contactName)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.contactName.length}/${validationConstraints.contactName.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='contactPhone' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    name='contactPhone'
                                                    value={values.contactPhone}
                                                    helperText={(touched.contactPhone && errors.contactPhone) as string}
                                                    error={touched.contactPhone && Boolean(errors.contactPhone)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.contactPhone.length}/${validationConstraints.contactPhone.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='contactEmail' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    name='contactEmail'
                                                    value={values.contactEmail}
                                                    helperText={(touched.contactEmail && errors.contactEmail) as string}
                                                    error={touched.contactEmail && Boolean(errors.contactEmail)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.contactEmail.length}/${validationConstraints.contactEmail.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='reviewNotes' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField multiline rows={5} />
                                            </Skeleton>
                                        ) : (
                                                <StyledTextField
                                                    multiline
                                                    inputProps={{ style: { resize: 'vertical' } }}
                                                    rows={5}
                                                    placeholder={intl.formatMessage({
                                                        id: 'project.property.reviewNotes.placeholder',
                                                    })}
                                                    name='reviewNotes'
                                                    value={values.reviewNotes}
                                                    helperText={(touched.reviewNotes && errors.reviewNotes) as string}
                                                    error={touched.reviewNotes && Boolean(errors.reviewNotes)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position='end'
                                                                className={
                                                                    classes.inputAdornment
                                                                }>{`${values.reviewNotes.length}/${validationConstraints.reviewNotes.maxLength}`}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                    </Grid>
                                    <Grid item>
                                        <TermsCheckbox checked={values.termsAccepted}
                                            name='project.addEdit.submitDetails.terms'
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                        <FormHelperText
                                            error={touched.termsAccepted && Boolean(errors.termsAccepted)}
                                            variant="filled">{touched.termsAccepted && errors.termsAccepted}</FormHelperText>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            label={
                                                <Typography className={classes.terms}>
                                                    {intl.formatMessage({ id: 'projectSubmitDetails.submit.warning' })}
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    name='warningAccepted'
                                                    color='primary'
                                                    checked={values.warningAccepted}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onMouseDown={e => e.preventDefault()}
                                                    onClick={handleBlur}
                                                />
                                            }
                                        />
                                        <FormHelperText
                                            error={touched.warningAccepted && Boolean(errors.warningAccepted)}
                                            variant="filled">{touched.warningAccepted && errors.warningAccepted}</FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid item className={classes.divider}>
                                    <Divider variant='fullWidth' />
                                </Grid>

                                <Grid container direction="row" >
                                    <Grid item>
                                        <StyledButton
                                            color='default'
                                            type='button'
                                            disabled={isSubmitting}
                                            onClick={handleGoBackButtonClick}
                                            onMouseDown={e => e.preventDefault()}>
                                            <Typography>
                                                {intl.formatMessage({
                                                    id: 'project.addEdit.testDetails.goBack',
                                                })}
                                            </Typography>
                                        </StyledButton>
                                    </Grid>
                                    <Grid item>
                                        <StyledButton
                                            color='primary'
                                            type='submit'
                                            disabled={
                                                !isValid
                                            }
                                            loading={isSubmitting}
                                            onMouseDown={e => e.preventDefault()}>
                                            <Typography>
                                                {intl.formatMessage({
                                                    id: 'project.addEdit.submitDetails.submit',
                                                })}
                                            </Typography>
                                        </StyledButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ConfirmationPopup isOpen={isCompletedConfirmationPopupOpened} />
                        <DiscardChangesConfirmationPopup isOpen={isConfirmationPopupOpened} name='submitDetails'
                            onOk={handleDiscardChangesOk} onSave={!isValid ? undefined : handleDiscardChangesSave} onCancel={handleDiscardChangesCancel} />
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        root: {},
        submitTitle: {
            fontWeight: 'bold',
            padding: `${spacing(1)}px 0px`,
            fontSize: '26px',
        },
        submitDescription: {
            padding: spacing(1),
        },
        submitButton: {
            paddingTop: spacing(2),
            width: '50%',
        },
        divider: {
            paddingTop: spacing(4),
            paddingBottom: spacing(3),
        },
        inputAdornment: {
            position: 'absolute',
            right: '13px',
            top: '13px',

            '& p': {
                fontSize: '11px',
            },
        },
        terms: {
            paddingTop: spacing(2),
            color: palette.info.main,
            fontWeight: 600,
            fontSize: 14,
        },
    })
);

export default ProjectSubmitDetailsForm;
