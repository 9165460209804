import React from 'react';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';

type InfoTooltipProps = {
    title: string | React.ReactNode;
};

const InfoTooltip = (props: InfoTooltipProps): JSX.Element => {
    const classes = useStyles();

    return (
        <CustomTooltip
            arrow
            placement='top'
            interactive
            TransitionComponent={Zoom}
            title={<Typography>{props.title}</Typography>}>
            <InfoIcon className={classes.icon} />
        </CustomTooltip>
    );
};

const CustomTooltip = withStyles(({palette, spacing}: Theme) => ({
    tooltip: {
        backgroundColor: palette.common.white,
        color: palette.info.light,
        textAlign: 'center',
        fontSize: '14px',
        maxWidth: spacing(32),
        padding: `${spacing(2.5)}px ${spacing(1)}px`,
        border: `1px solid ${palette.info.light}`,
        borderRadius: '4px',
    },
    arrow: {
        color: palette.common.white,
    },
}))(Tooltip);

const useStyles = makeStyles(
    createStyles({
        icon: {
            color: '#c1ced2',
            fontSize: 16,
        },
    })
);

export default InfoTooltip;
