import React from 'react';
import {useErrorHandler} from './useErrorHandler';

export type WithErrorHandlerProps = ReturnType<typeof useErrorHandler>;

export const withErrorHandler = (Component: any) => {
    return (props: any) => {
        const errorHandler = useErrorHandler();

        return <Component {...errorHandler} {...props} />;
    };
};
