import React from 'react';
import axios from 'axios';
import {useIntl} from 'react-intl';
import {authService} from '../services/authService';
import {CustomError} from '../errorHandling/types';

export type ErrorResponse = CustomError;

const InitAxios = ({children}: {children?: React.ReactNode}) => {
    const intl = useIntl();

    axios.defaults.baseURL = process.env.REACT_APP_SANDBOX_API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    axios.interceptors.request.use(config => {
        config.headers['Authorization'] = authService.generateAuthHeader();
        return config;
    });

    axios.interceptors.response.use(
        response => {
            return response;
        },
        async (error): Promise<CustomError | void> => {
            const originalRequest = error.config;
            const hasNotBeenRetried = !originalRequest._retry;

            if (error && error.response) {
                if ([400, 404].includes(error.response.status)) {
                    return Promise.reject({
                        message: error.response.data,
                        status: error.response.status,
                        exception: error,
                    });
                } else if (
                    error.response.status === 401 &&
                    hasNotBeenRetried &&
                    !authService.isRefreshTokenUrl(originalRequest.url)
                ) {
                    originalRequest._retry = true;
                    try {
                        await authService.refreshJwtToken();
                        originalRequest.headers['Authorization'] = authService.generateAuthHeader();
                    } catch (e) {
                        if (!e || !e.response || e.response.status === 401) {
                            authService.logOut();
                            return Promise.reject();
                        }
                    }
                }
            }

            return Promise.reject({message: intl.formatMessage({id: 'generic.exception'}), exception: error});
        }
    );

    return <>{children}</>;
};

export default InitAxios;
