import React from 'react';
import {Alert} from '@material-ui/lab';
import {Snackbar, Portal} from '@material-ui/core';

const CustomToast = ({appearance, children}) => {
    const [snackbarOpen, setSnackbarOpen] = React.useState(true);
    const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Portal>
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={appearance}>
                    {children}
                </Alert>
            </Snackbar>
        </Portal>
    );
};

export default CustomToast;
