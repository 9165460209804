import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import StyledMenu from './styledMenu';
import StyledMenuItem from './styledMenuItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import InfoIcon from '@material-ui/icons/Info';
import {images} from '../../../assets/resources';
import Router from '../../../routing/router';
import {authService} from '../../../services/authService';
import {useSelector, useDispatch} from 'react-redux';
import {ApplicationState} from '../../../store';
import {UserResponse, UserRole} from '../../../api/apiContracts';
import FirstTimeLoginPopUp from '../../../components/projectsOverview/firstTimeLoginPopUp';
import {useErrorHandler} from '../../../errorHandling/useErrorHandler';
import {updateUser} from '../../../thunks/usersThunks';
import {AppThunkDispatch} from '../../../store/types';

const HomeHeader = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const loggedInUser = useSelector<ApplicationState, UserResponse[]>(s => s.users.users).filter(
        u => u.id === authService.getLoggedInUserId()
    )[0];
    const userIsAdmin = authService.getUserRoles().includes(UserRole.Administrator);
    const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();
    const [isWelcomePopupShown, setIsWelcomePopupShown] = useState<boolean>(false);

    useEffect(() => {
        (async (): Promise<void> => {
            let showWelcome = !isWelcomePopupShown && loggedInUser.showWelcome && !userIsAdmin; 
            setShowInfoPopup(showWelcome);
    })();
    }, [intl, dispatch, handleResponseError, isWelcomePopupShown, loggedInUser, userIsAdmin]);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInfoPopupShow = () => {
        setShowInfoPopup(true);
    }

    const handleInfoPopupClose = async () => {
        try {
            if (loggedInUser.showWelcome && !userIsAdmin)
            {
                await dispatch(updateUser(loggedInUser, new UserResponse({
                    ...loggedInUser,
                    showWelcome: false,
                })));
            }
        } catch (e) {
            handleResponseError(e);
        } finally {
            setShowInfoPopup(false);
            setIsWelcomePopupShown(true);
        }
    }

    return (
        <AppBar position='fixed' color='secondary' className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <img src={images.logo_sandbox} alt='Logo' className={classes.logo} onClick={() => Router.routes.index.go()} />
                <div className={classes.actionsContainer}>
                    <Grid container className={classes.notifications}>
                        { userIsAdmin 
                            ?  null 
                            : (<>
                                <InfoIcon onClick={handleInfoPopupShow} />
                                <FirstTimeLoginPopUp isOpen={showInfoPopup} onOk={handleInfoPopupClose} />
                            </>)
                        }
                        <NotificationsNoneIcon />
                        <Typography variant='body2'>
                            {intl.formatMessage({id: 'header.greeting'}, {name: loggedInUser?.displayName || ''})}
                        </Typography>
                    </Grid>
                    <SettingsIcon />
                    <ExpandMoreIcon onClick={handleClick} />
                    <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        {userIsAdmin
                            ? null
                            : [
                                  <StyledMenuItem key='profile' onClick={() => Router.routes.profile.go()}>
                                      <ListItemText primary={intl.formatMessage({id: 'header.actions.profile'})} />
                                  </StyledMenuItem>,
                                  <StyledMenuItem key='helpCenter' onClick={() => {window.open(intl.formatMessage({id: 'header.actions.helpCenter.url'}), '_blank', 'noopener')}} >
                                      <ListItemText primary={intl.formatMessage({id: 'header.actions.helpCenter'})} />
                                  </StyledMenuItem>,
                              ]}
                        <StyledMenuItem onClick={() => Router.routes.login.go()}>
                            <ListItemText primary={intl.formatMessage({id: 'header.actions.signOut'})} />
                        </StyledMenuItem>
                    </StyledMenu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles(({zIndex, spacing}: Theme) =>
    createStyles({
        root: {
            zIndex: zIndex.drawer + 1,
            backgroundColor: '#d5e0e3',
            boxShadow: '0px 1px 0px 0px #C1CED2',
        },
        toolbar: {
            justifyContent: 'space-between',
        },
        logo: {
            height: spacing(4),
            cursor: 'pointer',
        },
        actionsContainer: {
            display: 'flex',
        },
        notifications: {
            marginRight: spacing(1),
            alignItems: 'center',
        },
    })
);

export default HomeHeader;
