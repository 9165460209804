import {TestUsersActionTypes, TestUsersState} from './types';

const initialState: TestUsersState = {
    testUsers: [],
};

export const testUsersReducer = (state = initialState, action: TestUsersActionTypes): TestUsersState => {
    switch (action.type) {
        case 'SET_TEST_USERS':
            return {testUsers: action.testUsers};
        case 'CREATE_TEST_USER':
            return {testUsers: [...state.testUsers, action.testUser]};
        case 'UPDATE_TEST_USER':
            return {testUsers: state.testUsers.map(tu => (tu.id === action.testUser.id ? action.testUser : tu))};
        case 'DELETE_TEST_USER':
            return {testUsers: state.testUsers.filter(tu => tu.id !== action.id)};
        default:
            return state;

    }
};
