import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../shared/styledButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {transferOwnership} from '../../api/apiClient';
import Router from '../../routing/router';
import {emailFormatValidationRegex} from '../../consts/regexConsts';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import StyledTextField from '../shared/styledTextField';

type TransferOwnershipDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    projectId: number;
    projectName: string;
};

const TransferOwnershipDialog = (props: TransferOwnershipDialogProps): JSX.Element => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const {isOpen, onClose, projectId, projectName} = props;

    const validationSchema = Yup.object().shape({
        projectName: Yup.string()
            .oneOf(
                [projectName],
                intl.formatMessage({id: 'projects.projectCard.options.transferOwnership.validationError.projectName'})
            )
            .required(intl.formatMessage({id: 'validation.project.delete.name.empty'})),
        newOwnerEmail: Yup.string()
            .required(intl.formatMessage({id: 'validation.email.empty'}))
            .matches(emailFormatValidationRegex, intl.formatMessage({id: 'validation.email.format'})),
    });

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
            await transferOwnership(projectId, values);
            Router.reload();
        } catch (e) {
            handleResponseError(e);
            setSubmitting(false);
        }
    };

    return (
        <Dialog classes={{paper: classes.paper}} open={isOpen} onClose={onClose} fullWidth>
            <Formik
                initialValues={{projectName: '', newOwnerEmail: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, handleChange, handleBlur} = props;

                    return (
                        <Form>
                            <DialogTitle className={classes.title}>
                                {`${intl.formatMessage({
                                    id: 'projects.projectCard.options.transferOwnership.dialog.title',
                                })} ${projectName}`}
                            </DialogTitle>
                            <DialogContent>
                                <h4 className={classes.inputHeading}>
                                    {intl.formatMessage({
                                        id: 'projects.projectCard.options.transferOwnership.projectName.heading',
                                    })}
                                </h4>
                                <StyledTextField
                                    autoFocus
                                    placeholder={intl.formatMessage({
                                        id: 'projects.projectCard.options.transferOwnership.projectName.placeholder',
                                    })}
                                    name='projectName'
                                    value={values.projectName}
                                    helperText={(touched.projectName && errors.projectName) as string}
                                    error={touched.projectName && Boolean(errors.projectName)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <h4 className={classes.inputHeading}>
                                    {intl.formatMessage({
                                        id: 'projects.projectCard.options.transferOwnership.newOwnerEmail.heading',
                                    })}
                                </h4>
                                <StyledTextField
                                    placeholder={intl.formatMessage({
                                        id: 'projects.projectCard.options.transferOwnership.newOwnerEmail.placeholder',
                                    })}
                                    name='newOwnerEmail'
                                    value={values.newOwnerEmail}
                                    helperText={(touched.newOwnerEmail && errors.newOwnerEmail) as string}
                                    error={touched.newOwnerEmail && Boolean(errors.newOwnerEmail)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <StyledButton onMouseDown={e => e.preventDefault()} onClick={onClose} type='reset' color='secondary'>
                                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                                </StyledButton>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={Object.keys(errors).some(key => errors[key] && touched[key])}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}>
                                    <Typography>
                                        {intl.formatMessage({
                                            id: 'projects.projectCard.options.transferOwnership.button.transfer',
                                        })}
                                    </Typography>
                                </StyledButton>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        inputHeading: {
            color: palette.primary.main,
        },
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);

export default TransferOwnershipDialog;
