import React from 'react';
import {useIntl} from 'react-intl';
import InfoTooltip from './infoTooltip';
import StyledHeader from './styledHeader';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';

type InputTitleProps = {
    name: string;
    values?: Record<string, React.ReactNode>;
};

const InputTitle = (props: InputTitleProps): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Grid container>
            <StyledHeader
                headerText={intl.formatMessage({
                    id: `project.property.${props.name}.heading`, 
                })}
            />
            <div className={classes.tooltipContainer}>
                <InfoTooltip
                    title={intl.formatMessage({
                        id: `project.property.${props.name}.tooltip`,
                    }, props.values)}
                />
            </div>
        </Grid>
    );
};

const useStyles = makeStyles(({palette}: Theme) =>
    createStyles({
        tooltipContainer: {
            height: '48px',
            paddingTop: '21px',
            paddingLeft: 2,
        },
    })
);

export default InputTitle;
