export const validationConstraints = {
//user    
    name: {
        maxLength: 50,
    },
    identity: {
        maxLength: 12,
    },
    email: {
        maxLength: 50,
    },
    mobileNumber: {
        maxLength: 50,
    },
    password: {
        minLength: 8,
    },
//project
    projectName: {
        maxLength: 50,
    },
    payoff: {
        maxLength: 60,
    },
    description: {
        maxLength: 254,
    },

    companyName: {
        maxLength: 50,
    },
    address: {
        maxLength: 50,
    },
    zipCode: {
        maxLength: 6,
    },
    city: {
        maxLength: 15,
    },
    contactName: {
        maxLength: 50,
    },
    contactPhone: {
        maxLength: 25,
    },
    contactEmail: {
        maxLength: 50,
    },
    reviewNotes: {
        maxLength: 254,
        minLength: 0
    },
    reviewComments: {
        maxLength: 500,
    },
    images: {
        maxSize: 1, //Mb
        supportedMediaTypes: ['image/jpeg','image/jpg','image/png'],
        supportedFormats: ['png','jpeg','jpg']
    },
};

