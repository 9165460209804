import React, {useState} from 'react';
import {Prompt} from 'react-router-dom';
import {useIntl} from 'react-intl';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import ColorPicker, {ColorResult} from '../../components/shared/colorPicker';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Grid, MenuItem, InputAdornment, Divider, Typography, Box, IconButton, FormHelperText} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {hexColorCode} from '../../consts/regexConsts';
import StyledButton from '../../components/shared/styledButton';
import Button from '@material-ui/core/Button';
import InputTitle from '../shared/inputTitle';
import {useDispatch} from 'react-redux';
import {AppThunkDispatch} from '../../store/types';
import {createProject, updateProject} from '../../thunks/projectsThunks';
import {ProjectResponse} from '../../api/apiContracts';
import Preview from '../addEditProjectGeneralDetails/preview';
import {uploadFile, downloadFileUrl, downloadFile} from '../../api/apiClient';
import {FileInput} from '../shared/fileInput';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import Router from '../../routing/router';
import {defaultProjectColor} from '../../consts/styleConsts';
import StyledTextField from '../shared/styledTextField';
import DiscardChangesConfirmationPopup from '../shared/DiscardChangesConfirmationPopup';
import {validationConstraints} from '../../consts/validationConstrs';
import ClearIcon from '@material-ui/icons/Clear';

type ProjectGeneralDetailsFormProps = {
    project: ProjectResponse;
    setProject: React.Dispatch<React.SetStateAction<ProjectResponse>>;
    isEdit: boolean;
    loading: boolean;
};

const ProjectGeneralDetailsForm = (props: ProjectGeneralDetailsFormProps): JSX.Element => {
    const countriesList = ['Denmark', 'Norway', 'Sweden'];

    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();
    const {project, setProject, isEdit, loading} = props;
    const [isConfirmationPopupOpened, setIsConfirmationPopupOpened] = useState<boolean>(false);
    const [doGoBack, setDoGoBack] = useState<boolean>(false);
    const [leavingTo, setLeavingTo] = useState<string>('');
    
    const goBack = () => {
        //as it should be:  
        //setIsConfirmationPopupOpened(false);
        //Router.goBack();

        //as it is now, beause on in ProjectTestDetailsForm we can't calculate value of 'goBackSteps':
        Router.routes.index.go();   
        window.scrollTo(0, 0);     
    }

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            const {coverImage, logo, ...projectValues} = values;

            await Promise.all(
                [
                    {urlPropertyName: 'coverImageUrl', file: coverImage},
                    {urlPropertyName: 'logoUrl', file: logo},
                ]
                    .filter(p => project[p.urlPropertyName] !== projectValues[p.urlPropertyName])
                    .map(async p => {
                        projectValues[p.urlPropertyName] = await uploadFile(p.file);
                    })
            );

            let projectId = project.id;

            if (isEdit) {
                await dispatch(updateProject(project, projectValues));
                setProject(projectValues);
            } else {
                projectId = await dispatch(createProject(projectValues));
                setProject({...projectValues, id: projectId});
                Router.replace(Router.routes.editProject.formatRoute({id: projectId}));
            }

            if (leavingTo) {
                Router.goTo(leavingTo);
            } else if (doGoBack) {
                goBack();
            } else {
                Router.routes.editProjectTestDetails.go({id: projectId});
            }

        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .max(
                validationConstraints.projectName.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.projectName.maxLength})
            )
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.name.heading'})})),
        payoff: Yup.string()
            .max(
                validationConstraints.payoff.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.payoff.maxLength})
            )
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.payoff.heading'})})),
        country: Yup.string()
            .oneOf(countriesList, intl.formatMessage({id: 'validation.invalid'}))
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.country.heading'})})),
        description: Yup.string()
            .max(
                validationConstraints.description.maxLength,
                intl.formatMessage(
                    {id: 'validation.maxLength'},
                    {maxLength: validationConstraints.description.maxLength}
                )
            )
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.description.heading'})})),
        color: Yup.string()
            .matches(hexColorCode, intl.formatMessage({id: 'validation.project.color.invalidFormat'}))
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.color.heading'})})),
        coverImageUrl: Yup.string().required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.coverImage.heading'})})),
        logoUrl: Yup.string().required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.logo.heading'})})),
        coverImage: Yup.mixed()
            .test('format', intl.formatMessage({id: 'validation.project.image.invalidFormat'}, {formats: validationConstraints.images.supportedFormats.join(', ')}), value => testFileFormat(value))
            .test('size', intl.formatMessage({id: 'validation.project.image.invalidSize'}, {size: validationConstraints.images.maxSize}), value => testFileSize(value)),
        logo: Yup.mixed()
            .test('format', intl.formatMessage({id: 'validation.project.image.invalidFormat'}, {formats: validationConstraints.images.supportedFormats.join(', ')}), value => testFileFormat(value))
            .test('size', intl.formatMessage({id: 'validation.project.image.invalidSize'}, {size: validationConstraints.images.maxSize}), value => testFileSize(value)),
    });

    const testFileSize = (value: File) => {
        var result = !value || value.size <= validationConstraints.images.maxSize * 1024 * 1024;
        return result;
    }

    const testFileFormat = (value: File) => {
        var result = !value || validationConstraints.images.supportedMediaTypes.includes(value.type.toLowerCase());
        return result;
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...project,
                coverImage: undefined as File | undefined,
                logo: undefined as File | undefined,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {props => {
                const {values, errors, touched, isSubmitting, dirty, isValid, handleChange, handleBlur, setFieldValue, submitForm, setFieldTouched, setValues} = props;

                const handleCoverImageSelection = (file?: File) => {
                    setFieldTouched('coverImageUrl', true);
                    setFieldTouched('coverImage', true);
                    setValues({...values, coverImage: file, coverImageName: file ? file.name : '', coverImageUrl: file ? URL.createObjectURL(file) : ''}, true);
                };

                const handleLogoSelection = (file?: File) => {
                    setFieldTouched('logoUrl', true);
                    setFieldTouched('logo', true);
                    setValues({...values, logo: file, logoName: file ? file.name : '', logoUrl: file ? URL.createObjectURL(file) : ''}, true);
                };

                const handleGoBackButtonClick = () => {
                    if (dirty) {
                        setIsConfirmationPopupOpened(true);
                    } else {
                        goBack();
                    }
                };
            
                const handleDiscardChangesOk = () => {
                    if (leavingTo) {
                        Router.goTo(leavingTo);
                    } else {
                        goBack();
                    }
                };
            
                const handleDiscardChangesSave = async () => {
                    setIsConfirmationPopupOpened(false);

                    if (isValid) {
                        setDoGoBack(!leavingTo);
                        await submitForm();                        
                    }
                };
            
                const handleDiscardChangesCancel = () => {
                    setIsConfirmationPopupOpened(false);
                    setLeavingTo('');
                    setDoGoBack(false);
                };
            
                return (
                    <Form>
                        <Prompt when={dirty && !isSubmitting && !isConfirmationPopupOpened}
                            message={(location) => {
                                setLeavingTo(location.pathname);
                                setIsConfirmationPopupOpened(true);
                                return false;
                            }}
                        />

                        <Grid container justify='space-between'>
                            <Grid item xs={12} lg={4}>
                                <InputTitle name='name' />
                                {loading ? (
                                    <Skeleton variant='rect' width='100%'>
                                        <StyledTextField />
                                    </Skeleton>
                                ) : (
                                    <StyledTextField
                                        autoFocus
                                        placeholder={intl.formatMessage({
                                            id: 'project.property.name.placeholder',
                                        })}
                                        name='name'
                                        value={values.name}
                                        helperText={(touched.name && errors.name) as string}
                                        error={touched.name && Boolean(errors.name)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='end'
                                                    className={
                                                        classes.inputAdornment
                                                    }>{`${values.name.length}/${validationConstraints.projectName.maxLength}`}</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}

                                <InputTitle name='payoff' />
                                {loading ? (
                                    <Skeleton variant='rect' width='100%'>
                                        <StyledTextField />
                                    </Skeleton>
                                ) : (
                                    <StyledTextField
                                        placeholder={intl.formatMessage({
                                            id: 'project.property.payoff.placeholder',
                                        })}
                                        name='payoff'
                                        value={values.payoff}
                                        helperText={(touched.payoff && errors.payoff) as string}
                                        error={touched.payoff && Boolean(errors.payoff)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='end'
                                                    className={
                                                        classes.inputAdornment
                                                    }>{`${values.payoff.length}/${validationConstraints.payoff.maxLength}`}</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}

                                <InputTitle name='country' />
                                {loading ? (
                                    <Skeleton variant='rect' width='100%'>
                                        <StyledTextField />
                                    </Skeleton>
                                ) : (
                                    <StyledTextField
                                        select
                                        SelectProps={{
                                            displayEmpty: true,
                                        }}
                                        InputProps={{
                                            className: values.country === '' ? classes.select : '',
                                        }}
                                        variant='outlined'
                                        name='country'
                                        value={values.country}
                                        helperText={(touched.country && errors.country) as string}
                                        error={touched.country && Boolean(errors.country)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}>
                                        <MenuItem value='' disabled>
                                            {intl.formatMessage({id: 'project.property.country.placeholder'})}
                                        </MenuItem>
                                        {countriesList.map(country => (
                                            <MenuItem key={country} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </StyledTextField>
                                )}

                                <InputTitle name='description' />
                                {loading ? (
                                    <Skeleton variant='rect' width='100%'>
                                        <StyledTextField multiline rows={10} />
                                    </Skeleton>
                                ) : (
                                    <StyledTextField
                                        multiline
                                        inputProps={{style: {resize: 'vertical'}}}
                                        rows={10}
                                        placeholder={intl.formatMessage({
                                            id: 'project.property.description.placeholder',
                                        })}
                                        name='description'
                                        value={values.description}
                                        helperText={(touched.description && errors.description) as string}
                                        error={touched.description && Boolean(errors.description)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='end'
                                                    className={
                                                        classes.inputAdornment
                                                    }>{`${values.description.length}/${validationConstraints.description.maxLength}`}</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}

                                <InputTitle name='color' />
                                <Grid container alignItems='center' spacing={1}>
                                    {loading ? (
                                        <Skeleton variant='rect' width='100%'>
                                            <StyledTextField />
                                        </Skeleton>
                                    ) : (
                                        <>
                                            <Grid item>
                                                <StyledTextField
                                                    name='color'
                                                    value={values.color}
                                                    helperText={(touched.color && errors.color) as string}
                                                    error={touched.color && Boolean(errors.color)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <ColorPicker
                                                    color={values.color}
                                                    onColorChange={(color: ColorResult): void =>
                                                        setFieldValue('color', color.hex)
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>

                                <InputTitle name='coverImage' />
                                <Grid container alignItems='center' spacing={1} className={classes.fileButtonContainer}>
                                {loading ? (
                                        <Skeleton variant='rect' width='100%'>
                                            <StyledTextField />
                                        </Skeleton>
                                    ) : (
                                        <>
                                            <Grid item>
                                                {!values.coverImageUrl ? 
                                                <FileInput
                                                    name='coverImage'
                                                    buttonLabel={intl.formatMessage({id: 'project.addEdit.generalDetails.coverImage.upload'})}
                                                    accept='image/*'
                                                    onSelection={(file: File) => handleCoverImageSelection(file)}
                                                /> :
                                                <Button size='small' component='span' className={classes.fileButton} 
                                                    onMouseDown={(e: React.MouseEvent) => e.preventDefault()} 
                                                    onClick={() => values.coverImage ? downloadFile(values.coverImage) : downloadFileUrl(values.coverImageUrl)}>
                                                    {values.coverImageName ? values.coverImageName : `${intl.formatMessage({id: 'project.addEdit.generalDetails.coverImage.download'})}`}
                                                </Button>
                                            }
                                            </Grid>
                                            {!values.coverImageUrl ? null :
                                                <Grid item className={classes.fileButtonActContainer}>
                                                    <IconButton size='small' onMouseDown={(e: React.MouseEvent) => e.preventDefault()} 
                                                        onClick={() => handleCoverImageSelection()}>
                                                        <ClearIcon fontSize="small" className={classes.fileButtonAct} />
                                                    </IconButton>
                                                </Grid>
                                            }

                                        </>
                                    )}
                                </Grid>
                                {!values.coverImageUrl ? 
                                    <FormHelperText 
                                        error={touched.coverImageUrl && Boolean(errors.coverImageUrl)} 
                                        variant="filled">{touched.coverImageUrl && errors.coverImageUrl}</FormHelperText>
                                        : 
                                    <FormHelperText 
                                        error={touched.coverImage && Boolean(errors.coverImage)} 
                                        variant="filled">{touched.coverImage && errors.coverImage}</FormHelperText>
                                }

                                <InputTitle name='logo' />
                                <Grid container alignItems='center' spacing={1} className={classes.fileButtonContainer}>
                                {loading ? (
                                        <Skeleton variant='rect' width='100%'>
                                            <StyledTextField />
                                        </Skeleton>
                                    ) : (
                                        <>
                                            <Grid item>
                                                {!values.logoUrl ? 
                                                <FileInput
                                                    name='logo'
                                                    buttonLabel={intl.formatMessage({id: 'project.addEdit.generalDetails.logo.upload'})}
                                                    accept='image/*'
                                                    onSelection={(file: File) => handleLogoSelection(file)}
                                                /> :
                                                <Button size='small' component='span' className={classes.fileButton} 
                                                    onMouseDown={(e: React.MouseEvent) => e.preventDefault()} 
                                                    onClick={() => values.logo ? downloadFile(values.logo) : downloadFileUrl(values.logoUrl)}>
                                                    {values.logoName ? values.logoName : `${intl.formatMessage({id: 'project.addEdit.generalDetails.logo.download'})}`}
                                                </Button>
                                            }
                                            </Grid>
                                            {!values.logoUrl ? null :
                                                <Grid item className={classes.fileButtonActContainer}>
                                                    <IconButton size='small' onMouseDown={(e: React.MouseEvent) => e.preventDefault()} 
                                                        onClick={() => handleLogoSelection()}>
                                                        <ClearIcon fontSize="small" className={classes.fileButtonAct} />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </>
                                    )}
                                </Grid>
                                {!values.logoUrl ? 
                                    <FormHelperText 
                                        error={touched.logoUrl && Boolean(errors.logoUrl)} 
                                        variant="filled">{touched.logoUrl && errors.logoUrl}</FormHelperText>
                                        : 
                                    <FormHelperText 
                                        error={touched.logo && Boolean(errors.logo)} 
                                        variant="filled">{touched.logo && errors.logo}</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} lg={8} xl={6}>
                                <Preview
                                    project={
                                        new ProjectResponse({
                                            ...values,
                                            color: hexColorCode.test(values.color) ? values.color : defaultProjectColor,
                                        })
                                    }
                                    loading={loading}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.divider}>
                            <Divider variant='fullWidth' />
                        </Grid>

                        <Grid container direction="row">
                            <Grid item>
                                <StyledButton
                                    color='default'
                                    type='button'
                                    disabled={isSubmitting}
                                    onClick={handleGoBackButtonClick}
                                    onMouseDown={e => e.preventDefault()}>
                                    <Typography>
                                        {intl.formatMessage({
                                            id: 'project.addEdit.testDetails.goBack',
                                        })}
                                    </Typography>
                                </StyledButton>
                            </Grid>

                            <Grid item>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={!isValid}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}>
                                    <Typography>
                                        {intl.formatMessage({
                                            id: 'project.addEdit.generalDetails.submit',
                                        })}
                                    </Typography>
                                </StyledButton>
                            </Grid>
                        </Grid>
                        <DiscardChangesConfirmationPopup isOpen={isConfirmationPopupOpened} name='generalDetails' 
                            onOk={handleDiscardChangesOk} onSave={!isValid ? undefined : handleDiscardChangesSave}  onCancel={handleDiscardChangesCancel}/>
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
        inputAdornment: {
            position: 'absolute',
            right: '13px',
            top: '13px',
            '& p': {
                fontSize: '11px',
            },
        },
        divider: {
            paddingTop: spacing(4),
            paddingBottom: spacing(3),
        },
        fileInput: {
            display: 'none',
        },
        select: {
            color: palette.text.disabled,
        },
        fileButton: {
            borderRadius: 25,
            textTransform: 'none',
            fontWeight: 700,
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            boxShadow: 'none',
            background: '#dce2e6',
            color: palette.info.main,
        },
        fileButtonActContainer: {
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
        fileButtonAct: {
        },
        fileButtonContainer: {
            flexWrap: 'nowrap',
        },
    })
);

export default ProjectGeneralDetailsForm;
