import React, {useRef, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

type IframeWithPostProps = {
    url: string;
    iframeName: string;
    iframeTitle: string;
    parameters: [{name: string; value: string}];
    iframeProps?: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;
};

const IframeWithPost = (props: IframeWithPostProps): JSX.Element => {
    const classes = useStyles();
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    }, []);

    return (
        <>
            <form ref={formRef} target={props.iframeName} action={props.url} method='POST' className={classes.form}>
                {props.parameters.map(p => (
                    <input key={p.name} type='hidden' name={p.name} value={p.value} />
                ))}
                <input type='submit' />
            </form>

            <iframe src='' name={props.iframeName} title={props.iframeTitle} {...props.iframeProps} />
        </>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        form: {
            display: 'none',
        },
    })
);

export default IframeWithPost;
