import {TestUserResponse} from '../../api/apiContracts';
import {
    CREATE_TEST_USER,
    CreateTestUserAction,
    DELETE_TEST_USER,
    DeleteTestUserAction,
    SET_TEST_USERS,
    SetTestUsersAction,
    UPDATE_TEST_USER,
    UpdateTestUserAction,
} from './types';

export const setTestUsersAction = (testUsers: TestUserResponse[]): SetTestUsersAction => {
    return {
        type: SET_TEST_USERS,
        testUsers: testUsers,
    };
};

export const createTestUserAction = (testUser: TestUserResponse): CreateTestUserAction => {
    return {
        type: CREATE_TEST_USER,
        testUser: testUser,
    };
};

export const updateTestUserAction = (testUser: TestUserResponse): UpdateTestUserAction => {
    return {
        type: UPDATE_TEST_USER,
        testUser: testUser,
    };
};

export const deleteTestUserAction = (id: number): DeleteTestUserAction => {
    return {
        type: DELETE_TEST_USER,
        id: id,
    };
};
