import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {TestUserResponse} from '../../api/apiContracts';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {AppThunkDispatch} from '../../store/types';
import {deleteTestUser, loadTestUsers} from '../../thunks/testUsersThunks';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {
    Paper,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    Typography,
    Grid,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {AddEditTestUserDialog} from '../forms/addEditTestUserDialog';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import StyledButton from '../shared/styledButton';

interface TestUsersTableColumn {
    id: 'name' | 'email' | 'mobileNumber' | 'identity';
    label: string;
    minWidth?: number;
    align?: 'right';
}

export const emptyTestUser = new TestUserResponse({
    id: 0,
    email: '',
    identity: '',
    mobileNumber: '',
    name: '',
    plusUserId: '',
});

type TestUsersTableProps = {
    disabled?: boolean;
};

export const TestUsersTable = ({ disabled }: TestUsersTableProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const testUsers = useSelector<ApplicationState, TestUserResponse[]>(s => s.testUsers.testUsers);
    const dispatch: AppThunkDispatch = useDispatch();
    const {handleResponseError} = useErrorHandler();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isAddEditTestUserDialogOpen, setIsAddEditTestUserDialogOpen] = React.useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [testUser, setTestUser] = useState<TestUserResponse>(emptyTestUser);

    const columns: TestUsersTableColumn[] = [
        {id: 'name', label: intl.formatMessage({id: 'testUsersTable.column.testUser'}), minWidth: 100},
        {id: 'email', label: intl.formatMessage({id: 'testUsersTable.column.email'}), minWidth: 100},
        {id: 'mobileNumber', label: intl.formatMessage({id: 'testUsersTable.column.phoneNumber'}), minWidth: 100},
        {id: 'identity', label: intl.formatMessage({id: 'testUsersTable.column.cpr'}), minWidth: 100},
    ];

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                await dispatch(loadTestUsers());
            } catch (e) {
                handleResponseError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, handleResponseError]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteTestUser = async (testUserId: number) => {
        await dispatch(deleteTestUser(testUserId));
    };

    const handleUpdateTestUser = async (testUser: TestUserResponse) => {
        setIsEdit(true);
        setTestUser(testUser);
        setIsAddEditTestUserDialogOpen(true);
    };

    const handleCreateTestUser = async () => {
        setIsEdit(false);
        setTestUser(emptyTestUser);
        setIsAddEditTestUserDialogOpen(true);
    };

    return loading ? (
        <Skeleton variant='rect' width='100%' className={classes.skeleton} />
    ) : (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    classes={{stickyHeader: classes.stickyHeader}}>
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell classes={{head: classes.stickyHeader}} padding='checkbox' />
                            <TableCell classes={{stickyHeader: classes.stickyHeader}} padding='checkbox' />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                        {testUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(tu => {
                            return (
                                <TableRow hover role='checkbox' tabIndex={-1} key={tu.id}>
                                    {columns.map(column => {
                                        const value = tu[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell padding='checkbox'>
                                        <IconButton onClick={() => handleUpdateTestUser(tu)} disabled={disabled}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell padding='checkbox'>
                                        <IconButton onClick={() => handleDeleteTestUser(tu.id)} disabled={disabled}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Grid container wrap='nowrap' justify='space-between'>
                    <StyledButton onClick={handleCreateTestUser} classes={{wrapper: classes.addTestUserButton}} disabled={disabled}>
                        <PersonAddIcon />
                        <Typography className={classes.buttonText} variant='body2'>
                            {intl.formatMessage({id: 'testUsersTable.title'})}
                        </Typography>
                    </StyledButton>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={testUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        className={classes.pagination}
                    />
                </Grid>
            </TableContainer>

            <AddEditTestUserDialog
                testUser={testUser}
                isEdit={isEdit}
                isOpen={isAddEditTestUserDialogOpen}
                onClose={() => setIsAddEditTestUserDialogOpen(false)}
            />
        </Paper>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: palette.secondary.main,
            border: '1px solid #d5e0e3',
            boxShadow: 'none',
            borderRadius: spacing(2),
            overflow: 'hidden',

            '& .MuiTableCell-root': {
                borderColor: '#d5e0e3',
            },
        },
        container: {
            maxHeight: 440,
        },
        stickyHeader: {
            fontWeight: 700,
            color: palette.text.secondary,
            backgroundColor: palette.secondary.main,
        },
        body: {
            '& td': {
                color: palette.info.light,
                '&:first-child': {
                    fontWeight: 700,
                },
            },
        },
        title: {
            padding: `${spacing(1)}px 0 0 ${spacing(2)}px`,
        },
        addTestUserButton: {
            width: 'fit-content',
            minWidth: 'fit-content',
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
        },
        skeleton: {
            height: spacing(40),
        },
        buttonText: {
            marginLeft: spacing(1),
        },
        pagination: {
            overflow: 'visible',
        },
    })
);
