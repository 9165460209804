import {DashboardStatsResponse} from '../../api/apiContracts';

export type StatsState = {
    stats: DashboardStatsResponse;
};

export const SET_STATS = 'SET_STATS';

export type SetStatsAction = {
    type: typeof SET_STATS;
    stats: DashboardStatsResponse;
};

export type StatsActionTypes = SetStatsAction;
