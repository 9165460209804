import React from 'react';
import {Route, Redirect} from 'react-router';
import {authService} from '../services/authService';
import Router from '../routing/router';
import {useAsync} from 'react-async';
import {UserRole} from '../api/apiContracts';

const PrivateRoute = ({component: Component, allowedRoles, ...rest}) => {
    const {data: isTokenValid, isPending} = useAsync(authService.isJwtTokenValid);
    if (isPending) return <div />;

    const roles = authService.getUserRoles();

    return (
        <Route
            {...rest}
            render={props => {
                if (!isTokenValid) {
                    return <Redirect to={Router.routes.login.props.path as string} />;
                } else if (allowedRoles && !allowedRoles.some(r => roles.includes(r))) {
                    return (
                        <Redirect
                            to={
                                roles.includes(UserRole.Administrator)
                                    ? (Router.routes.userAdministration.props.path as string)
                                    : (Router.routes.index.props.path as string)
                            }
                        />
                    );
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
