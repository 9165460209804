import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppThunkDispatch} from '../store/types';
import {authService} from '../services/authService';
import {loadLoggedInUser} from '../thunks/usersThunks';

const LoadInitialData = ({children}: {children?: React.ReactNode}) => {
    const dispatch: AppThunkDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        (async (): Promise<void> => {
            if (await authService.isJwtTokenValid()) {
                await dispatch(loadLoggedInUser());
            }

            setIsLoading(false);
        })();
    }, [dispatch]);

    return isLoading ? null : <>{children}</>;
};

export default LoadInitialData;
