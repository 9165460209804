import {
    setProjects,
    createProject as createProjectAction,
    updateProject as updateProjectAction,
    deleteProject as deleteProjectAction,
} from '../store/projects/actions';
import {AppThunk} from '../store/types';
import {
    getProjects,
    getProjectById,
    createProject as createProjectApi,
    deleteProject as deleteProjectApi,
    patchProject,
} from '../api/apiClient';
import {ProjectRequest, ProjectResponse, IProjectResponse} from '../api/apiContracts';
import {ApplicationState} from '../store';

export const loadProjects = (): AppThunk<Promise<void>> => async dispatch => {
    const projects = await getProjects();
    dispatch(setProjects(projects));
};

export const createProject = (project: ProjectRequest): AppThunk<Promise<number>> => async dispatch => {
    const id = await createProjectApi(project);
    const projectResponse: ProjectResponse = await getProjectById(id);
    dispatch(createProjectAction(projectResponse));

    return id;
};

export const updateProject = (
    oldProject: IProjectResponse,
    newProject: IProjectResponse
): AppThunk<Promise<void>> => async dispatch => {
    const patchedProject = await patchProject(oldProject, newProject);
    dispatch(updateProjectAction(patchedProject));
};

export const deleteProject = (id: number): AppThunk<Promise<void>> => async dispatch => {
    await deleteProjectApi(id);
    dispatch(deleteProjectAction(id));
};

export const duplicateProject = (id: number): AppThunk<Promise<void>> => async (
    dispatch,
    getState: () => ApplicationState
) => {
    const state = getState();
    const existingProject = state.projects.projects.find(p => p.id === id);
    if (existingProject) {
        const createProjectContract: ProjectRequest = new ProjectRequest({
            ...(({id, ...rest}) => rest)(existingProject),
        });
        await dispatch(createProject(createProjectContract));
    }
};

export const renameProject = (id: number, newName: string): AppThunk<Promise<void>> => async (
    dispatch,
    getState: () => ApplicationState
) => {
    const state = getState();
    const project = {...state.projects.projects.find(p => p.id === id)} as ProjectResponse;
    if (project) {
        const oldProject = new ProjectResponse(project);
        project.name = newName;
        const newProject = new ProjectResponse(project);
        await dispatch(updateProject(oldProject, newProject));
        dispatch(updateProjectAction(project));
    }
};
