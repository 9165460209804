import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {duplicateProject} from '../../thunks/projectsThunks';
import {AppThunkDispatch} from '../../store/types';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import TuneIcon from '@material-ui/icons/Tune';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CountryLogo from './countryLogo';

import Router from '../../routing/router';
import {ProjectResponse, ProjectStatus} from '../../api/apiContracts';
import TransferOwnershipDialog from '../forms/transferOwnershipDialog';
import DeleteConfirmationDialog from '../forms/deleteConfirmationDialog';
import RenameDialog from '../forms/renameDialog';
import CollaboratorLinkDialog from '../forms/collaboratorLinkDialog';
import { checkProject } from '../../api/apiClient';

type ProjectCardProps = {
    project: ProjectResponse;
};

const ProjectCard = (props: ProjectCardProps) => {
    const classes = useStyles(props);
    const intl = useIntl();
    const dispatch: AppThunkDispatch = useDispatch();
    const {project} = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isRenameDialogOpen, setIsRenameDialogOpen] = React.useState<boolean>(false);
    const [isCollaboratorLinkDialogOpen, setIsCollaboratorLinkDialogOpen] = React.useState<boolean>(false);
    const [isTransferOwnershipDialogOpen, setIsTransferOwnershipDialogOpen] = React.useState<boolean>(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = React.useState<boolean>(false);

    const openOptions = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const closeOptions = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleRenameProject = (event: React.MouseEvent<any>): void => {
        closeOptions(event);
        setIsRenameDialogOpen(true);
    };

    const handleEditProject = (event: React.MouseEvent<any>): void => {
        closeOptions(event);
        Router.routes.editProject.go({id: project.id});
    };

    const handleDuplicateProject = async (event: React.MouseEvent<any>): Promise<void> => {
        closeOptions(event);
        await dispatch(duplicateProject(project.id));
    };

    const handleCollaboratorLink = (event: React.MouseEvent<any>): void => {
        closeOptions(event);
        setIsCollaboratorLinkDialogOpen(true);
    };

    const handleTransferOwnership = (event: React.MouseEvent<any>): void => {
        closeOptions(event);
        setIsTransferOwnershipDialogOpen(true);
    };

    const handleDeleteProject = (event: React.MouseEvent<any>): void => {
        closeOptions(event);
        setIsDeleteConfirmationOpen(true);
    };

    const handleCardClick = (event: React.MouseEvent<any>): void => {
        if (checkProject(project))
        {
            handleEditProject(event);
        }
    };

    const projectActions = [
        {
            name: 'rename',
            onClick: handleRenameProject,
            disabledFor: [ProjectStatus.Pending],
            className: classes.menuItem,
        },
        {
            name: 'edit',
            onClick: handleEditProject,
            disabledFor: [ProjectStatus.Pending],
            className: classes.menuItem,
        },
        {
            name: 'duplicate',
            onClick: handleDuplicateProject,
            className: classes.menuItem,
        },
        {
            name: 'share',
            onClick: handleCollaboratorLink,
            className: classes.menuItem,
        },
        {
            name: 'transferOwnership',
            onClick: handleTransferOwnership,
            className: classes.menuItem,
        },
        {
            name: 'delete',
            onClick: handleDeleteProject,
            disabledFor: [ProjectStatus.Pending, ProjectStatus.Accepted],
            className: classes.primary,
        },
    ];

    const allowedActions = projectActions.filter(
        action => !(action.disabledFor && action.disabledFor.includes(props.project.status)),
    );

    return (
        <>
            <Card className={classes.root} onClick={handleCardClick}>
                <CardActionArea className={classes.actionArea}>
                    <Grid container className={classes.cardWrapper}>
                        <Grid item>
                            <Chip
                                avatar={<CountryLogo country={project.country} />}
                                label={<Typography variant='subtitle2'>{project.country}</Typography>}
                                className={classes.chipCountry}
                            />
                        </Grid>
                        <Grid item>
                            <Chip
                                avatar={<TuneIcon />}
                                label={
                                    <Typography variant='button' className={classes.chipSettingsLabel}>
                                        {intl.formatMessage({id: 'projects.projectCard.options'})}
                                    </Typography>
                                }
                                className={classes.chipSettings}
                                onClick={openOptions}
                            />
                        </Grid>
                        {allowedActions.length ? (
                            <Menu
                                keepMounted
                                classes={{paper: classes.menu}}
                                elevation={1}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={closeOptions}>
                                {allowedActions.map((action, index) => (
                                    <MenuItem key={index} onClick={action.onClick} className={action.className}>
                                        <Typography>
                                            {intl.formatMessage({id: `projects.projectCard.options.${action.name}`})}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        ) : null}
                        <Grid item xs={12} className={classes.cardContent}>
                            <Typography gutterBottom variant='h5' component='h3'>
                                {project.payoff}
                            </Typography>
                        </Grid>
                        <Grid container item alignItems='center' className={classes.footer}>
                            <img src={project.logoUrl} alt='logo' className={classes.logo} />
                            <Typography className={classes.truncate} variant='subtitle1'>{project.name}</Typography>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
            <RenameDialog
                isOpen={isRenameDialogOpen}
                onClose={() => setIsRenameDialogOpen(false)}
                projectId={project.id}
                projectName={project.name}
            />
            <CollaboratorLinkDialog
                isOpen={isCollaboratorLinkDialogOpen}
                onClose={() => setIsCollaboratorLinkDialogOpen(false)}
                projectId={project.id}
            />
            <TransferOwnershipDialog
                isOpen={isTransferOwnershipDialogOpen}
                onClose={() => setIsTransferOwnershipDialogOpen(false)}
                projectId={project.id}
                projectName={project.name}
            />
            <DeleteConfirmationDialog
                isOpen={isDeleteConfirmationOpen}
                onClose={() => setIsDeleteConfirmationOpen(false)}
                projectId={project.id}
                projectName={project.name}
            />
        </>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {
            height: 260,
            padding: spacing(1),
            borderRadius: spacing(2),
            boxShadow: 'none',
        },
        actionArea: {
            height: '100%',
            background: (props: ProjectCardProps) =>
                `url(${props.project.coverImageUrl}) center center / cover no-repeat`,
            boxShadow: (props: ProjectCardProps) => `inset 0 0 0 100vw ${fade(props.project.color || '#fff', 0.7)}`,
            borderRadius: spacing(1),
            cursor: 'initial',
        },
        truncate: {
            flex: 1,
            minWidth: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        cardHeader: {
            alignItems: 'baseline',
        },
        cardHeaderAction: {
            alignSelf: 'baseline',
        },
        chipSettings: {
            backgroundColor: palette.background.default,
            cursor: 'pointer',
        },
        chipSettingsLabel: {
            fontSize: 10,
            fontWeight: 700,
        },
        chipCountry: {
            height: spacing(3),
            backgroundColor: palette.background.default,
        },
        cardContent: {
            color: palette.secondary.light,
        },
        footer: {
            color: palette.secondary.light,
            alignSelf: 'flex-end',
        },
        cardWrapper: {
            height: '100%',
            padding: spacing(2),
            justifyContent: 'space-between',
        },
        logo: {
            height: spacing(4),
            width: spacing(4),
            padding: spacing(0.5),
            backgroundColor: palette.secondary.light,
            borderRadius: spacing(0.5),
            marginRight: spacing(1),
        },
        primary: {
            color: palette.primary.main,
        },
        menu: {
            borderRadius: spacing(1),
        },
        menuItem: {
            paddingBottom: spacing(1),
            paddingRight: spacing(5),
        },
    }),
);

export default ProjectCard;
