import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import WelcomeBox from '../components/shared/welcomeBox';
import {Grid, Typography} from '@material-ui/core';
import StyledButton from '../components/shared/styledButton';
import {invalidTokenTypes} from '../consts/errorMessages';
import {requestConfirmEmail} from '../api/apiClient';
import { RequestConfirmEmailRequest } from '../api/apiContracts';
import RequestEmailConfirmationPopup from '../components/shared/requestEmailConfirmationPopup';
import Router from '../routing/router';

type RouteParams = {
    type: string;
    param: string;
};

const InvalidToken = (props: RouteComponentProps<RouteParams>) => {
    const classes = useStyles();
    const intl = useIntl();
    const tokenType = props.match.params.type;
    const param = props.match.params.param;
    const [isRequestEmailConfirmationPopupOpened, setIsRequestEmailConfirmationPopupOpened] = useState<boolean>(false);

    const handleRequestEmailConfirmationOk = () => {
        setIsRequestEmailConfirmationPopupOpened(false);
        Router.routes.login.go();
    }

    return (
        <div className={classes.root}>
            <WelcomeBox name={'linkExpired.generic'}>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                <Grid item xs={12} className={classes.subtitle}>
                    <Typography variant='body1'>
                        {intl.formatMessage({id: 'linkExpired.generic.subtitle'}, {type: tokenType})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.subtitle}>
                    <Typography variant='body1'>
                        {intl.formatMessage({id: `linkExpired.${tokenType}.subtitle`})}
                    </Typography>
                </Grid>
                {tokenType === invalidTokenTypes.EmailConfirmation ? (
                <Grid item xs={'auto'}>
                    <StyledButton color='primary'
                        onClick={async ()=> { await requestConfirmEmail(RequestConfirmEmailRequest.fromJS({email: param}));
                            setIsRequestEmailConfirmationPopupOpened(true) }}>
                            {intl.formatMessage({id: 'login.verfication.link'})}
                    </StyledButton>
                </Grid>
                ) : null}
                </Grid>
            </WelcomeBox>
            <RequestEmailConfirmationPopup name='registration.confirmation.success' isOpen={isRequestEmailConfirmationPopupOpened} 
                onOk={handleRequestEmailConfirmationOk}/>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        subtitle: {
            textAlign: 'center',
        },
    }),
);

export default InvalidToken;
