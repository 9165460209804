import React from 'react';
import {RouteComponentProps} from 'react-router';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import HomeHeader from '../headers/homeHeader';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {useSelector} from 'react-redux';
import Router from '../../routing/router';
import {ApplicationState} from '../../store';
import {Box, Divider} from '@material-ui/core';
import HomeSideMenu from '../sideMenus/homeSideMenu';
import StyledConnector from '../shared/styledConnector';

type RouteParams = {
    id: string;
};

const ProjectLayout = (props: RouteComponentProps<RouteParams> & {children?: React.ReactNode}) => {
    const classes = useStyles();
    const intl = useIntl();
    const toolbar = <div className={classes.toolbar} />;

    let projectId = 0;
    try {
        projectId = parseInt(props.match.params.id);
    } catch (e) {}

    const isEdit = !!projectId;

    const steps = [
        {
            url: isEdit
                ? Router.routes.editProject.formatRoute({id: projectId})
                : (Router.routes.addProject.props.path as string),
            label: intl.formatMessage({id: 'project.addEdit.stepper.first'}),
        },
        {
            url: Router.routes.editProjectTestDetails.formatRoute({id: projectId}),
            label: intl.formatMessage({id: 'project.addEdit.stepper.second'}),
        },
        {
            url: Router.routes.editProjectSubmitDetails.formatRoute({id: projectId}),
            label: intl.formatMessage({id: 'project.addEdit.stepper.third'}),
        },
    ];
    const location = useSelector<ApplicationState, string>(s => s.router.location.pathname);
    let activeStep = steps.findIndex(s => s.url === location);
    activeStep = activeStep > 0 ? activeStep : 0;

    return (
        <div className={classes.root}>
            <HomeHeader />
            <HomeSideMenu>{toolbar}</HomeSideMenu>
            <main className={classes.main}>
                {toolbar}
                <div className={classes.content}>
                    <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        className={classes.stepper}
                        connector={<StyledConnector />}>
                        {steps.map(({label}) => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconProps={{
                                            classes: {
                                                root: classes.iconRoot,
                                                active: classes.iconActive,
                                                completed: classes.iconActive,
                                            },
                                        }}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Divider variant='fullWidth' className={classes.divider}/>
                    <Box component='span' fontWeight='bold' className={classes.mainTitle}>
                        {intl.formatMessage({id: isEdit ? 'project.edit.title' : 'project.add.title'})}
                    </Box>
                    {props.children}
                </div>
            </main>
        </div>
    );
};

const useStyles = makeStyles(({palette, mixins, spacing}: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        toolbar: mixins.toolbar,
        main: {
            flexGrow: 1,
            padding: spacing(3),
        },
        content: {
            padding: '0 5%',
        },
        mainTitle: {
            marginBottom: spacing(3),
            fontSize: 32,
        },
        stepper: {
            backgroundColor: 'transparent',
            marginBottom: spacing(3),
        },
        iconRoot: {
            color: `${palette.text.secondary} !important`,
        },
        iconActive: {
            color: `${palette.primary.main} !important`,
        },
        divider: {
            marginBottom: spacing(3),
        },
    }),
);

export default ProjectLayout;
