import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ProfileBox} from '../components/profile/profileBox';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {ApplicationState} from '../store';
import ChangeNameForm from '../components/forms/changeNameForm';
import ChangeEmailForm from '../components/forms/changeEmailForm';
import ChangePasswordForm from '../components/forms/changePasswordForm';
import {Grid} from '@material-ui/core';
import StyledButton from '../components/shared/styledButton';
import DeleteAccountDialog from '../components/forms/deleteAccountDialog';
import {UserResponse} from '../api/apiContracts';
import {authService} from '../services/authService';
import Router from '../routing/router';
import Typography from '@material-ui/core/Typography';

const Profile = () => {
    const classes = useStyles();
    const intl = useIntl();
    const loggedInUser = useSelector<ApplicationState, UserResponse[]>(s => s.users.users).filter(
        u => u.id === authService.getLoggedInUserId()
    )[0];
    const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = React.useState(false);

    return (
        <Grid container justify='center'>
            <Grid item xs={10} lg={6}>
                <ProfileBox>
                    <ChangeNameForm user={loggedInUser} />
                    <ChangeEmailForm currentEmail={loggedInUser.email} />
                    <ChangePasswordForm />
                </ProfileBox>
                <Grid container justify='center' className={classes.delete}>
                    <Grid item xs={6}>
                        <StyledButton color='primary' onClick={() => Router.routes.index.go()}>
                            <Typography>{intl.formatMessage({id: 'actions.backToWorkspace'})}</Typography>
                        </StyledButton>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledButton color='default' onClick={() => setIsDeleteAccountDialogOpen(true)}>
                            {intl.formatMessage({id: 'profile.button.delete'})}
                        </StyledButton>
                    </Grid>
                </Grid>
                <DeleteAccountDialog
                    isOpen={isDeleteAccountDialogOpen}
                    onClose={() => setIsDeleteAccountDialogOpen(false)}
                    email={loggedInUser.email}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        delete: {
            marginTop: spacing(3),
        },
    })
);

export default Profile;
