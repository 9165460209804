import {
    ProjectsState,
    SET_PROJECTS,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    ProjectsActionTypes,
} from './types';

const initialState: ProjectsState = {
    projects: [],
};

export const projectsReducer = (state = initialState, action: ProjectsActionTypes): ProjectsState => {
    switch (action.type) {
        case SET_PROJECTS:
            return {
                projects: action.projects,
            };
        case CREATE_PROJECT:
            return {
                projects: [...state.projects, action.project],
            };
        case UPDATE_PROJECT:
            return {
                projects: state.projects.map(p => (p.id === action.project.id ? action.project : p)),
            };
        case DELETE_PROJECT:
            return {
                projects: state.projects.filter(p => p.id !== action.id),
            };
        default:
            return state;
    }
};
