import {createMuiTheme} from '@material-ui/core/styles';

export const eBoksRedTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#a8242f',
        },
        secondary: {
            main: '#f4f6f8',
            dark: '#e6ebf0',
            light: '#ffffff',
        },
        info: {
            main: '#294350',
            light: '#7f9aa3',
        },
        background: {
            default: '#e6ebf0',
        },
        text: {
            primary: '#294350',
            secondary: '#587179',
        },
        error: {
            main: '#a8242f',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1920,
        },
    },
});

eBoksRedTheme.props = {
    MuiTextField: {
        variant: 'outlined',
        color: 'secondary',
        fullWidth: true,
    },
    MuiButton: {
        size: 'large',
        variant: 'contained',
    },
};

eBoksRedTheme.overrides = {
    MuiInputBase: {
        root: {
            backgroundColor: eBoksRedTheme.palette.secondary.light,
        },
    },
    MuiCheckbox: {
        root: {
            color: '#d5e0e3',

            '& .MuiIconButton-label': {
                width: '22px',
                height: '22px',
                overflow: 'hidden',
                borderRadius: '12px',
            },
        },
    },
    MuiFormHelperText: {
        contained: {
            '&$error': {
                backgroundColor: eBoksRedTheme.palette.error.main,
                color: 'white',
                padding: '8px 10px',
                margin: '10px 0',
                position: 'relative',

                '&:before': {
                    position: 'absolute',
                    border: '6px solid transparent',
                    borderBottom: `6px solid ${eBoksRedTheme.palette.error.main}`,
                    content: '""',
                    height: 0,
                    width: 0,
                    top: '-11px',
                    left: '14px',
                },
            },
        },
    },
};
