import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {getProjectById, checkProject} from '../api/apiClient';
import {ProjectResponse, ProjectStatus} from '../api/apiContracts';
import ProjectGeneralDetailsForm from '../components/forms/projectGeneralDetailsForm';
import {defaultProjectColor} from '../consts/styleConsts';
import {Typography} from '@material-ui/core';

type RouteParams = {
    id: string;
};

const AddEditProjectGeneralDetails = (props: RouteComponentProps<RouteParams>) => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();

    let projectId = 0;
    try {
        projectId = parseInt(props.match.params.id);
    } catch (e) {}
    const isEdit = !!projectId;

    const [loading, setLoading] = useState<boolean>(isEdit);
    const [project, setProject] = useState<ProjectResponse>(
        new ProjectResponse({
            id: 0,
            userId: '',
            name: '',
            payoff: '',
            country: '',
            description: '',
            color: defaultProjectColor,
            coverImageUrl: '',
            coverImageName: '',
            logoUrl: '',
            logoName: '',
            status: ProjectStatus.Draft,
            testUrl: '',
            testOptions: 0,
        })
    );

    useEffect(() => {
        (async (): Promise<void> => {
            if (isEdit) {
                try {
                    const project: ProjectResponse = await getProjectById(projectId);

                    if (checkProject(project))
                    {
                        setProject(project);
                        setLoading(false);
                    }

                } catch (e) {
                    handleResponseError(e);
                }
            }
        })();
    }, [handleResponseError, intl, isEdit, projectId]);

    return (
        <div className={classes.root}>
            <Typography variant='body2'>
                {intl.formatMessage({id: 'project.addEdit.generalDetails.description'})}
            </Typography>
            <ProjectGeneralDetailsForm loading={loading} isEdit={isEdit} project={project} setProject={setProject} />
        </div>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
        title: {
            color: palette.text.secondary,
            fontSize: 32,
        },
    })
);

export default AddEditProjectGeneralDetails;
