import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Router from './router';
import PrivateRoute from '../components/privateRoute';

const Routes = () => {
    return (
        <Switch>
            {Object.keys(Router.routes).map(key =>
                Router.routes[key].props.allowAnonymous ? (
                    <Route key={key} {...Router.routes[key].props} />
                ) : (
                    <PrivateRoute key={key} {...Router.routes[key].props} />
                )
            )}
            <Route>
                <Redirect to={Router.routes.notFound.props.path as string} />
            </Route>
        </Switch>
    );
};

export default Routes;
