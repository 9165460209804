import {useIntl} from 'react-intl';
import React, {useState} from 'react';
import {Grid, IconButton, InputAdornment} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import {ChangePasswordDialog} from './changePasswordDialog';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';

const ChangePasswordForm = (): JSX.Element => {
    const intl = useIntl();
    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);

    return (
        <Grid item xs={12}>
            <StyledTextFieldWithHeader
                inputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={() => setIsChangePasswordDialogOpen(true)}>
                                <Edit />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value='********'
                disabled={true}
                headerText={intl.formatMessage({id: 'profile.password.heading'})}
            />
            <ChangePasswordDialog
                isOpen={isChangePasswordDialogOpen}
                onClose={() => setIsChangePasswordDialogOpen(false)}
            />
        </Grid>
    );
};

export default ChangePasswordForm;
