import React, {useState}  from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {ProjectResponse} from '../../api/apiContracts';
import IphoneFrame from '../shared/iphoneFrame';
import {Grid, Typography} from '@material-ui/core';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

type PreviewServiceDescriptionProps = {
    project: ProjectResponse;
    getContent?: () => Promise<React.ReactNode>;
    install: boolean;
    contentInstalled?: () => void;
};

const PreviewServiceDescription = (props: PreviewServiceDescriptionProps): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles(props);
    const {handleResponseError} = useErrorHandler();
    const {project, getContent, install, contentInstalled} = props;    
    const [previewContent, setPreviewContent] = useState<React.ReactNode>(null);
    const [previewContentInstalled, setPreviewContentInstalled] = useState<boolean>(false);

    const handleClick = async (): Promise<void> => {
        if (install)
        {
            try  
            {
                if (getContent)
                {
                    const content = await getContent();
                    setPreviewContent(content);
                }
                else
                {
                    setPreviewContent(null);
                }

            } catch (e) 
            {
                handleResponseError(e);
            }        
            finally {
                setPreviewContentInstalled(true);
                if (contentInstalled)
                {
                    contentInstalled();        
                }
            }
        }
    };

    return (
        <IphoneFrame>
            <Grid container direction='column' justify='space-between' className={classes.content}>
                {previewContentInstalled && !install ? (
                    <>
                    <Grid container justify='flex-start' className={classes.topTileContainer}>
                        <KeyboardBackspaceIcon color='primary'/>
                        <Typography variant='subtitle1' className={classes.topTile}>{project.name}</Typography>
                    </Grid>
                    {previewContent}
                    </>
                ) : (
                    project ?
                    <>
                        <Grid item>
                            <Grid container justify='flex-start' className={classes.topTileContainer}>
                                <KeyboardBackspaceIcon color='primary'/>
                                <Typography variant='subtitle1' className={classes.topTile}>{project.name}</Typography>
                            </Grid>
                            <div className={classes.header} />
                            <Grid container justify='center'>
                                <div className={classes.logoWrapper}>
                                    <img src={project.logoUrl} alt='logo' className={classes.logo} />
                                </div>
                            </Grid>
                            <Grid container justify='center' className={classes.payoffContainer}>
                                <Typography variant='h5' className={classes.payoff}>{project.payoff}</Typography>
                            </Grid>
                            <Grid container justify='center' className={classes.tileContainer}>
                                <Typography variant='subtitle1' className={classes.tile}>
                                    {project.name}
                                </Typography>
                            </Grid>
                            <Grid container justify='center' className={classes.description}>
                                <Typography variant='body2'>{project.description}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify='center' className={classes.footer}>
                                <div className={classes.button} onClick={handleClick}>
                                    <Typography variant='subtitle2' color='secondary'>
                                        {intl.formatMessage({
                                            id: 'project.addEdit.generalDetails.preview.installChannel',
                                        })}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                    : null
                )}
            </Grid>
        </IphoneFrame>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        content: {
            height: '100%',
            flexWrap: 'nowrap',
            paddingTop: spacing(10),
            textAlign: 'center',
            overflowY: 'auto',
            wordBreak: 'break-word',
            '&::-webkit-scrollbar': {
                width: '5px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-border-radius': '10px',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                opacity: 0.1,
                '-webkit-border-radius': '10px',
                borderRadius: '10px',
                background: 'rgba(0,0,0,0.4)',
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.4)',
            },
        },
        header: {
            height: '100px',
            background: (props: PreviewServiceDescriptionProps) =>
                `url(${props.project.coverImageUrl}) center center / cover no-repeat`,
            boxShadow: (props: PreviewServiceDescriptionProps) => `inset 0 0 0 100vw ${fade((props.project.color?props.project.color:'#fff'), 0.8)}`,
        },
        logoWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: spacing(10),
            width: spacing(10),
            borderRadius: '50%',
            marginTop: `-${spacing(5)}px`,
            boxShadow: '0 8px 18px 0 rgba(0, 48, 72, 0.08)',
            backgroundColor: palette.secondary.light,
        },
        logo: {
            height: spacing(5),
        },
        description: {
            padding: `0 ${spacing(4)}px`,
            textAlign: 'center',
        },
        footer: {
            borderTop: '1px solid #e8e8e8',
            padding: spacing(4),
        },
        button: {
            backgroundColor: (props: PreviewServiceDescriptionProps) => `${props.project.color}`,
            width: '100%',
            padding: '11px 0',
            textAlign: 'center',
            borderRadius: spacing(3),
            cursor: ({getContent}: PreviewServiceDescriptionProps) => (getContent ? 'pointer' : 'auto'),
        },
        topTileContainer: {
            marginTop: `-${spacing(5)}px`,
            flexWrap: 'nowrap',
            padding: spacing(0.5),
            alignItems: 'center',
        }, 
        topTile: {
            fontWeight: 700,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: spacing(1),
            paddingLeft: spacing(2),
        }, 
        tileContainer: {
            padding: `0 ${spacing(2)}px`,
            textAlign: 'center',
        }, 
        tile: {
            fontWeight: 700,
            color: (props: PreviewServiceDescriptionProps) => `${props.project.color}`,
        }, 
        payoffContainer:
         {
            padding: `0 ${spacing(2)}px`,
            textAlign: 'center',
        }, 
        payoff: {
            fontWeight: 700,
            lineHeight: 1.2,
        }, 
    })
);

export default PreviewServiceDescription;
