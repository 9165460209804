import React, {useEffect, useState, ChangeEvent} from 'react';
import {Prompt} from 'react-router-dom';
import {useIntl} from 'react-intl';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Typography, Grid, MenuItem, Divider} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledButton from '../shared/styledButton';
import InputTitle from '../shared/inputTitle';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {AppThunkDispatch} from '../../store/types';
import {updateProject} from '../../thunks/projectsThunks';
import {ProjectResponse, TestUserResponse} from '../../api/apiContracts';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import Router from '../../routing/router';
import TestOptionsSelection from '../editProjectTestDetails/testOptionsSelection';
import StyledTextField from '../shared/styledTextField';
import {TestUsersTable, emptyTestUser} from '../tables/testUsersTable';
import PreviewServiceDescription from '../../components/shared/previewServiceDescription';
import {generateProjectDemoToken} from '../../api/apiClient';
import IframeWithPost from '../../components/shared/iframeWithPost';
import DiscardChangesConfirmationPopup from '../shared/DiscardChangesConfirmationPopup';
import TestWithSSOConfirmationPopup from '../editProjectTestDetails/testWithSSOConfirmationPopup';
import ServiceNotWorkingPopup from '../addEditProjectGeneralDetails/serviceNotWorkingPopup';
import {ProjectTestOptions} from '../../api/apiContracts';

type ProjectTestDetailsFormProps = {
    project: ProjectResponse;
    setProject: React.Dispatch<React.SetStateAction<ProjectResponse>>;
    loading: boolean;
};

const ProjectTestDetailsForm = (props: ProjectTestDetailsFormProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleError, handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();
    const {project, setProject, loading} = props;
    const testUsers = useSelector<ApplicationState, TestUserResponse[]>(s =>  [emptyTestUser, ...s.testUsers.testUsers]);
    const [selectedTestUser, setSelectedTestUser] = useState<TestUserResponse>(emptyTestUser);
    const [isConfirmationPopupOpened, setIsConfirmationPopupOpened] = useState<boolean>(false);
    const [installContent, setInstallContent] = useState<boolean>(true);
    const [doGoBack, setDoGoBack] = useState<boolean>(false);
  //  const [goBackSteps, setBackSteps] = useState<number>(1);
    const [doDemo, setDoDemo] = useState<boolean>(false);
    const [isTestWithSSOConfirmationPopupOpened, setIsTestWithSSOConfirmationPopupOpened] = useState<boolean>(false);
    const [leavingTo, setLeavingTo] = useState<string>('');
    const [isServiceNotWorkingPopupOpened, setIsServiceNotWorkingPopupOpened] = useState<boolean>(false);

    useEffect(() => {
        if (testUsers?.length)
        {
            if (!testUsers.find(t => t.id === selectedTestUser?.id))
            {
                setSelectedTestUser(testUsers[0]);
            }
        }
        else
        {
            setSelectedTestUser(emptyTestUser);
        }
    }, [selectedTestUser, testUsers]);

    const handleTestUserChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const id = parseInt(event.target.value);
        let testUser = testUsers && testUsers.find(t => t.id === id);
        if (!testUser) testUser = emptyTestUser;
        setSelectedTestUser(testUser);
    };

    const goBack = () => {
       //as it should be:  
       // setIsConfirmationPopupOpened(false);
       // Router.goBack(-goBackSteps);

        //as it is now, beause we dont know how to calculate value of 'goBackSteps':
        //the demoIFrame iframe modifies global navigation history
       Router.routes.editProject.go({id: project.id});
    }

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            await dispatch(updateProject(project, values));
            setProject(values);

            if (leavingTo) {
                Router.goTo(leavingTo);
            } else if (doGoBack) {
                goBack();
            } else if (!doDemo) {
                Router.routes.editProjectSubmitDetails.go({id: project.id});
            } else {
                setDoDemo(false);
            }
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    const handleServiceNotWorkingButtonClick = () => {
        setIsServiceNotWorkingPopupOpened(true);
    };
    
    const handleServiceNotWorkingCancel = () => {
        setIsServiceNotWorkingPopupOpened(false);
    };

    const validationSchema = Yup.object().shape({
        testUrl: Yup.string()
            .url(intl.formatMessage({id: 'validation.url'}, {property: intl.formatMessage({id: 'project.property.testUrl.heading'})}))
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'project.property.testUrl.heading'})})),
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={project}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {props => {
                const {
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    submitForm,
                } = props;

                const handleDemoButtonClick = async (): Promise<void> => {
                    setInstallContent(true);

                    if(isValid)
                    {
                        setDoDemo(true);
                        await submitForm();
                    }
                };

                const handleContentInstalled = () => {
                    setInstallContent(false);
                }
            
                const generateContent = async () : Promise<React.ReactNode> => {
                    if (!testUsers.length) {
                        handleError(intl.formatMessage({id: 'project.demo.testUsers.required'}));
                    }
                    // Not required now but can be required in the future
                    // else if (!selectedTestUser?.id) {
                    //     handleError(intl.formatMessage({id: 'project.demo.selectedTestUser.notSeleted'}));
                    // }
                    else
                    {
                        try {
                            return (
                                isSSOOn() ?
                                <IframeWithPost
                                    iframeName='demoIFrame'
                                    iframeTitle='Demo'
                                    url={values.testUrl}
                                    parameters={[{name: 'token', value: await generateProjectDemoToken(project.id, selectedTestUser.id)}]}
                                    iframeProps={{width: '100%', height: '100%'}}
                                />
                                :
                                <iframe src={values.testUrl} title='Demo' width='100%' height='100%' />
                            );
                        } catch (e) {
                            handleResponseError(e);
                        }
                    }
                };

                const handleGoBackButtonClick = () => {
                    if (dirty) {
                        setIsConfirmationPopupOpened(true);
                    } else {
                        goBack();
                    }
                };

                const handleDiscardChangesOk = () => {
                    if (leavingTo) {
                        Router.goTo(leavingTo);
                    } else {
                        goBack();
                    }
                };

                const handleDiscardChangesSave = async () => {
                    setIsConfirmationPopupOpened(false);

                    if (isValid) {
                        setDoGoBack(!leavingTo);
                        await submitForm();                        
                    }
                };

                const handleDiscardChangesCancel = () => {
                    setIsConfirmationPopupOpened(false);
                    setLeavingTo('');
                    setDoGoBack(false);
                };

                const handleTestWithSSOCancel = () => {
                    setIsTestWithSSOConfirmationPopupOpened(false);
                };

                const handleTestWithSSOOk = () => {
                    setIsTestWithSSOConfirmationPopupOpened(false);

                    let testOptions = values.testOptions | ProjectTestOptions.SignWithCertificate;
                    setFieldValue('testOptions', testOptions)
                };

                const handleSetTestOptions = (testOptions: number): void => {

                    if (testOptions & ProjectTestOptions.SignWithCertificate
                        && !(values.testOptions & ProjectTestOptions.SignWithCertificate))
                    {
                        testOptions = testOptions & ~ProjectTestOptions.SignWithCertificate;
                        setIsTestWithSSOConfirmationPopupOpened(true);
                    }

                    setFieldValue('testOptions', testOptions)
                }

                const isSSOOn = (): boolean => { return (values.testOptions & ProjectTestOptions.SignWithCertificate) === ProjectTestOptions.SignWithCertificate; }

                return (
                    <Grid container justify='space-between' spacing={10} direction='row' alignItems='center'>
                        <Grid item xs={12} lg={7} >
                            <Form>
                                <Prompt when={dirty && !isSubmitting && !isConfirmationPopupOpened}
                                    message={(location) => {
                                        setLeavingTo(location.pathname);
                                        setIsConfirmationPopupOpened(true);
                                        return false;
                                    }}
                                />

                                <Grid container direction='column'>
                                    <Grid item>
                                        <TestOptionsSelection
                                            testOptions={values.testOptions}
                                            setTestOptions={handleSetTestOptions}
                                            loading={loading}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <InputTitle name='testUsers' />
                                        <TestUsersTable disabled={!isSSOOn()}/>
                                    </Grid> 
                                    <Grid item>
                                        <InputTitle name='testUser' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                            <StyledTextField
                                                select
                                                variant='outlined'
                                                name='selectTestUser'
                                                value={testUsers?.length && selectedTestUser && isSSOOn() ? selectedTestUser.id : '' }
                                                disabled={!testUsers?.length || !isSSOOn()}
                                                onChange={handleTestUserChange}>
                                                {testUsers.map(testUser => (
                                                    <MenuItem key={testUser.id} value={testUser.id}>
                                                        {testUser.name ? testUser.name : intl.formatMessage({id: 'project.property.testUser.notSelected'}) }
                                                    </MenuItem>
                                                ))}
                                            </StyledTextField>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <InputTitle name='testUrl' />
                                        {loading ? (
                                            <Skeleton variant='rect' width='100%'>
                                                <StyledTextField />
                                            </Skeleton>
                                        ) : (
                                            <StyledTextField
                                                placeholder={intl.formatMessage({
                                                    id: 'project.property.testUrl.placeholder',
                                                })}
                                                name='testUrl'
                                                value={values.testUrl}
                                                helperText={(touched.testUrl && errors.testUrl) as string}
                                                error={touched.testUrl && Boolean(errors.testUrl)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                
                                <Grid item className={classes.divider}>
                                    <Divider variant='fullWidth' />
                                </Grid>

                                <Grid container direction="row">
                                    <Grid item>
                                        <StyledButton
                                            color='default'
                                            type='button'
                                            disabled={isSubmitting}
                                            onClick={handleGoBackButtonClick}
                                            onMouseDown={e => e.preventDefault()}>
                                            <Typography>
                                                {intl.formatMessage({
                                                    id: 'project.addEdit.testDetails.goBack',
                                                })}
                                            </Typography>
                                        </StyledButton>
                                    </Grid>
                                    <Grid item>
                                        <StyledButton
                                            color='primary'
                                            type='button'
                                            disabled={!isValid}
                                            loading={isSubmitting}
                                            onClick={handleDemoButtonClick}
                                            onMouseDown={e => e.preventDefault()}>
                                            <Typography>
                                                {intl.formatMessage({
                                                    id: 'project.addEdit.testDetails.demo',
                                                })}
                                            </Typography>
                                        </StyledButton>
                                    </Grid>
                                    <Grid item>
                                        <StyledButton
                                            color='primary'
                                            type='submit'
                                            disabled={!isValid}
                                            loading={isSubmitting}
                                            onMouseDown={e => e.preventDefault()}>
                                            <Typography>
                                                {intl.formatMessage({
                                                    id: 'project.addEdit.testDetails.submit',
                                                })}
                                            </Typography>
                                        </StyledButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid> 
                        <Grid item xs={12} lg={5}>
                            {loading ? (
                                <Skeleton variant='rect' width='100%'>
                                    <div className={classes.phoneFrameWrapper} />
                                </Skeleton>
                            ) : (
                            <>
                                <div className={classes.phoneFrameWrapper}>
                                    <PreviewServiceDescription project={project} getContent={generateContent} install={installContent}
                                        contentInstalled={handleContentInstalled}/>
                                </div>
                                <Typography variant='subtitle1' className={classes.previewLabel}>
                                    {intl.formatMessage({id: 'project.addEdit.testDetails.preview.demo'})}
                                </Typography>
                                <StyledButton
                                    color='default'
                                    type='button'
                                    onClick={handleServiceNotWorkingButtonClick}
                                    onMouseDown={e => e.preventDefault()}
                                    className={classes.buttonWidth}>
                                    <Typography>
                                        {intl.formatMessage({
                                            id: 'project.addEdit.generalDetails.ServiceNotWorking.title',
                                        })}
                                    </Typography>
                                </StyledButton>
                            </>
                        )}
                        </Grid> 
                        <DiscardChangesConfirmationPopup isOpen={isConfirmationPopupOpened} name='testDetails' 
                            onOk={handleDiscardChangesOk} onSave={!isValid ? undefined : handleDiscardChangesSave}  onCancel={handleDiscardChangesCancel}/>
                        <TestWithSSOConfirmationPopup isOpen={isTestWithSSOConfirmationPopupOpened} name='testDetails' 
                            onOk={handleTestWithSSOOk} onCancel={handleTestWithSSOCancel}/>
                        <ServiceNotWorkingPopup isOpen={isServiceNotWorkingPopupOpened} name='serviceNotWorkingDetails' 
                            onCancel={handleServiceNotWorkingCancel}/>
                    </Grid> 
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {},
        inputHeading: {
            color: palette.primary.main,
        },
        inputAdornment: {
            position: 'absolute',
            right: '13px',
            top: '13px',

            '& p': {
                fontSize: '11px',
            },
        },
        divider: {
            paddingTop: spacing(4),
            paddingBottom: spacing(3),
        },
        fileInput: {
            display: 'none',
        },
        subtitle: {
            fontWeight: 'bold',
            color: palette.text.secondary,
        },
        phoneFrameWrapper: {
            padding: spacing(3),
            width: '380px',
            height: '720px',
            margin: '0 auto',
        },
        previewLabel: {
            textAlign: 'center',
        },
        buttonWidth: {
            paddingRight: spacing(3),
            paddingLeft: spacing(3),
            width: '260px',
            height : '42px',
            margin: '0 auto'
        },
    })
);

export default ProjectTestDetailsForm;
