import {useIntl} from 'react-intl';
import React, {useState} from 'react';
import {Grid, IconButton, InputAdornment} from '@material-ui/core';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import {Edit} from '@material-ui/icons';
import ChangeNameDialog from './changeNameDialog';
import {UserResponse} from '../../api/contracts/generated';

type ChangeNameFormProps = {
    user: UserResponse;
};

const ChangeNameForm = ({user}: ChangeNameFormProps): JSX.Element => {
    const intl = useIntl();
    const [isChangeNameDialogOpen, setIsChangeNameDialogOpen] = useState(false);

    return (
        <Grid item xs={12}>
            <StyledTextFieldWithHeader
                inputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={() => setIsChangeNameDialogOpen(true)}>
                                <Edit />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={user.displayName}
                disabled={true}
                headerText={intl.formatMessage({id: 'profile.name.heading'})}
            />
            <ChangeNameDialog
                user={user}
                isOpen={isChangeNameDialogOpen}
                onClose={() => setIsChangeNameDialogOpen(false)}
            />
        </Grid>
    );
};

export default ChangeNameForm;
