import {StatsState, StatsActionTypes} from './types';
import {DashboardStatsResponse} from '../../api/apiContracts';

const initialState: StatsState = {
    stats: new DashboardStatsResponse({
        totalAccounts: -1,
        newAccountsThisMonth: -1,
        totalProjects: -1,
        newProjectsThisMonth: -1,
        draftProjects: -1,
        pendingProjects: -1,
        acceptedProjects: -1,
        rejectedProjects: -1,
    }),
};

export const statsReducer = (state = initialState, action: StatsActionTypes): StatsState => {
    switch (action.type) {
        case 'SET_STATS':
            return {stats: action.stats};
        default:
            return state;
    }
};
