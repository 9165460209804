import React, {Fragment, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import ProjectReviewForm from '../components/forms/projectReviewForm';
import Preview from '../components/addEditProjectGeneralDetails/preview';
import {ProjectResponse, ProjectPreviewResponse, ProjectSubmitDetailsResponse} from '../api/apiContracts';
import axios from 'axios';
import {getProjectRoute, getProjectSubmitDetailsRoute} from '../api/apiRoutes';
import {useErrorHandler} from '../errorHandling/useErrorHandler';
import InputTitle from '../components/shared/inputTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledTextField from '../components/shared/styledTextField';

type RouteParams = {
    id: string;
};

const ProjectReview = (props: RouteComponentProps<RouteParams>) => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();

    const [loading, setLoading] = useState<boolean>(true);
    const [project, setProject] = useState<ProjectPreviewResponse>(new ProjectResponse());
    const [projectSubmitDetails, setProjectSubmitDetails] = useState<ProjectSubmitDetailsResponse>(
        new ProjectSubmitDetailsResponse()
    );

    let projectId = 0;
    try {
        projectId = parseInt(props.match.params.id);
    } catch (e) {}

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                setProject((await axios.get(getProjectRoute(projectId))).data);
                setProjectSubmitDetails((await axios.get(getProjectSubmitDetailsRoute(projectId))).data);
                setLoading(false);
            } catch (e) {
                handleResponseError(e);
            }
        })();
    }, [handleResponseError, projectId]);

    return (
        <Grid container alignItems='center' justify='space-evenly' className={classes.root}>
            <Grid item xs={12} className={classes.title}>
                <Typography variant='h4' align='center'>
                    {intl.formatMessage({id: 'project.review.title'})}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                {['name', 'payoff', 'country', 'description'].map(key => (
                    <Fragment key={key}>
                        <InputTitle name={key} />
                        {loading ? (
                            <Skeleton variant='rect' width='100%'>
                                <StyledTextField />
                            </Skeleton>
                        ) : (
                            <StyledTextField name={key} value={project[key]} disabled={true} multiline />
                        )}
                    </Fragment>
                ))}
            </Grid>

            <Grid item xs={12} md={6}>
                <Preview project={project as ProjectResponse} loading={loading} />
            </Grid>

            <Grid item xs={12} md={8}>
                {[
                    'productionUrl',
                    'companyName',
                    'address',
                    'zipCode',
                    'city',
                    'contactName',
                    'contactPhone',
                    'contactEmail',
                    'reviewNotes',
                ].map(key => (
                    <Fragment key={key}>
                        <InputTitle name={key} />
                        {loading ? (
                            <Skeleton variant='rect' width='100%'>
                                <StyledTextField />
                            </Skeleton>
                        ) : (
                            <StyledTextField name={key} value={projectSubmitDetails[key]} disabled={true} multiline />
                        )}
                    </Fragment>
                ))}
            </Grid>

            <Grid item xs={12} className={classes.warning}>
                <Typography variant='subtitle1'>{intl.formatMessage({id: 'project.review.subtitle'})}</Typography>
                <Typography variant='body1'>{intl.formatMessage({id: 'project.review.warning'})}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.reviewForm}>
                <ProjectReviewForm projectId={projectId} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {
            padding: `${spacing(5)}px 10%`,
            backgroundColor: palette.secondary.main,
            minHeight: '100vh',
        },
        title: {
            paddingBottom: spacing(5),
        },
        warning: {
            paddingTop: spacing(10),
        },
        reviewForm: {
            minWidth: '50vw',
        },
    })
);

export default ProjectReview;
