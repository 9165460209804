import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button, {ButtonClassKey, ButtonProps} from '@material-ui/core/Button';
import StyledCircularProgress from './styledCircularProgress';
import {eBoksGradient} from '../../consts/styleConsts';
import {Grid} from '@material-ui/core';
import clsx from 'clsx';

type StyledButtonProps = ButtonProps & {
    loading?: boolean;
    classes?: Partial<Record<ButtonClassKey | 'wrapper', string>>;
};

const StyledButton = (props: StyledButtonProps): JSX.Element => {
    const classes = useStyles(props);
    const {disabled, loading, ...rest} = props;

    return (
        <Grid
            container
            direction='column'
            alignItems='stretch'
            className={clsx(props.classes?.wrapper, classes.wrapper)}>
            <Button
                {...rest}
                classes={{
                    root: classes.root,
                    contained: classes.contained,
                    containedPrimary: classes.containedPrimary,
                    containedSecondary: classes.containedSecondary,
                    disabled: classes.disabled,
                    ...props.classes,
                }}
                disabled={disabled || loading}
            />
            {loading && <StyledCircularProgress size={30} className={classes.buttonProgress} />}
        </Grid>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {
            borderRadius: 25,
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',

            '& .MuiTypography-root': {
                fontWeight: 500,
            },
        },
        contained: {
            boxShadow: 'none',
            color: palette.text.secondary,
        },
        containedPrimary: {
            '&:not($disabled)': {
                background: eBoksGradient,
                color: palette.secondary.light,
            },
        },
        containedSecondary: {
            background: palette.secondary.dark,
        },
        disabled: {},
        wrapper: {
            padding: spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -15,
            marginLeft: -15,
        },
    })
);

export default StyledButton;
