import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../shared/styledButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import PasswordField from '../shared/passwordField';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {changeLoggedInUserPassword} from '../../api/apiClient';
import {validationConstraints} from '../../consts/validationConstrs';

type ChangePasswordDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const ChangePasswordDialog = (props: ChangePasswordDialogProps): JSX.Element => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const {isOpen, onClose} = props;

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required(intl.formatMessage({id: 'validation.password.empty'})),
        newPassword: Yup.string()
            .required(intl.formatMessage({id: 'validation.password.empty'}))
            .min(validationConstraints.password.minLength,
                 intl.formatMessage({id: 'validation.password.length'}, {minLength: validationConstraints.password.minLength}))
            .test(
                'newPassword',
                intl.formatMessage({id: 'validation.password.case'}),
                value => value != null && value.toLowerCase() !== value
            )
            .when('oldPassword', {
                is: (value: any) => !!value,
                then: Yup.string().notOneOf(
                    [Yup.ref('oldPassword')],
                    intl.formatMessage({id: 'validation.newPassword.same'})
                ),
            }),
        repeatNewPassword: Yup.string()
            .required(intl.formatMessage({id: 'validation.password.empty'}))
            .oneOf([Yup.ref('newPassword')], intl.formatMessage({id: 'validation.repeatNewPassword.match'})),
    });

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            await changeLoggedInUserPassword(values.oldPassword, values.newPassword);
            onClose();
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog classes={{paper: classes.paper}} open={isOpen} onClose={onClose} fullWidth>
            <Formik
                initialValues={{oldPassword: '', newPassword: '', repeatNewPassword: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, handleChange, handleBlur} = props;

                    return (
                        <Form>
                            <DialogTitle className={classes.title}>
                                {intl.formatMessage({id: 'profile.password.dialog.title'})}
                            </DialogTitle>
                            <DialogContent>
                                <PasswordField
                                    autoFocus
                                    placeholder={intl.formatMessage({id: 'profile.oldPassword.placeholder'})}
                                    name='oldPassword'
                                    value={values.oldPassword}
                                    helperText={(touched.oldPassword && errors.oldPassword) as string}
                                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({id: 'profile.oldPassword.heading'})}
                                    disabled={isSubmitting}
                                />

                                <PasswordField
                                    placeholder={intl.formatMessage({id: 'profile.newPassword.placeholder'})}
                                    name='newPassword'
                                    value={values.newPassword}
                                    helperText={(touched.newPassword && errors.newPassword) as string}
                                    error={touched.newPassword && Boolean(errors.newPassword)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({id: 'profile.newPassword.heading'})}
                                    disabled={isSubmitting}
                                />

                                <PasswordField
                                    placeholder={intl.formatMessage({id: 'profile.repeatNewPassword.placeholder'})}
                                    name='repeatNewPassword'
                                    value={values.repeatNewPassword}
                                    helperText={(touched.repeatNewPassword && errors.repeatNewPassword) as string}
                                    error={touched.repeatNewPassword && Boolean(errors.repeatNewPassword)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({id: 'profile.repeatNewPassword.heading'})}
                                    disabled={isSubmitting}
                                />
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <StyledButton onMouseDown={e => e.preventDefault()} onClick={onClose} type='reset' color='secondary'>
                                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                                </StyledButton>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={Object.keys(errors).some(key => errors[key] && touched[key])}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}>
                                    <Typography>{intl.formatMessage({id: 'generic.button.submit'})}</Typography>
                                </StyledButton>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);
