import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import {ProjectResponse} from '../../api/apiContracts';
import Grid from '@material-ui/core/Grid';
import StyledButton from '../../components/shared/styledButton';
import Skeleton from '@material-ui/lab/Skeleton';
import IphoneFrame from '../shared/iphoneFrame';
import PreviewServiceCard from './previewServiceCard';
import Typography from '@material-ui/core/Typography';
import {images} from '../../assets/resources';
import PreviewServiceDescription from '../shared/previewServiceDescription';

type PreviewProps = {
    project: ProjectResponse;
    loading: boolean;
};

const Preview = ({project, loading}: PreviewProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container justify='center'>
            <Grid item xs={12} md={12} lg xl={6}>
                {loading ? (
                    <Skeleton variant='rect' className={classes.phoneSkeleton}>
                        <div className={classes.phoneFrameWrapper} />
                    </Skeleton>
                ) : (
                    <div className={classes.phoneFrameWrapper}>
                        <IphoneFrame>
                            <div className={classes.frameContent}>
                                <div className={classes.serviceCardWrapper}>
                                    <PreviewServiceCard project={project} />
                                </div>
                            </div>
                        </IphoneFrame>
                    </div>
                )}
                <Typography variant='subtitle1' className={classes.previewLabel}>
                    {intl.formatMessage({id: 'project.addEdit.generalDetails.preview.tile'})}
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg xl={6}>
                {loading ? (
                    <Skeleton variant='rect' className={classes.phoneSkeleton}>
                        <div className={classes.phoneFrameWrapper} />
                    </Skeleton>
                ) : (
                    <div className={classes.phoneFrameWrapper}>
                        <PreviewServiceDescription project={project} install={true} />
                    </div>
                )}
                <Typography variant='subtitle1' className={classes.previewLabel}>
                    {intl.formatMessage({id: 'project.addEdit.generalDetails.preview.app'})}
                </Typography>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        phoneFrameWrapper: {
            padding: spacing(3),
            width: '380px',
            height: '720px',
            margin: '0 auto',
        },
        previewLabel: {
            marginLeft: '5%',
            textAlign: 'center',
        },
        frameContent: {
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            backgroundImage: `url(${images.previewAppTile})`,
        },
        serviceCardWrapper: {
            paddingTop: '225px',
        },
        phoneSkeleton: {
            width: '90%',
            marginLeft: '5%',
        },
    })
);

export default Preview;
