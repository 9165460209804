import React, {useState} from 'react';
import {Button} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

type FileInputProps = {
    accept: string;
    name: string;
    buttonLabel: string;
    onSelection: (file: File) => void;
};

export const FileInput = ({accept, name, buttonLabel, onSelection}: FileInputProps): JSX.Element => {
    const classes = useStyles();

    const [fileName, setFileName] = useState<string>('');

    return (
        <>
            <input
                accept={accept}
                className={classes.fileInput}
                id={name}
                name={name}
                type='file'
                onChange={event => {
                    const files = event.currentTarget.files;
                    if (files && files.length) {
                        const file = files[0];
                        setFileName(file.name);
                        onSelection(file);
                    }
                }}
            />
            <label htmlFor={name}>
                <Button size='small' component='span' className={classes.fileButton}
                    onMouseDown={(e: React.MouseEvent) => e.preventDefault()}>
                    {fileName || buttonLabel}
                </Button>
            </label>
        </>
    );
};

const useStyles = makeStyles(({spacing, palette}: Theme) =>
    createStyles({
        fileInput: {
            display: 'none',
        },
        fileButton: {
            borderRadius: 25,
            textTransform: 'none',
            fontWeight: 700,
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            boxShadow: 'none',
            background: '#dce2e6',
            color: palette.info.main,
        },
    })
);
