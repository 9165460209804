import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {IUserResponse, UserResponse, UserRole} from '../../api/apiContracts';
import MaterialTable, {Column, MTableToolbar} from './materialTable';
import StyledButton from '../shared/styledButton';
import {Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {AppThunkDispatch} from '../../store/types';
import {deleteUser, loadUsers, updateUser} from '../../thunks/usersThunks';
import ConfirmationDialog from '../shared/confirmationDialog';
import ChangeUserEmailDialog from '../forms/changeUserEmailDialog';
import {authService} from '../../services/authService';
import ConfirmPromoteDialog from '../shared/confirmPromoteDialog';

type Row = UserResponse;

const UserAdministrationTable = () => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();
    const loggedUserRoles = authService.getUserRoles();
    const userIsSuperAdministrator = authService.getUserRoles().includes(UserRole.SuperAdministrator);

    const [loading, setLoading] = useState<boolean>(true);
    const users = useSelector<ApplicationState, UserResponse[]>(s => s.users.users);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState<boolean>(false);
    const [changeUserEmailDialogOpen, setChangeUserEmailDialogOpen] = useState<boolean>(false);
    const [confirmPromoteDialogOpen, setConfirmPromoteDialogOpen] = useState<boolean>(false);
    const [newRoles, setNewRoles] = useState<string[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserResponse>(
        new UserResponse({
            id: '',
            displayName: '',
            email: '',
            emailConfirmed: false,
            showWelcome: true,
            createdAt: new Date(),
            roles: [],
        })
    );
    const formatUserRoles = (roles: string[]): string => {
        let result = '';
        if (roles.includes(UserRole.Administrator)) result += UserRole.Administrator + ', ';
        if (roles.includes(UserRole.Manager)) result += UserRole.Manager + ', ';
        result += UserRole.User;

        return result;
    };

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                await dispatch(loadUsers());
            } catch (e) {
                handleResponseError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, handleResponseError, intl]);

    const columns: Column<Row>[] = [
        {
            title: intl.formatMessage({id: 'userAdministration.table.headers.id'}),
            type: 'string',
            field: 'id',
        },
        {
            title: intl.formatMessage({id: 'userAdministration.table.headers.userName'}),
            type: 'string',
            field: 'displayName',
        },
        {
            title: intl.formatMessage({id: 'userAdministration.table.headers.email'}),
            type: 'string',
            field: 'email',
        },
        {
            title: intl.formatMessage({id: 'userAdministration.table.headers.roles'}),
            type: 'string',
            field: 'roles',
            render: rowData => formatUserRoles(rowData.roles),
        },
        {
            title: intl.formatMessage({id: 'userAdministration.table.headers.actions'}),
            headerStyle: {textAlign: 'center'},
            render: rowData => (
                <Grid container className={classes.actions}>
                    <Grid item xs={3}>
                        <StyledButton
                            variant='outlined'
                            color='default'
                            disabled={rowData.roles.includes(UserRole.Administrator)}
                            onClick={async event => {
                                event.stopPropagation();

                                let roles = [UserRole.Manager, UserRole.User];
                                if (rowData.roles.includes(UserRole.Manager)) {
                                    roles = [UserRole.Administrator, UserRole.Manager, UserRole.User];
                                    setSelectedUser(rowData);
                                    setNewRoles(roles);
                                    setConfirmPromoteDialogOpen(true);
                                } else {
                                    await dispatch(updateUser(rowData, {...rowData, roles: roles}));
                                }
                            }}>
                            {intl.formatMessage({id: 'userAdministration.button.promote'})}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledButton
                            variant='outlined'
                            color='default'
                            disabled={
                                (!userIsSuperAdministrator && rowData.roles.includes(UserRole.Administrator)) ||
                                (!rowData.roles.includes(UserRole.Manager) &&
                                    !rowData.roles.includes(UserRole.Administrator))
                            }
                            onClick={async event => {
                                event.stopPropagation();

                                let newRoles = [UserRole.User];
                                if (rowData.roles.includes(UserRole.Administrator))
                                    newRoles = [UserRole.Manager, UserRole.User];

                                await dispatch(updateUser(rowData, {...rowData, roles: newRoles}));
                            }}>
                            {intl.formatMessage({id: 'userAdministration.button.demote'})}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledButton
                            variant='outlined'
                            color='primary'
                            disabled={
                                !loggedUserRoles.includes(UserRole.SuperAdministrator) &&
                                rowData.roles.includes(UserRole.Administrator)
                            }
                            onClick={async event => {
                                event.stopPropagation();
                                setChangeUserEmailDialogOpen(true);
                                setSelectedUser(rowData);
                            }}>
                            {intl.formatMessage({id: 'userAdministration.button.changeUserEmail'})}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledButton
                            variant='outlined'
                            color='primary'
                            disabled={
                                !loggedUserRoles.includes(UserRole.SuperAdministrator) &&
                                rowData.roles.includes(UserRole.Administrator)
                            }
                            onClick={async event => {
                                event.stopPropagation();
                                setDeleteConfirmationDialogOpen(true);
                                setSelectedUser(rowData);
                            }}>
                            {intl.formatMessage({id: 'userAdministration.button.delete'})}
                        </StyledButton>
                    </Grid>
                </Grid>
            ),
        },
    ];

    return (
        <>
            <MaterialTable
                title={intl.formatMessage({id: 'userAdministration.title'})}
                columns={columns}
                data={users.filter(
                    u => !u.id.includes(authService.getLoggedInUserId()) && !u.roles.includes('SuperAdministrator')
                )}
                options={{
                    search: true,
                    padding: 'dense',
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    emptyRowsWhenPaging: false,
                }}
                isLoading={loading}
                components={{
                    Toolbar: props => (
                        <div className={classes.toolbar}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
            />
            <ConfirmationDialog
                isOpen={deleteConfirmationDialogOpen}
                title={intl.formatMessage({id: 'userAdministration.deleteDialog.title'})}
                description={intl.formatMessage(
                    {id: 'userAdministration.deleteDialog.description'},
                    {userName: selectedUser.displayName}
                )}
                confirmationText={selectedUser.email}
                action={async () => await dispatch(deleteUser(selectedUser.id))}
                handleClose={(): void => setDeleteConfirmationDialogOpen(false)}
            />
            <ChangeUserEmailDialog
                isOpen={changeUserEmailDialogOpen}
                handleClose={() => setChangeUserEmailDialogOpen(false)}
                user={selectedUser}
            />
            <ConfirmPromoteDialog
                isOpen={confirmPromoteDialogOpen}
                title={intl.formatMessage({id: 'userAdministration.confirmPromoteDialog.title'})}
                description={intl.formatMessage(
                    {id: 'userAdministration.confirmPromoteDialog.description'},
                    {userName: selectedUser.displayName}
                )}
                onClose={(): void => setConfirmPromoteDialogOpen(false)}
                action={async () => await dispatch(updateUser(selectedUser, {...selectedUser, roles: newRoles}))}
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        toolbar: {
            '& .MuiFormControl-fullWidth': {
                width: 'auto',
            },
        },
        actions: {
            minWidth: 700,
        },
    })
);

export default UserAdministrationTable;
