import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {generatePreviewLink} from '../../api/apiClient';
import {Dialog, DialogContent, DialogTitle, DialogContentText, InputAdornment, IconButton} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../shared/styledButton';

type CollaboratorLinkDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    projectId: number;
};

const CollaboratorLinkDialog = ({isOpen, onClose, projectId}: CollaboratorLinkDialogProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const [collaboratorLink, setCollaboratorLink] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const handleGetCollaboratorLink = async (): Promise<void> => {
        try {
            setCollaboratorLink(await generatePreviewLink(projectId));
            setLoading(false);
        } catch (e) {
            handleResponseError(e);
        }
    };

    return (
        <Dialog
            classes={{paper: classes.paper}}
            open={isOpen}
            onEnter={handleGetCollaboratorLink}
            onClose={onClose}
            fullWidth>
            <DialogTitle className={classes.title}>
                {intl.formatMessage({id: 'projects.projectCard.options.share'})}
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Skeleton variant='rect' width='100%'>
                        <StyledTextFieldWithHeader
                            headerText={intl.formatMessage({id: 'projects.projectCard.options.share.link.heading'})}
                        />
                    </Skeleton>
                ) : (
                    <StyledTextFieldWithHeader
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => navigator.clipboard.writeText(collaboratorLink)}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        className={classes.linkField}
                        name='projectName'
                        value={collaboratorLink}
                        disabled
                        headerText={intl.formatMessage({id: 'projects.projectCard.options.share.link.heading'})}
                    />
                )}

                <DialogContentText>
                    {intl.formatMessage({
                        id: 'projects.projectCard.options.share.link.note',
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StyledButton onMouseDown={e => e.preventDefault()} onClick={onClose} type='reset' color='secondary'>
                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        linkField: {
            paddingBottom: spacing(2),
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);

export default CollaboratorLinkDialog;
