import React from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../shared/styledButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {renameProject} from '../../thunks/projectsThunks';
import {AppThunkDispatch} from '../../store/types';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';
import {InputAdornment} from '@material-ui/core';
import {validationConstraints} from '../../consts/validationConstrs';

type RenameDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    projectId: number;
    projectName: string;
};

const RenameDialog = (props: RenameDialogProps): JSX.Element => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();
    const {isOpen, onClose, projectId, projectName} = props;

    const validationSchema = Yup.object().shape({
        projectName: Yup.string()
            .max(
                validationConstraints.projectName.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.projectName.maxLength})
            )
            .required(intl.formatMessage({id: 'validation.project.rename.name.empty'})),
    });

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            await dispatch(renameProject(projectId, values.projectName));
            onClose();
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog classes={{paper: classes.paper}} open={isOpen} onClose={onClose} fullWidth>
            <Formik initialValues={{projectName: ''}} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, handleChange, handleBlur} = props;

                    return (
                        <Form>
                            <DialogTitle className={classes.title}>
                                {intl.formatMessage({id: 'projects.projectCard.options.rename.dialog.title'}) +
                                    ` ${projectName}`}
                            </DialogTitle>
                            <DialogContent>
                                <StyledTextFieldWithHeader
                                    autoFocus
                                    placeholder={intl.formatMessage({
                                        id: 'projects.projectCard.options.rename.projectName.placeholder',
                                    })}
                                    name='projectName'
                                    value={values.projectName}
                                    helperText={(touched.projectName && errors.projectName) as string}
                                    error={touched.projectName && Boolean(errors.projectName)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({
                                        id: 'projects.projectCard.options.rename.projectName.heading',
                                    })}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position='end'
                                                className={
                                                    classes.inputAdornment
                                                }>{`${values.projectName.length}/${validationConstraints.projectName.maxLength}`}</InputAdornment>
                                        ),
                                    }}

                                />
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <StyledButton onMouseDown={e => e.preventDefault()} onClick={onClose} type='reset' color='secondary'>
                                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                                </StyledButton>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={Object.keys(errors).some(key => errors[key] && touched[key])}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}>
                                    <Typography>{intl.formatMessage({id: 'generic.button.rename'})}</Typography>
                                </StyledButton>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
        inputAdornment: {
            position: 'absolute',
            right: '13px',
            top: '13px',
            '& p': {
                fontSize: '11px',
            },
        },
    })
);

export default RenameDialog;
