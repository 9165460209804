import React from 'react';
import WelcomeBox from '../components/shared/welcomeBox';
import ForgotPasswordForm from '../components/forms/forgotPasswordForm';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const ForgotPassword = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <WelcomeBox name='forgotPassword' subName='forgotPassword.sub'>
                <ForgotPasswordForm />
            </WelcomeBox>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            height: '100vh',
            minHeight: '100vh',
        },
    })
);

export default ForgotPassword;
