import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {ProjectResponse} from '../../api/apiContracts';

type PreviewProjectCardProps = {
    project: ProjectResponse;
};

const PreviewProjectCard = (props: PreviewProjectCardProps) => {
    const classes = useStyles(props);
    const intl = useIntl();
    const {project} = props;

    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
                <Grid className={classes.cardWrapper}>
                    <CardContent className={classes.cardContent}>
                        <Grid container>
                            <Typography gutterBottom variant='h6' component='h3' className={classes.payoff}>
                                {project.payoff}
                            </Typography>
                        </Grid>
                        <Grid container justify='space-between' alignItems='center'>
                            <Grid item xs={project.logoUrl ? 1 : 3}>
                                <div className={classes.imageWrapper}>
                                    <img
                                        src={project.logoUrl}
                                        alt='logo'
                                        className={project.logoUrl ? classes.logo : classes.logoEmpty}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.projectName}>
                                    {project.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.button}>
                                    <Typography variant='body2'>
                                    {intl.formatMessage({
                                            id: 'generic.button.open',
                                        })}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        root: {
            height: spacing(19),
            padding: spacing(0.5),
            borderRadius: spacing(2),
            boxShadow: 'none',
            backgroundColor: 'transparent',
        },
        actionArea: {
            height: '100%',
            background: (props: PreviewProjectCardProps) =>
                `url(${props.project.coverImageUrl}) center center / cover no-repeat`,
            boxShadow: (props: PreviewProjectCardProps) => `inset 0 0 0 100vw ${fade((props.project.color?props.project.color:'#fff'), 0.8)}`,
            borderRadius: spacing(1),
            cursor: 'initial',
        },
        cardContent: {
            color: palette.secondary.light,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '16px!important',
        },
        cardWrapper: {
            height: '100%',
        },
        logo: {
            height: spacing(4),
            width: spacing(4),
            padding: spacing(0.5),
            backgroundColor: palette.secondary.light,
            borderRadius: spacing(0.5),
            marginRight: spacing(1),
            float: 'left',
        },
        logoEmpty: {
            backgroundColor: palette.secondary.light,
            color: 'black',
            borderRadius: spacing(0.5),
            padding: spacing(0.5),
            float: 'left',
        },
        button: {
            padding: '4px 12px',
            borderRadius: '12px',
            boxShadow: `inset 0 0 0 100vw ${fade(palette.secondary.light, 0.2)}`,
        },
        payoff: {
            overflow: 'hidden',
            minHeight: '28px',
            maxHeight: '83px',
            textOverflow: 'ellipsis',
            lineHeight: '28px',
        },
        projectName: {
            maxHeight: '20px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: spacing(1),
            fontWeight: 'normal',
        },
        imageWrapper: {
            wordBreak: 'break-all',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
        },
    }),
);

export default PreviewProjectCard;
