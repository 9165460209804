import {RouteProps} from 'react-router-dom';
import {formatRoute} from 'react-router-named-routes';
import {history} from './history';

import HomeLayout from '../components/layouts/homeLayout';
import ProjectLayout from '../components/layouts/projectLayout';
import ProfileLayout from '../components/layouts/profileLayout';
import {withLayout} from '../components/hoc/withLayout';

import Registration from '../screens/registration';
import Login from '../screens/login';
import RegistrationSuccess from '../screens/registrationSuccess';
import ForgotPassword from '../screens/forgotPassword';
import NotFound from '../screens/notFound';
import ResetPassword from '../screens/resetPassword';
import ForgotPasswordSuccess from '../screens/forgotPasswordSuccess';
import AddEditProjectGeneralDetails from '../screens/addEditProjectGeneralDetails';
import EditProjectTestDetails from '../screens/editProjectTestDetails';
import EditProjectSubmitDetails from '../screens/editProjectSubmitDetails';
import Profile from '../screens/profile';
import HelpCenter from '../screens/helpCenter';
import PeekProject from '../screens/peekProject';
import ProjectReview from '../screens/projectReview';
import {UserRole} from '../api/apiContracts';
import ManagementDashboard from '../screens/managementDashboard';
import ProjectsManagement from '../screens/projectsManagement';
import ProjectsOverview from '../screens/projectsOverview';
import AdminLayout from '../components/layouts/adminLayout';
import UserAdministration from '../screens/userAdministration';
import InvalidToken from '../screens/invalidToken';

interface CustomRouteProps extends RouteProps {
    allowAnonymous?: boolean;
    allowedRoles?: UserRole[];
    componentProps?: any;
}

class CustomRoute {
    public props: CustomRouteProps;

    constructor(props: CustomRouteProps) {
        this.props = props;
    }
}

class ParameterlessRoute extends CustomRoute {
    go(): void {
        history.push(this.props.path as string);
    }
}

class ParameterizedRoute<T> extends CustomRoute {
    go(params: T, queryParams?: any, newTab?: boolean): void {
        const url: string = this.formatRoute(params, queryParams);
        if (newTab) {
            window.open(url, '_blank', 'noopener');
        } else {
            history.push(url);
        }
    }

    formatRoute(params: T, queryParams?: any): string {
        return `${formatRoute(this.props.path, params)}${this.formatQueryParams(queryParams)}`;
    }

    formatQueryParams(queryParams: any): string {
        if (queryParams) {
            return `?${Object.keys(queryParams)
                .filter(key => queryParams[key] != null)
                .map(key => `${key}=${queryParams[key]}`)
                .join('&')}`;
        }

        return '';
    }
}

class Router {
    static isProjectsPath(path: string) {
        return path?.toLowerCase().startsWith('/projects');
    }

    static get routes() {
        return {
            notFound: new ParameterlessRoute({
                path: '/404',
                exact: true,
                component: NotFound,
                allowAnonymous: true,
            }),
            invalidToken: new ParameterizedRoute<{type: string, param?: string}>({
                path: '/invalidToken/:type/:param?',
                exact: true,
                component: InvalidToken,
                allowAnonymous: true,
            }),
            registration: new ParameterlessRoute({
                path: '/registration',
                exact: true,
                component: Registration,
                allowAnonymous: true,
            }),
            registrationSuccess: new ParameterlessRoute({
                path: '/registration/success',
                exact: true,
                component: RegistrationSuccess,
                allowAnonymous: true,
            }),
            login: new ParameterlessRoute({
                path: '/login',
                exact: true,
                component: Login,
                allowAnonymous: true,
            }),
            forgotPassword: new ParameterlessRoute({
                path: '/password/forgot',
                exact: true,
                component: ForgotPassword,
                allowAnonymous: true,
            }),
            forgotPasswordSuccess: new ParameterlessRoute({
                path: '/password/forgot/success',
                exact: true,
                component: ForgotPasswordSuccess,
                allowAnonymous: true,
            }),
            resetPassword: new ParameterlessRoute({
                path: '/password/reset',
                exact: true,
                component: ResetPassword,
                allowAnonymous: true,
            }),
            peekProject: new ParameterlessRoute({
                path: '/peek',
                exact: true,
                component: PeekProject,
                allowAnonymous: true,
            }),
            index: new ParameterlessRoute({
                path: '/',
                exact: true,
                component: withLayout({layoutComponent: HomeLayout, component: ProjectsOverview}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            userAdministration: new ParameterlessRoute({
                path: '/administration',
                exact: true,
                component: withLayout({layoutComponent: AdminLayout, component: UserAdministration}),
                allowedRoles: [UserRole.Administrator],
            }),
            addProject: new ParameterlessRoute({
                path: '/projects/add',
                exact: true,
                component: withLayout({layoutComponent: ProjectLayout, component: AddEditProjectGeneralDetails}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            editProject: new ParameterizedRoute<{id: number}>({
                path: '/projects/edit/:id',
                exact: true,
                component: withLayout({layoutComponent: ProjectLayout, component: AddEditProjectGeneralDetails}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            editProjectTestDetails: new ParameterizedRoute<{id: number}>({
                path: '/projects/edit/:id/test',
                exact: true,
                component: withLayout({layoutComponent: ProjectLayout, component: EditProjectTestDetails}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            editProjectSubmitDetails: new ParameterizedRoute<{id: number}>({
                path: '/projects/edit/:id/submit',
                exact: true,
                component: withLayout({layoutComponent: ProjectLayout, component: EditProjectSubmitDetails}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            profile: new ParameterlessRoute({
                path: '/profile',
                exact: true,
                component: withLayout({layoutComponent: ProfileLayout, component: Profile}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            help: new ParameterlessRoute({
                path: '/help',
                exact: true,
                component: withLayout({layoutComponent: HomeLayout, component: HelpCenter}),
                allowedRoles: [UserRole.User, UserRole.Manager],
            }),
            projectReview: new ParameterizedRoute<{id: number}>({
                path: '/review/:id',
                exact: true,
                component: ProjectReview,
                allowedRoles: [UserRole.Manager],
            }),
            managementDashboard: new ParameterlessRoute({
                path: '/management/dashboard',
                exact: true,
                component: withLayout({layoutComponent: HomeLayout, component: ManagementDashboard}),
                allowedRoles: [UserRole.Manager],
            }),
            projectsManagement: new ParameterlessRoute({
                path: '/management/projects',
                exact: true,
                component: withLayout({layoutComponent: HomeLayout, component: ProjectsManagement}),
                allowedRoles: [UserRole.Manager],
            }),
        };
    }

    static goBack(steps?: number): void {
        if (steps) history.go(steps);
        else history.goBack();
    }

    static goTo = (url: string) => {
        history.push(url);
    }

    static replace(url: string, state?: any): void {
        history.replace(url, state);
    }

    static reload(): void {
        history.go(0);
    }
}

export default Router;
