import React, {useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import {Link, Typography} from '@material-ui/core';
import LoginForm from '../components/forms/loginForm';
import WelcomeBox from '../components/shared/welcomeBox';
import Router from '../routing/router';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {authService} from '../services/authService';
import {useToasts} from 'react-toast-notifications';
import * as QueryString from "query-string";

const Login = props => {
    const classes = useStyles();
    const intl = useIntl();
    const {addToast} = useToasts();

    useEffect(() => {
        const params = QueryString.parse(props.location.search);
        if (params.email == "verified")
        {
            addToast(intl.formatMessage({id: 'login.toast.email.verified'}), {appearance: 'success'});
        }
        authService.logOut(false);
    }, []);    

    const handleSignUp = () => {
        Router.routes.registration.go();
    };

    return (
        <div className={classes.root}>
            <WelcomeBox>
                <LoginForm />
                <Grid item xs={12}>
                    <Typography variant='subtitle2'>
                        <Link href={Router.routes.forgotPassword.props.path as string} underline='always'
                        onMouseDown={e => e.preventDefault()}>
                            {intl.formatMessage({id: 'login.forgot.link'})}
                            
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.divider}>
                    <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' className={classes.loginSubHeading}>
                        {intl.formatMessage({id: 'login.subheading.create'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button className={classes.button} onClick={handleSignUp}
                        onMouseDown={e => e.preventDefault()}>
                        <Typography variant='body2'>{intl.formatMessage({id: 'login.button.create'})}</Typography>
                    </Button>
                </Grid>
            </WelcomeBox>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        divider: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        buttonContainer: {
            textAlign: 'center',
            paddingTop: theme.spacing(1.5),
        },
        button: {
            borderRadius: 25,
            textTransform: 'none',
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            background: theme.palette.secondary.dark,
            color: theme.palette.info.main,
        },
        loginSubHeading: {
            color: '#294350',
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 18,
            },
        },
    })
);

export default Login;
