import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@material-ui/core';
import StyledButton from '../shared/styledButton';

type RequestEmailConfirmationPopupProps = {
    isOpen: boolean;
    name: string;
    onOk: () => void;
};

const RequestEmailConfirmationPopup = (props: RequestEmailConfirmationPopupProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog classes={{paper: classes.paper}} open={props.isOpen}  onClose={props.onOk} fullWidth>
            <DialogTitle className={classes.title}>
                <Typography variant='h4' component='span'>
                    {intl.formatMessage({id: `${props.name}.title`})}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>
                    {intl.formatMessage({id: `${props.name}`})}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StyledButton onClick={props.onOk} color='primary'>
                    <Typography noWrap>
                        {intl.formatMessage({id: `${props.name}.button.ok`})}
                    </Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        content: {
            textAlign: 'center',
            padding: `${spacing(1)}px 20%`,
        },
        actions: {
            justifyContent: 'center',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
        link: {
            textAlign: 'center',
        },
    })
);

export default RequestEmailConfirmationPopup;
