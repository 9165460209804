import axios from 'axios';
import {mediaUploadRoute, getMediaDownloadRoute} from '../apiRoutes';
import {FileUploadResponse} from '../contracts/generated';

export const uploadFile = async file => {
    const url = mediaUploadRoute;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };

    return FileUploadResponse.fromJS((await axios.post(url, formData, config)).data).fileUrl;
};

export const downloadFileUrl = async (url: string) => {
    let id = url.substr(url.lastIndexOf('/') + 1);
    url = getMediaDownloadRoute(id);
    const a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const downloadFile = async (file: File) => {
    let a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    a.remove();
};
