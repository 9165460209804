import Grid from '@material-ui/core/Grid';
import React, {ReactNode} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';

type ProfileBoxProps = {
    children: ReactNode;
};

export const ProfileBox = ({children}: ProfileBoxProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.box}>
            <Grid container spacing={0} className={classes.gridsContainer}>
                <Grid item xs={12} className={classes.headingContainer}>
                    <h1 className={classes.heading}>{intl.formatMessage({id: 'profile.title'})}</h1>
                </Grid>
                {children}
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 25,
        },
        gridsContainer: {
            paddingTop: theme.spacing(3),
            paddingRight: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            paddingLeft: theme.spacing(6),
        },
        headingContainer: {
            textAlign: 'center',
            lineHeight: '120%',
        },
        heading: {
            color: theme.palette.info.main,
            fontWeight: 500,
        },
    })
);
