import axios from 'axios';
import {
    getProjectsRoute,
    getProjectRoute,
    getProjectPreviewRoute,
    createProjectRoute,
    deleteProjectRoute,
    patchProjectRoute,
    getProjectShortInfosRoute,
    transferOwnershipRoute,
    projectReviewRoute,
    generateProjectDemoTokenRoute,
    submitProjectRoute,
    generatePreviewLinkRoute,
} from '../apiRoutes';
import {
    ProjectResponse,
    ProjectPreviewResponse,
    ProjectRequest,
    ProjectShortInfoResponse,
    TransferOwnershipRequest,
    ReviewProjectRequest,
    IProjectResponse,
    ProjectStatus
} from '../apiContracts';
import {getResourceId} from '../../utils/getResourceId';
import {compare} from 'fast-json-patch';
import Router from '../../routing/router';


export type CheckProjectResult = {
}

export const checkProject = (project: ProjectResponse, checkResult?: CheckProjectResult) : boolean => {
    if (project && project.id 
        && (project.status !== ProjectStatus.Pending))
       {
           return true;
       } 
       else
       {
            if (checkResult)
            {
                //not implemented
            }
            Router.routes.index.go();
       }
       return false;
}

export const getProjects = async (): Promise<ProjectResponse[]> => {
    const result: ProjectResponse[] = (await axios.get(getProjectsRoute)).data.map(p => ProjectResponse.fromJS(p));
    return result;
};

export const getProjectById = async (id: number): Promise<ProjectResponse> => {
    const result: ProjectResponse = ProjectResponse.fromJS((await axios.get(getProjectRoute(id))).data);
    return result;
};

export const getProjectPreview = async (projectPreviewKey: string): Promise<ProjectPreviewResponse> => {
    const result: ProjectPreviewResponse = ProjectPreviewResponse.fromJS(
        (await axios.get(getProjectPreviewRoute(projectPreviewKey))).data
    );
    return result;
};

export const createProject = async (project: ProjectRequest): Promise<number> => {
    const response = await axios.post(createProjectRoute, project);
    const id: number = getResourceId(response);
    return id;
};

export const patchProject = async (
    oldProject: IProjectResponse,
    newProject: IProjectResponse
): Promise<ProjectResponse> => {
    const oldProjectRequest = ProjectRequest.fromJS(oldProject);
    const newProjectRequest = ProjectRequest.fromJS(newProject);

    const patch = compare(oldProjectRequest, newProjectRequest);
    if (patch.length) {
        await axios.patch(patchProjectRoute(newProject.id), patch);
    }

    return ProjectResponse.fromJS(newProject);
};

export const deleteProject = async (id: number): Promise<void> => {
    await axios.delete(deleteProjectRoute(id));
};

export const getProjectShortInfos = async (): Promise<ProjectShortInfoResponse[]> => {
    const result: ProjectShortInfoResponse[] = (await axios.get(getProjectShortInfosRoute)).data.map(p =>
        ProjectShortInfoResponse.fromJS(p)
    );
    return result;
};

export const transferOwnership = async (projectId: number, request: TransferOwnershipRequest): Promise<void> => {
    await axios.post(transferOwnershipRoute(projectId), request);
};

export const projectReview = async (projectId: number, request: ReviewProjectRequest): Promise<void> => {
    await axios.post(projectReviewRoute(projectId), request);
};

export const generateProjectDemoToken = async (projectId: number, testUserId: number): Promise<string> => {
    const result: string = (await axios.get(generateProjectDemoTokenRoute(projectId, testUserId))).data;
    return result;
};

export const submitProject = async (projectId: number): Promise<void> => {
    await axios.post(submitProjectRoute(projectId));
};

export const generatePreviewLink = async (projectId: number): Promise<string> => {
    const result: string = (await axios.post(generatePreviewLinkRoute(projectId))).data;
    return result;
};
