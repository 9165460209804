import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';

type StyledHeaderProps = {
    headerText: string;
    className?: string;
};

export default function StyledHeader({headerText, className}: StyledHeaderProps): JSX.Element {
    const classes = useStyles();
    return <div className={clsx(className, classes.inputHeading)}>{headerText}</div>;
}

const useStyles = makeStyles(({palette}: Theme) =>
    createStyles({
        inputHeading: {
            color: palette.primary.main,
            fontSize: 16,
            fontWeight: 'bold',
            height: '48px',
            paddingTop: '22px',
        },
    })
);
