import React from 'react';
import UserAdministrationTable from '../components/tables/userAdministrationTable';

const UserAdministration = () => {
    return (
        <UserAdministrationTable/>
    );
};

export default UserAdministration;
