export const registerIdentityRoute = '/identity/register';
export const loginRoute = '/identity/login';
export const refreshTokenRoute = '/identity/refresh';
export const forgotPasswordRoute = '/identity/forgot';
export const resetPasswordRoute = '/identity/reset';
export const requestEmailChangeRoute = '/identity/emailchange';
export const deleteAccountRoute = '/identity/selfdelete';
export const changePasswordRoute = '/identity/changepassword';
export const requestConfirmEmailRoute = '/identity/confirm';

export const getProjectsRoute = '/projects';
export const getProjectRoute = (id: number): string => `/projects/${id}`;
export const getProjectPreviewRoute = (projectPreviewKey: string): string => `/projects/preview/${projectPreviewKey}`;
export const createProjectRoute = '/projects';
export const deleteProjectRoute = (id: number): string => `/projects/${id}`;
export const patchProjectRoute = (id: number): string => `/projects/${id}`;
export const getProjectShortInfosRoute = '/projects/shortinfos';
export const transferOwnershipRoute = (id: number): string => `/projects/${id}/transfer`;
export const projectReviewRoute = (id: number): string => `/projects/${id}/review`;
export const generateProjectDemoTokenRoute = (id: number, testUserId: number): string =>
    `/projects/${id}/token?testUserId=${testUserId}`;
export const submitProjectRoute = (id: number): string => `/projects/${id}/submit`;
export const generatePreviewLinkRoute = (id: number): string => `/projects/${id}/preview`;

export const getSignCertificateRoute = '/resources/signcertificate';

export const mediaUploadRoute = '/media/upload';
export const mediaDownloadRoute = '/media/download';

export const getMediaDownloadRoute = (mediaId: string): string => `${process.env.REACT_APP_SANDBOX_API_BASE_URL}media/download/${mediaId}`;

export const getTestUsersRoute = '/testusers';
export const createTestUserRoute = '/testusers';
export const patchTestUserRoute = (id: number): string => `/testusers/${id}`;
export const deleteTestUserRoute = (id: number): string => `/testusers/${id}`;

export const getUsersRoute = '/users';
export const getUserByIdRoute = (id: string): string => `/users/${id}`;
export const patchUserRoute = (id: string): string => `/users/${id}`;
export const deleteUserRoute = (id: string): string => `/users/${id}`;

export const getProjectSubmitDetailsRoute = (projectId: number): string => `/projectsubmitdetails/${projectId}`;
export const createProjectSubmitDetailsRoute = (projectId: number): string => `/projectsubmitdetails/${projectId}`;
export const deleteProjectSubmitDetailsRoute = (projectId: number): string => `/projectsubmitdetails/${projectId}`;
export const patchProjectSubmitDetailsRoute = (projectId: number): string => `/projectsubmitdetails/${projectId}`;

export const getDashboardStatsRoute = '/stats/dashboard';
