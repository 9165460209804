import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import WelcomeBox from '../components/shared/welcomeBox';
import {Grid, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import StyledButton from '../components/shared/styledButton';
import Router from '../routing/router';

const RegistrationSuccess = () => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.root}>
            <WelcomeBox name='registration.success'>
                <Typography className={classes.content}>
                    {intl.formatMessage({id: 'registration.success'})}
                </Typography>
                <Grid container justify='center'>
                    <Grid item xs={5}>
                        <StyledButton color='primary' onClick={() => Router.routes.login.go()}>
                            {intl.formatMessage({id: 'generic.button.back'})}
                        </StyledButton>
                    </Grid>
                </Grid>
            </WelcomeBox>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            height: '100vh',
            minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(4),
            margin: '0 auto',
            textAlign: 'center',
        },
    }),
);

export default RegistrationSuccess;
