import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';

const HelpCenter = () => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' alignItems='stretch'>
            <Typography variant='h4'>{intl.formatMessage({id: 'help.title'})}</Typography>
            <Typography variant='subtitle1'>
                {intl.formatMessage(
                    {id: 'help.subtitle'},
                    {
                        companyName: (
                            <Typography key='companyName' variant='body1' component='span' color='primary'>
                                {intl.formatMessage({id: 'help.companyName'})}
                            </Typography>
                        ),
                    }
                )}
            </Typography>
            <Grid container className={classes.iframeWrapper}>
                <iframe
                    className={classes.iframe}
                    src={intl.formatMessage({id: 'header.actions.helpCenter.url'})}
                    title={intl.formatMessage({id: 'header.actions.helpCenter'})}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        iframe: {
            width: '100%',
            height: '100%',
            border: 'none',
        },
        iframeWrapper: {
            height: '80vh',
        },
    })
);

export default HelpCenter;
