import React from 'react';
import {Grid, Paper, Typography, makeStyles, GridSize} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

type DashboardTileProps = {
    xs: GridSize;
    name: string;
    data: number;
}

const DashboardTile = ({xs, name, data}: DashboardTileProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid item xs={xs}>
            <Paper elevation={2} className={classes.paper}>
                <Typography component='h6' variant='h6'>
                    {name}
                </Typography>
                <Typography component='h6' variant='h6'>
                    {data === -1 ? <Skeleton variant='text'/> : data}
                </Typography>
            </Paper>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

export default DashboardTile;
