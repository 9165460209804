import {withStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenuItem = withStyles(({palette}: Theme) => ({
    root: {
        '&:hover': {
            backgroundColor: palette.info.light,
        },
    },
}))(MenuItem);

export default StyledMenuItem;
