import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {images} from '../../assets/resources';

type EboksCountryLogoProps = {
    country: string;
};

const EboksCountryLogo = ({country}: EboksCountryLogoProps) => {
    const classes = useStyles();

    let imageSrc: string = images.eboksDK;
    if (country === 'Norway') imageSrc = images.eboksNO;
    if (country === 'Sweden') imageSrc = images.eboksSE;

    return <img src={imageSrc} alt={country} className={classes.root} />;
};

const useStyles = makeStyles(({spacing}: Theme) =>
    createStyles({
        root: {
            height: '100%',
        },
    })
);

export default EboksCountryLogo;
