import React from 'react';
import {useIntl, IntlShape} from 'react-intl';

export type WithIntlProps = {
    intl: IntlShape;
};

export const withIntl = (Component: any) => {
    return (props: any) => {
        const intl = useIntl();

        return <Component intl={intl} {...props} />;
    };
};
