import React from 'react';
import {useIntl} from 'react-intl';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {emailFormatValidationRegex} from '../../consts/regexConsts';
import {validationConstraints} from '../../consts/validationConstrs';
import StyledButton from '../../components/shared/styledButton';
import {useDispatch} from 'react-redux';
import {AppThunkDispatch} from '../../store/types';
import {useErrorHandler} from '../../errorHandling/useErrorHandler';
import {TestUserResponse} from '../../api/apiContracts';
import {createTestUser, updateTestUser} from '../../thunks/testUsersThunks';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import EmailField from '../shared/emailField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {Guid} from 'guid-typescript';
import StyledTextFieldWithHeader from '../shared/styledTextFieldWithHeader';

type AddEditTestUserDialogProps = {
    testUser: TestUserResponse;
    isEdit: boolean;
    isOpen: boolean;
    onClose: () => void;
};

export const AddEditTestUserDialog = ({testUser, isEdit, isOpen, onClose}: AddEditTestUserDialogProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const {handleResponseError} = useErrorHandler();
    const dispatch: AppThunkDispatch = useDispatch();

    const handleSubmit = async (values, {setSubmitting}): Promise<void> => {
        setSubmitting(true);
        try {
            if (isEdit) {
                await dispatch(updateTestUser(testUser, values));
            } else {
                await dispatch(createTestUser(values));
            }
        } catch (e) {
            handleResponseError(e);
        } finally {
            setSubmitting(false);
            onClose();
        }
    };

    const generateRandomIdentity = (): string => {
        return Math.random().toString().substr(2, 10);
    };

    const generateNewPlusUserId = (): string => {
        return Guid.raw();
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .max(
                validationConstraints.name.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.name.maxLength})
            )
            .required(intl.formatMessage({id: 'validation.required'}, {property: intl.formatMessage({id: 'testUsersTable.dialog.name.heading'})})),
        identity: Yup.string().max(
            validationConstraints.identity.maxLength,
            intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.identity.maxLength})
        ),
        email: Yup.string()
            .max(
                validationConstraints.email.maxLength,
                intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.email.maxLength})
            )
            .matches(emailFormatValidationRegex, intl.formatMessage({id: 'validation.email.format'})),
        mobileNumber: Yup.string().max(
            validationConstraints.mobileNumber.maxLength,
            intl.formatMessage({id: 'validation.maxLength'}, {maxLength: validationConstraints.mobileNumber.maxLength})
        ),
    });

    return (
        <Dialog classes={{paper: classes.paper}} open={isOpen} onClose={onClose} fullWidth>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...testUser,
                    plusUserId: testUser.plusUserId ? testUser.plusUserId : generateNewPlusUserId(),
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {props => {
                    const {values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue} = props;

                    return (
                        <Form>
                            <DialogTitle className={classes.title}>
                                {intl.formatMessage(
                                    {id: 'testUsersTable.dialog.title'},
                                    {
                                        action: isEdit
                                            ? intl.formatMessage({id: 'testUsersTable.dialog.action.update'})
                                            : intl.formatMessage({id: 'testUsersTable.dialog.action.create'}),
                                    }
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <StyledTextFieldWithHeader
                                    autoFocus
                                    placeholder={intl.formatMessage({id: 'testUsersTable.dialog.name.placeholder'})}
                                    name='name'
                                    value={values.name}
                                    helperText={(touched.name && errors.name) as string}
                                    error={touched.name && Boolean(errors.name)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({id: 'testUsersTable.dialog.name.heading'})}
                                />

                                <EmailField
                                    value={values.email}
                                    helperText={(touched.email && errors.email) as string}
                                    error={touched.email && Boolean(errors.email)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={false}
                                    headerText={intl.formatMessage({id: 'testUsersTable.dialog.email.heading'})}
                                />

                                <StyledTextFieldWithHeader
                                    placeholder={intl.formatMessage({
                                        id: 'testUsersTable.dialog.mobileNumber.placeholder',
                                    })}
                                    name='mobileNumber'
                                    value={values.mobileNumber}
                                    helperText={(touched.mobileNumber && errors.mobileNumber) as string}
                                    error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({id: 'testUsersTable.dialog.mobileNumber.heading'})}
                                />

                                <StyledTextFieldWithHeader
                                    placeholder={intl.formatMessage({
                                        id: 'testUsersTable.dialog.identity.placeholder',
                                    })}
                                    name='identity'
                                    value={values.identity}
                                    helperText={(touched.identity && errors.identity) as string}
                                    error={touched.identity && Boolean(errors.identity)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    headerText={intl.formatMessage({
                                        id: 'testUsersTable.dialog.identity.heading',
                                    })}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={() => setFieldValue('identity', generateRandomIdentity())}>
                                                    <AutorenewIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <StyledButton onMouseDown={e => e.preventDefault()} onClick={onClose} type='reset' color='secondary'>
                                    <Typography>{intl.formatMessage({id: 'generic.button.cancel'})}</Typography>
                                </StyledButton>
                                <StyledButton
                                    color='primary'
                                    type='submit'
                                    disabled={Object.keys(errors).some(key => errors[key] && touched[key])}
                                    onMouseDown={e => e.preventDefault()}
                                    loading={isSubmitting}>
                                    <Typography>{intl.formatMessage({id: 'generic.button.submit'})}</Typography>
                                </StyledButton>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        actions: {
            justifyContent: 'space-between',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);
