import {ProjectShortInfoResponse} from '../../api/apiContracts';

export type ProjectShortInfoState = {
    projectShortInfos: ProjectShortInfoResponse[];
};

export const SET_PROJECT_SHORT_INFOS = 'SET_PROJECT_SHORT_INFOS';

export type SetProjectShortInfosAction = {
    type: typeof SET_PROJECT_SHORT_INFOS;
    projectShortInfos: ProjectShortInfoResponse[];
};

export type ProjectShortInfoActionTypes = SetProjectShortInfosAction;
