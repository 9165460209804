import React from 'react';
import {useIntl} from 'react-intl';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@material-ui/core';
import StyledButton from '../shared/styledButton';
import Router from '../../routing/router';

type ConfirmationPopupProps = {
    isOpen: boolean;
};

const ConfirmationPopup = ({isOpen}: ConfirmationPopupProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog classes={{paper: classes.paper}} open={isOpen} fullWidth>
            <DialogTitle className={classes.title}>
                <Typography variant='h4' component='span'>
                    {intl.formatMessage({id: 'project.addEdit.submitDetails.confirmation.title'})}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>
                    {intl.formatMessage({id: 'project.addEdit.submitDetails.confirmation.description'})}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <StyledButton onClick={() => Router.routes.index.go()} color='primary'>
                    <Typography>
                        {intl.formatMessage({id: 'project.addEdit.submitDetails.confirmation.done'})}
                    </Typography>
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(({palette, spacing}: Theme) =>
    createStyles({
        paper: {
            backgroundColor: palette.secondary.main,
            padding: spacing(2),
        },
        title: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        content: {
            textAlign: 'center',
            padding: `${spacing(1)}px 20%`,
        },
        actions: {
            justifyContent: 'center',
            padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`,
        },
    })
);

export default ConfirmationPopup;
