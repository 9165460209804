import Router from '../routing/router';
import JwtDecode from 'jwt-decode';
import {refreshTokenRoute} from '../api/apiRoutes';
import {refreshToken as refreshTokenApi} from '../api/apiClient';
import {logError} from '../errorHandling/errorLogging';
import {UserRole, RefreshTokenRequest} from '../api/apiContracts';

const isJwtTokenValid = async (): Promise<boolean> => {
    let isValid = false;
    try {
        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) {
            const tokenExpired = JwtDecode(jwtToken).exp < new Date().getTime() / 1000;
            if (!tokenExpired) {
                isValid = true;
            }

            if (tokenExpired) {
                await refreshJwtToken();
                isValid = true;
            }
        }
    } catch (e) {}

    return isValid;
};

const logOut = (goLogin: boolean = true): void => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('refreshToken');
    if (goLogin) {
        Router.routes.login.go();
    }
};

const generateAuthHeader = (): string => {
    return `Bearer ${localStorage.getItem('jwtToken')}`;
};

const login = (jwtToken: string, refreshToken: string): void => {
    localStorage.setItem('jwtToken', jwtToken);
    localStorage.setItem('refreshToken', refreshToken);
};

const refreshJwtToken = async (): Promise<void> => {
    const jwtToken = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (jwtToken && refreshToken) {
        const authResponseData = await refreshTokenApi(
            new RefreshTokenRequest({
                jwtToken: jwtToken,
                refreshToken: refreshToken,
            })
        );

        login(authResponseData.jwtToken, authResponseData.refreshToken);
    }
};

const isRefreshTokenUrl = (url: string): boolean => url === refreshTokenRoute;

const getLoggedInUserId = (): string => {
    const jwtToken = localStorage.getItem('jwtToken');
    const userId = JwtDecode(jwtToken).sub;

    return userId;
};

const getUserRoles = (): UserRole[] => {
    const jwtToken = localStorage.getItem('jwtToken');
    let roles: UserRole[] = [];

    try {
        const jwtDecoded: any = JwtDecode(jwtToken);

        roles = jwtDecoded.role || [];
        if (typeof roles === 'string') {
            roles = [roles];
        }
    } catch (e) {
        logError(e);
    }

    return roles;
};

export const authService = {
    isJwtTokenValid,
    logOut,
    generateAuthHeader,
    login,
    refreshJwtToken,
    isRefreshTokenUrl,
    getUserRoles,
    getLoggedInUserId,
};
