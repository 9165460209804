import {AppThunk} from '../store/types';
import {UserResponse, IUserResponse} from '../api/apiContracts';
import {deleteUser as deleteUserApi, getUserById, patchUser} from '../api/apiClient';
import {getUsers} from '../api/apiClient';
import {deleteUserAction, setUsersAction, updateUserAction} from '../store/users/actions';
import {authService} from '../services/authService';

export const loadUsers = (): AppThunk<Promise<void>> => async dispatch => {
    const users: UserResponse[] = await getUsers();
    dispatch(setUsersAction(users));
};

export const loadLoggedInUser = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const user: UserResponse = await getUserById(authService.getLoggedInUserId());
        dispatch(setUsersAction([user]));
    } catch (e) {
        authService.logOut();
    }
};

export const updateUser = (
    oldUser: IUserResponse,
    newUser: IUserResponse,
): AppThunk<Promise<void>> => async dispatch => {
    const patchedUser = await patchUser(oldUser, newUser);
    dispatch(updateUserAction(patchedUser));
};

export const deleteUser = (id: string): AppThunk<Promise<void>> => async dispatch => {
    await deleteUserApi(id);
    dispatch(deleteUserAction(id));
};
