import axios from 'axios';
import {getUsersRoute, deleteUserRoute, patchUserRoute, getUserByIdRoute} from '../apiRoutes';
import {compare} from 'fast-json-patch';
import {UserResponse} from '../apiContracts';
import {UserRequest, IUserResponse} from '../contracts/generated';

export const getUsers = async (): Promise<UserResponse[]> => {
    return (await axios.get(getUsersRoute)).data.map(user => UserResponse.fromJS(user));
};

export const getUserById = async (id: string): Promise<UserResponse> => {
    return UserResponse.fromJS((await axios.get(getUserByIdRoute(id))).data);
};

export const patchUser = async (oldUser: IUserResponse, newUser: IUserResponse): Promise<UserResponse> => {
    const oldUserRequest = UserRequest.fromJS(oldUser);
    const newUserRequest = UserRequest.fromJS(newUser);

    const patch = compare(oldUserRequest, newUserRequest);
    if (patch.length) {
        await axios.patch(patchUserRoute(newUser.id), patch);
    }

    return UserResponse.fromJS(newUser);
};

export const deleteUser = async (userId: string): Promise<void> => {
    await axios.delete(deleteUserRoute(userId));
};
